<template>
  <div>
    <page-preview
        v-if="uuid"
        :loading="false"
        type="edit"
        :uuid="uuid"
        :iframe-id="iframeId"
        :class="[
            (isMobile ? 'phone-container-bottom-indent' : null)
        ]"
    />
    <edit-block-dialog
        ref="editBlockDialog"
        @delete="deleteBlock"
        @save="saveBlock"
    />
    <confirmation ref="confirmation"/>
    <add-block-dialog
        ref="addBlockDialog"
        @addBlock="addBlock"
    />
    <footer-settings-dialog
        ref="footerSettingsDialog"
        @save="saveFooterSettings"
    />

    <v-card
        v-if="isMobile"
        flat
        class="pa-2 editor-menu-fixed"
    >
      <editor-menu />
    </v-card>
  </div>
</template>

<script>
import _ from "lodash";
import PagePreview from "@cabinet/components/page/PagePreview.vue";
import EditorMenu from "@cabinet/components/editor/EditorMenu.vue";
import Confirmation from "@cabinet/components/Confirmation.vue";
import EditBlockDialog from "@cabinet/components/editor/EditBlockDialog.vue";
import AddBlockDialog from "@cabinet/components/editor/AddBlockDialog.vue";
import {SET_HAS_CHANGES, SET_SAVING} from "@cabinet/store/Editor/mutations.js";
import {eventBus, events} from "@cabinet/utils/eventBus.js";
import pages from "@cabinet/api/pages.js";
import FooterSettingsDialog from "@cabinet/components/editor/FooterSettingsDialog.vue";

export default {
  name: 'Editor',
  components: {FooterSettingsDialog, AddBlockDialog, EditBlockDialog, Confirmation, EditorMenu, PagePreview},
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      uuid: null,
      themeData: {},
      pageBlocks: [],
      pageBlocksOriginal: [],
      settings: {},
      settingsOriginal: {},
      iframeId: null,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.mobile;
    },
    blockIndexByIdMap() {
      const map = {};
      this.pageBlocks.forEach((block, index) => {
        map[block.id] = index;
      });
      return map;
    },
    hasChanges: {
      get() {
        return this.$store.state.editor.hasChanges;
      },
      set(value) {
        this.$store.commit('editor/'+ SET_HAS_CHANGES, value);
      }
    },
    saving: {
      get() {
        return this.$store.state.editor.saving;
      },
      set(value) {
        this.$store.commit('editor/'+ SET_SAVING, value);
      }
    },
  },
  created() {
    this.iframeId = 'preview_' + ((Math.random() + 1).toString(36).substring(7));
  },
  mounted() {
    this.uuid = this.page.uuid;

    this.themeData = this.page.theme_data;
    this.pageBlocksOriginal = _.cloneDeep(this.page.page_blocks);
    this.pageBlocks = _.cloneDeep(this.page.page_blocks);
    this.settingsOriginal = _.cloneDeep(this.page.settings);
    this.settings = _.cloneDeep(this.page.settings);

    eventBus.$on(events.EDITOR_CLICK_CANCEL, this.cancel);
    eventBus.$on(events.EDITOR_CLICK_BACK, this.back);
    eventBus.$on(events.EDITOR_CLICK_ADD_BLOCK, this.showAddBlockDialog);
    eventBus.$on(events.EDITOR_CLICK_SAVE, this.savePage);
    document.addEventListener('footer-click', this.handleFooterClickEvent);
    document.addEventListener('block-click', this.handleBlockClickEvent);
    document.addEventListener('order-change', this.handleOrderChangeEvent);
  },
  beforeUnmount() {
    eventBus.$off(events.EDITOR_CLICK_CANCEL, this.cancel);
    eventBus.$off(events.EDITOR_CLICK_BACK, this.back);
    eventBus.$off(events.EDITOR_CLICK_ADD_BLOCK, this.showAddBlockDialog);
    eventBus.$off(events.EDITOR_CLICK_SAVE, this.savePage);
    document.removeEventListener('footer-click', this.handleFooterClickEvent);
    document.removeEventListener('block-click', this.handleBlockClickEvent);
    document.removeEventListener('order-change', this.handleOrderChangeEvent);
  },
  watch: {
    pageBlocks: {
      handler() {
        this.hasChanges = !_.isEqual(this.pageBlocks, this.pageBlocksOriginal)
            || !_.isEqual(this.settings, this.settingsOriginal);
      },
      deep: true,
    },
    settings: {
      handler() {
        this.hasChanges = !_.isEqual(this.pageBlocks, this.pageBlocksOriginal)
            || !_.isEqual(this.settings, this.settingsOriginal);
      },
      deep: true,
    },
  },
  methods: {
    cancel() {
      this.$refs.confirmation.showConfirm(
          this.$t('editor.cancelChangesBlockTitle'),
          this.$t('editor.cancelChangesBlockText'),
          () => {
            this.pageBlocks = _.cloneDeep(this.pageBlocksOriginal);
            this.settings = _.cloneDeep(this.settingsOriginal);
            this.pushChangesToPreview();
          }
      );
    },
    back() {
      this.$emit('goBack');
    },
    showAddBlockDialog() {
      this.$refs.addBlockDialog.show();
    },
    pushChangesToPreview() {
      const iframe = document.getElementById(this.iframeId);
      const targetOrigin = import.meta.env.VITE_CABINET_MAIN_URL;
      const postMessage = {
        source: 'cabinet',
        action: 'refresh-page',
        payload: {
          pageBlocks: _.cloneDeep(this.pageBlocks),
          pageSettings: _.cloneDeep(this.settings),
        },
      };
      iframe.contentWindow.postMessage(postMessage, targetOrigin);
    },

    handleFooterClickEvent() {
      this.$refs.footerSettingsDialog.show(this.settings.footer);
    },

    handleBlockClickEvent(event) {
      const blockId = event.detail.blockId;
      const index = this.blockIndexByIdMap[blockId]
      const block = _.cloneDeep(this.pageBlocks[index]);

      const customization = block.theme;
      let theme = this.getBlockDefaultTheme(block.component);
      theme = _.merge(theme, customization);
      block.theme = theme;

      this.$refs.editBlockDialog.show(block);
    },

    handleOrderChangeEvent(event) {
      this.pageBlocks = event.detail.pageBlocks;
    },

    saveBlock(block) {
      const theme = this.getBlockDefaultTheme(block.component);
      const customization = _.cloneDeep(block.theme);
      _.forEach(customization, function(value, key) {
        if (typeof value === 'object' && value !== null) {
          const themeNested = theme[key];
          _.forEach(value, function(valueNested, keyNested) {
            if (typeof valueNested === 'object' && Array.isArray(valueNested)) {
              valueNested.sort();
              themeNested[keyNested].sort();
              if (_.isEqual(valueNested, themeNested[keyNested])) {
                delete value[keyNested];
              }
            } else if (typeof valueNested === 'object' && !Array.isArray(valueNested)) {
              if (_.isEqual(valueNested, themeNested[keyNested])) {
                delete value[keyNested];
              }
            } else if (themeNested[keyNested] === valueNested) {
              delete value[keyNested];
            }
          });
          if (Object.getOwnPropertyNames(value).length === 0) {
            delete customization[key];
          }
        } else if (theme[key] === value) {
          delete customization[key];
        }
      });
      block.theme = customization;

      const index = this.blockIndexByIdMap[block.id];
      this.pageBlocks[index] = block;
      this.pushChangesToPreview();
    },

    saveFooterSettings(footerSettings) {
      this.settings.footer = _.cloneDeep(footerSettings);
      this.pushChangesToPreview();
    },

    addBlock(blockData) {
      this.pageBlocks.push(blockData);
      this.pushChangesToPreview();
    },

    getBlockDefaultTheme(component) {
      let theme = {};
      if (Object.prototype.hasOwnProperty.call(
          this.themeData.components,
          component,
      )) {
        theme = _.cloneDeep(this.themeData.components[component]);
      }
      return theme;
    },

    deleteBlock(blockId) {
      this.$refs.confirmation.showConfirm(
          this.$t('editor.deleteBlockTitle'),
          this.$t('editor.deleteBlockText'),
          () => {
            const index = this.blockIndexByIdMap[blockId];
            this.pageBlocks.splice(index, 1);
            this.$refs.editBlockDialog.close();
            this.pushChangesToPreview();
          }
      );
    },

    async savePage() {
      try {
        this.saving = true;
        if (!_.isEqual(this.pageBlocks, this.pageBlocksOriginal)) {
          const savedBlocks = await pages.saveBlocksData(this.uuid, this.pageBlocks);
          this.pageBlocksOriginal = _.cloneDeep(savedBlocks);
          this.$emit('blocksSaved', {
            pageBlocks: savedBlocks,
          })
        }
        if (!_.isEqual(this.settings, this.settingsOriginal)) {
          await pages.saveSettings(this.uuid, this.settings);
          this.settingsOriginal = _.cloneDeep(this.settings);
          this.$emit('settingsSaved', {
            settings: _.cloneDeep(this.settings),
          })
        }
        this.hasChanges = false;
      } finally {
        this.saving = false;
      }
    }
  },
}
</script>
<style>
.phone-container-bottom-indent {
  margin-bottom: 60px !important;
}
.editor-menu-fixed {
  white-space: nowrap;
  position: fixed;
  width: auto !important;
  max-width: 100%;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
