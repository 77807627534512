<template>
  <common-block>
    <template v-slot:common>
      <div class="text-center">
        <v-avatar
            :size="size"
            :class="themeModel.VAvatar.class.shape"
            color="primary"
        >
          <v-img
              v-if="dataModel.avatarUrl"
              :src="dataModel.avatarUrl"
          />
          <v-icon
              v-else
              :size="size"
          >
            mdi-account
          </v-icon>
        </v-avatar>
      </div>

      <image-cropper
          ref="imageCropper"
          :width="350"
          :height="350"
          @saved="applyNewAvatar"
      />
      <input
          type="file"
          ref="fileInput"
          @change="handleFileChange"
          style="display: none;"
          accept="image/*"
      />
      <v-toolbar class="flat" color="white">
        <v-spacer />
        <v-btn
            color="red"
            @click="deleteAvatarImg"
            :disabled="!dataModel.avatarUrl"
            class="mr-1"
        >
          {{ $t('editor.avatar.delete') }}
        </v-btn>
        <v-btn
            color="primary"
            variant="tonal"
            @click="$refs.fileInput.click()"
        >
          {{ $t('editor.avatar.choose') }}
        </v-btn>
        <v-spacer />
      </v-toolbar>
      

      <div class="text-caption">{{ $t('editor.avatar.size') }}</div>
      <v-slider
          v-model="size"
          :min="100"
          :max="250"
          append-icon="mdi-magnify-plus-outline"
          prepend-icon="mdi-magnify-minus-outline"
          @click:append="increase"
          @click:prepend="decrease"
      ></v-slider>
      
      
    </template>
    <template v-slot:content>
      <v-form>
        <v-text-field
            v-model="dataModel.title"
            :label="$t('editor.avatar.title')"
            variant="underlined"
        ></v-text-field>
        <v-text-field
            v-model="dataModel.subtitle"
            :label="$t('editor.avatar.subtitle')"
            variant="underlined"
        ></v-text-field>
      </v-form>
    </template>
    <template v-slot:design>
      <v-form>
        <div class="text-caption">{{ $t('editor.avatar.shape') }}</div>
        <v-radio-group
            v-model="themeModel.VAvatar.class.shape"
            inline
        >
          <v-radio
              :label="$t('editor.avatar.circle')"
              value="rounded-circle"
          ></v-radio>
          <v-radio
              :label="$t('editor.avatar.square')"
              value="rounded-0"
          ></v-radio>
        </v-radio-group>
      </v-form>
    </template>
  </common-block>
</template>
<script>
import blockMixin from "@cabinet/components/editor/blocks/blockMixin.js";
import ImageCropper from "@cabinet/components/ImageCropper.vue";


export default {
  name: 'Avatar_1',
  mixins: [blockMixin],
  components: {ImageCropper},
  data() {
    return {
      size: this.theme.VAvatar.size,
    };
  },
  watch: {
    size() {
      this.themeModel.VAvatar.size = this.size;
      this.themeModel.VIcon.size = this.size;
    },
  },
  methods: {
    decrease () {
      this.size = (this.size - 10) || 0
    },
    increase () {
      this.size = (this.size + 10) || 100
    },

    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const src = e.target.result;
          this.$refs.imageCropper.show(src);
        };
        reader.readAsDataURL(file);
      }
    },
    applyNewAvatar(tempUrl) {
      this.dataModel.avatarUrl = tempUrl;
      this.dataModel.isAvatarTempUrl = true;
    },
    deleteAvatarImg() {
      this.dataModel.avatarUrl = null;
      delete this.dataModel.isAvatarTempUrl;
    }
  }
}
</script>
