<template>
  <div
      class="d-flex align-center justify-center"
      style="height: 80vh"
  >
    <v-card
        width="400"
        class="mx-auto"
        flat
        :loading="loading"
    >
      <div v-if="loading" class="text-center ma-3">
        {{ $t('loading') }}...
      </div>
      <div v-else-if="!loading && profile">
        <v-card-title class="text-center">
          {{ $t('emailVerify.hello') }}, {{ profile.name }}!
        </v-card-title>
        <v-card-text>
          <p class="mb-2" v-html="$t('emailVerify.text1', {email: profile.email})" />
          <p>{{ $t('emailVerify.text2') }}</p>
        </v-card-text>
        <v-card-actions>
          <v-btn
              :loading="logouting"
              variant="tonal"
              @click="logout"
          >
            {{ $t('emailVerify.logout') }}
          </v-btn>
          <v-spacer />
          <v-btn
              :loading="resending"
              color="primary"
              variant="tonal"
              @click="sendAgain"
          >
            {{ $t('emailVerify.sendAgain') }}
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>

<script>
import ROUTE_NAMES from "@cabinet/router/routeNames.js";
import auth from "@cabinet/api/auth.js";
import user from "@cabinet/api/user.js";
import centrifuge from "@cabinet/plugins/centrifuge.js";
import {eventBus, events} from "@cabinet/utils/eventBus.js";

export default {
  name: 'VerifyEmail',
  data() {
    return {
      loading: true,
      routeNames: ROUTE_NAMES,
      logouting: false,
      resending: false,
      profile: null,
    };
  },
  mounted() {
    eventBus.$on(events.EMAIL_VERIFIED, this.emailVerified);
    if (this.$store.getters.authorized) {
      this.$router.push({ name: ROUTE_NAMES.PAGES });
    } else {
      this.fetchProfile();
    }
  },
  beforeUnmount() {
    eventBus.$off(events.EMAIL_VERIFIED, this.emailVerified);
  },
  methods: {
    emailVerified() {
      location.reload();
    },
    
    async fetchProfile() {
      this.loading = true;
      try {
        const response = await user.getUnverifiedProfile();
        this.profile = response.data;
        await centrifuge.subscribeUsersChannel(this.profile.id);
      } catch (e) {
        this.$router.push({name: ROUTE_NAMES.LOGIN});
      } finally {
        this.loading = false;
      }
    },
    
    async logout() {
      try {
        this.logouting = true;
        await auth.logout();
      } finally {
        this.logouting = false;
        this.$router.push({ name: ROUTE_NAMES.LOGIN });
      }
    },
    async sendAgain() {
      this.resending = true;
      try {
        await auth.resendVerificationLink();
      } finally {
        this.resending = false;
      }
    }
  },
}
</script>
