<template>
  <common-block>
    <template v-slot:common>
      
    </template>
    <template v-slot:content>
      <v-form>
        
        <v-text-field
            v-model="dataModel.title"
            :label="$t('editor.appointment.buttonName')"
            :placeholder="$t('editor.appointment.buttonNamePlaceholder')"
        />

        <v-sheet
            color="rgb(244 244 244)"
            class="pt-0 px-4 mb-2"
        >
          <v-checkbox
              v-model="dataModel.enableServices"
              hide-details>
            <template v-slot:label>
              {{ $t('editor.appointment.enableServices') }}
              <v-tooltip
                  v-model="servicesTooltip"
                  location="bottom"
                  max-width="500"
              >
                <template v-slot:activator="{ props }">
                  <v-btn
                      icon="mdi-chat-question-outline"
                      variant="text"
                      class="ml-2"
                      density="compact"
                      v-bind="props"
                      @click="servicesTooltip = true"
                  >
                  </v-btn>
                </template>
                <i v-html="$t('editor.appointment.servicesTooltip')" />
              </v-tooltip>
            </template>
          </v-checkbox>
        </v-sheet>

        <v-sheet
            color="rgb(244 244 244)"
            class="pt-0 px-4 mb-2"
        >
          <v-checkbox
              v-model="dataModel.enableSchedule"
              hide-details
          >
            <template v-slot:label>
              {{ $t('editor.appointment.enableSchedule') }}
              <v-tooltip
                  v-model="scheduleTooltip"
                  location="bottom"
                  max-width="500"
              >
                <template v-slot:activator="{ props }">
                  <v-btn
                      icon="mdi-chat-question-outline"
                      variant="text"
                      class="ml-2"
                      density="compact"
                      v-bind="props"
                      @click="scheduleTooltip = true"
                  >
                  </v-btn>
                </template>
                <i v-html="$t('editor.appointment.scheduleTooltip')" />
              </v-tooltip>
            </template>
          </v-checkbox>
          <v-expand-transition>
            <div
                v-if="dataModel.enableSchedule"
                class="pb-4"
            >
              <v-select
                  v-model="dataModel.forwardDays"
                  :items="forwardDaysItems"
                  :label="$t('editor.appointment.forwardDays')"
                  :return-object="false"
                  hide-details
                  class="mb-2"
              />

              <v-select
                  v-model="dataModel.gapMinutes"
                  :items="gapMinutesItems"
                  :label="$t('editor.appointment.gapMinutes')"
                  :return-object="false"
                  hide-details
                  class="mb-2"
              />

              <v-select
                  v-model="dataModel.reservationInterval"
                  :items="reservationIntervalItems"
                  :label="$t('editor.appointment.reservationInterval')"
                  :return-object="false"
                  hide-details
                  class="mb-2"
              />

              <v-select
                  v-model="dataModel.advanceMinutes"
                  :items="advanceMinutesItems"
                  :label="$t('editor.appointment.advanceMinutes')"
                  :return-object="false"
                  hide-details
                  class="mb-2"
              />
              
              
            </div>
          </v-expand-transition>
        </v-sheet>


        <v-sheet
            color="rgb(244 244 244)"
            class="pt-0 px-4 mb-2"
        >
          <v-checkbox
              v-model="dataModel.enableEmployees"
              :label="$t('editor.appointment.enableEmployees')"
              hide-details
          />
          <v-expand-transition>
            <div
                v-if="dataModel.enableEmployees"
                class="pb-4"
            >
              {{ $t('editor.appointment.selectEmployees') }}
              <v-select
                  v-model="dataModel.employees"
                  :items="employees"
                  :return-object="false"
                  item-title="name"
                  item-value="id"
                  :label="$t('editor.appointment.employees')"
                  chips
                  closable-chips
                  multiple
                  hide-details
              />
            </div>
          </v-expand-transition>
        </v-sheet>
      </v-form>
    </template>
    <template v-slot:design>

    </template>
  </common-block>
</template>
<script>
import blockMixin from "@cabinet/components/editor/blocks/blockMixin.js";
import employeesApi from "@cabinet/api/employees.js";
import ROUTE_NAMES from "@cabinet/router/routeNames.js";

export default {
  name: 'Appointment_1',
  mixins: [blockMixin],
  data() {
    return {
      routeNames: ROUTE_NAMES,
      employees: [],
      servicesTooltip: false,
      scheduleTooltip: false,
    };
  },
  computed: {
    forwardDaysItems() {
      return [
        {
          title: '1 ' + this.$t('editor.appointment.oneDay'),
          value: 1
        },
        {
          title: '2 ' + this.$t('editor.appointment.daysTwoToFour'),
          value: 2
        },
        {
          title: '3 ' + this.$t('editor.appointment.daysTwoToFour'),
          value: 3
        },
        {
          title: '4 ' + this.$t('editor.appointment.daysTwoToFour'),
          value: 4
        },
        {
          title: '5 ' + this.$t('editor.appointment.daysFiveAndMore'),
          value: 5
        },
        {
          title: '10 ' + this.$t('editor.appointment.daysFiveAndMore'),
          value: 10
        },
        {
          title: '15 ' + this.$t('editor.appointment.daysFiveAndMore'),
          value: 15
        },
        {
          title: '20 ' + this.$t('editor.appointment.daysFiveAndMore'),
          value: 20
        },
        {
          title: '30 ' + this.$t('editor.appointment.daysFiveAndMore'),
          value: 30
        },
        {
          title: '60 ' + this.$t('editor.appointment.daysFiveAndMore'),
          value: 60
        },
        {
          title: '90 ' + this.$t('editor.appointment.daysFiveAndMore'),
          value: 90
        },
      ];
    },
    gapMinutesItems() {
      return [
        {
          title: '0 ' + this.$t('editor.appointment.min'),
          value: 0
        },
        {
          title: '5 ' + this.$t('editor.appointment.min'),
          value: 5
        },
        {
          title: '10 ' + this.$t('editor.appointment.min'),
          value: 10
        },
        {
          title: '15 ' + this.$t('editor.appointment.min'),
          value: 15
        },
        {
          title: '20 ' + this.$t('editor.appointment.min'),
          value: 20
        },
        {
          title: '30 ' + this.$t('editor.appointment.min'),
          value: 30
        },
        {
          title: '45 ' + this.$t('editor.appointment.min'),
          value: 45
        },
        {
          title: '60 ' + this.$t('editor.appointment.min'),
          value: 60
        },
      ];
    },
    reservationIntervalItems() {
      return [
        {
          title: '5 ' + this.$t('editor.appointment.min'),
          value: 5
        },
        {
          title: '10 ' + this.$t('editor.appointment.min'),
          value: 10
        },
        {
          title: '15 ' + this.$t('editor.appointment.min'),
          value: 15
        },
        {
          title: '20 ' + this.$t('editor.appointment.min'),
          value: 20
        },
        {
          title: '30 ' + this.$t('editor.appointment.min'),
          value: 30
        },
        {
          title: '45 ' + this.$t('editor.appointment.min'),
          value: 45
        },
        {
          title: '60 ' + this.$t('editor.appointment.min'),
          value: 60
        },
      ];
    },
    advanceMinutesItems() {
      return [
        {
          title: this.$t('editor.appointment.no_limit'),
          value: 0
        },
        {
          title: '30 ' + this.$t('editor.appointment.min'),
          value: 30
        },
        {
          title: this.$t('editor.appointment.hour'),
          value: 60,
        },
        {
          title: this.$t('editor.appointment.hours2'),
          value: 120,
        },
        {
          title: this.$t('editor.appointment.hours3'),
          value: 180,
        },
        {
          title: this.$t('editor.appointment.hours6'),
          value: 360,
        },
        {
          title: this.$t('editor.appointment.hours12'),
          value: 720,
        },
      ];
    },
  },
  mounted() {
    this.fetchEmployees();
  },
  methods: {
    async fetchEmployees() {
      this.loadingModel = true;
      try {
        let employees = await employeesApi.getEmployees();
        employees = employees.map(item => {
          item.enabled = false;
          return item;
        });
        this.employees = employees;
      } finally {
        this.loadingModel = false;
      }
    },
  },
}
</script>
<style>
.info-link {
  color: rgb(var(--v-theme-info)) !important;
}
</style>