<template>
  <div
      class="d-flex align-center justify-center"
      style="height: 80vh"
  >
    <v-card
        width="400"
        class="mx-auto"
        flat
    >
      <v-card-title class="text-center">
        {{ $t('resetPassword.title') }}
      </v-card-title>

      <v-card-text v-if="done" class="text-center">
        <p>{{ $t('resetPassword.text1') }}</p>

        <v-btn
            color="primary"
            block
            class="mt-2"
            @click="$router.push({name: routeNames.LOGIN})"
        >
          {{ $t('resetPassword.login') }}
        </v-btn>
      </v-card-text>

      <v-card-text v-else>
        <v-alert
            v-model="errorAlert"
            class="mb-2"
            type="warning"
            density="compact"
            closable
        >
          {{ errorMessage }}
        </v-alert>
        <v-form
            ref="resetPasswordForm"
            @submit.prevent="submit"
        >
          <v-text-field
              v-model="resetPasswordData.email"
              :label="$t('resetPassword.email')"
              :rules="rules.emailValidation"
              :error-messages="errors.email"
              :disabled="disableEmail"
          />

          <v-text-field
              v-model="resetPasswordData.password"
              :label="$t('resetPassword.password')"
              :rules="rules.passwordValidation"
              :error-messages="errors.password"
              type="password"
          />
          <v-text-field
              v-model="resetPasswordData.password_confirmation"
              :label="$t('resetPassword.retypePassword')"
              :rules="rules.retypePasswordValidation"
              :error-messages="errors.password_confirmation"
              type="password"
          />

          <v-btn
              :loading="submitting"
              type="submit"
              color="primary"
              block
              class="mt-2"
          >
            {{ $t('resetPassword.setPassword') }}
          </v-btn>
        </v-form>
      </v-card-text>
      
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { INIT } from '@cabinet/store/actions';
import ROUTE_NAMES from "@cabinet/router/routeNames.js";
import auth from "@cabinet/api/auth.js";
import parseErrorsMixins from "@cabinet/mixins/parseErrorsMixins.js";
import {
  compose,
  ruleRequired,
  ruleEmail, ruleMin,
} from "@cabinet/utils/validationRules.js";

export default {
  name: 'ResetPassword',
  mixins: [parseErrorsMixins],
  data() {
    return {
      submitting: false,
      resetPasswordData: {
        token: null,
        email: null,
        password: null,
        password_confirmation: null,
      },
      done: false,
      disableEmail: false,
      routeNames: ROUTE_NAMES,
    };
  },
  computed: {
    rules() {
      const retypePasswordValidation = [];
      retypePasswordValidation.push(ruleRequired());
      retypePasswordValidation.push(
          (v) => v === this.resetPasswordData.password || this.$t('validation.passwordConfirmation')
      );
      
      return compose({
        emailValidation: [
          ruleRequired(),
          ruleEmail(),
        ],
        passwordValidation: [
          ruleRequired(),
          ruleMin('string', 6),
        ],
        retypePasswordValidation,
      });
    },
  },
  mounted() {
    this.resetPasswordData.token = this.$route.params.token;
    const url = new URL(window.location.href);
    const email = url.searchParams.get('email');
    if (email) {
      this.resetPasswordData.email = email;
      this.disableEmail = true;
    }
  },
  methods: {
    ...mapActions([INIT]),
    async submit() {
      this.hideError();
      const validate = await this.$refs.resetPasswordForm.validate();
      if (!validate.valid) {
        return;
      }
      this.submitting = true;
      try {
        await auth.resetPassword(this.resetPasswordData);
        this.done = true;
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.submitting = false;
      }
    },
  }
}
</script>
