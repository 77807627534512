<template>
  <common-block :design-enabled="false">
    <template v-slot:common>
      <div class="text-caption">{{ $t('editor.map.size') }}</div>
      <v-slider
          v-model="themeModel.height"
          :min="100"
          :max="500"
      ></v-slider>
    </template>
    <template v-slot:content>
      {{ $t('editor.map.description') }}
      
      <div :style="'height:' + themeModel.height + 'px;'">
        <l-map
            v-if="center"
            style="width: 100%"
            :zoom="themeModel.zoom"
            :center="center"
            :use-global-leaflet="false"
            @update:zoom="zoomUpdated"
            @update:center="centerUpdated"
            @update:bounds="boundsUpdated"
            @click="clickMap"
        >
          <l-tile-layer :url="url" :attribution="attribution" />
          <l-marker
              v-if="dataModel.markerLatLng"
              :lat-lng="dataModel.markerLatLng"
              :key="'marker_' + dataModel.markerLatLng.lat + '_' + dataModel.markerLatLng.lng"
          />
        </l-map>
      </div>
    </template>
    <template v-slot:design>
    </template>
  </common-block>
</template>
<script>
import _ from 'lodash';
import blockMixin from "@cabinet/components/editor/blocks/blockMixin.js";
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";

export default {
  name: 'Map_1',
  mixins: [blockMixin],
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      center: null,
      bounds: null
    };
  },
  mounted() {
    if (!this.dataModel.markerLatLng) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          this.center = {
            lat,
            lng,
          };
          this.themeModel.zoom = 12;
        }, () => {
          this.setDefaultPosition();
        });
      } else {
        this.setDefaultPosition();
      }
    } else {
      this.center = _.cloneDeep(this.dataModel.markerLatLng);
    }
  },
  methods: {
    setDefaultPosition() {
      this.themeModel.zoom = 2;
      this.center = {
        lat: 0,
        lng: 0,
      };
    },
    zoomUpdated (zoom) {
      this.themeModel.zoom = zoom;
    },
    centerUpdated (center) {
      this.center = center;
    },
    boundsUpdated (bounds) {
      this.bounds = bounds;
    },
    clickMap(event) {
      this.dataModel.markerLatLng = {
        lat: event.latlng.lat,
        lng: event.latlng.lng,
      };
    },
  }
}
</script>