<template>
  <v-card
      :loading="loading"
      flat
  >
    <v-toolbar>
      <v-toolbar-title>
        {{ $t('schedule.title') }}
      </v-toolbar-title>
      <v-btn
          variant="tonal"
          class="mr-1"
          @click="showBusinessHoursDialog"
      >
        {{ $t('schedule.workHours') }}
      </v-btn>
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn
              variant="tonal"
              v-bind="props"
          >
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="addEvent">
            <v-list-item-title>{{ $t('schedule.addEvent') }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$refs.specialDayDialog.show(userId, {}, 'working_time')">
            <v-list-item-title>{{ $t('schedule.addWorkingTime') }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$refs.specialDayDialog.show(userId, {}, 'day_off')">
            <v-list-item-title>{{ $t('schedule.addDayOff') }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$refs.specialDayDialog.show(userId, {}, 'break')">
            <v-list-item-title>{{ $t('schedule.addBreak') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      
    </v-toolbar>
    <v-card-text>
      <div v-if="employees && employees.length && user" class="flex-wrap text-right">
        <v-menu>
          <template v-slot:activator="{ props }">
            <v-btn
                v-bind="props"
                height="50"
                variant="tonal"
                class="mb-2"
            >
              <template v-slot:prepend>
                <v-avatar size="40" color="info">
                  <v-img
                      v-if="user.avatarUrl"
                      :src="user.avatarUrl"
                  />
                  <v-icon
                      v-else
                      icon="mdi-account-circle"
                      size="40"
                  />
                </v-avatar>
              </template>
              {{ user.name }} <span v-if="user.type === 'account'">({{ $t('schedule.supervisor') }})</span>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
                v-for="(user) in userList"
                @click="this.userId = user.id"
            >
              <template v-slot:prepend>
                <v-avatar size="30" color="info">
                  <v-img
                      v-if="user.avatarUrl"
                      :src="user.avatarUrl"
                  />
                  <v-icon
                      v-else
                      icon="mdi-account-circle"
                      size="30"
                  />
                </v-avatar>
              </template>
              <v-list-item-title>{{ user.name }} <span v-if="!user.owner_id">({{ $t('schedule.supervisor') }})</span></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        
      </div>
      
      
      <vue-cal
          :events="eventsToShow"
          :special-hours="specialHours"
          :time-from="timeFrom"
          :time-to="timeTo"
          :disable-views="['years', 'year', 'month', 'day']"
          :min-cell-width="130"
          :locale="$vuetify.locale.current"
          :on-event-click="onEventClick"
          :time-step="30"
          hide-view-selector
          active-view="week"
          @cell-dblclick="addEventByScheduleClick($event)"
          style="max-height: calc(100vh - 200px)"
        />
    </v-card-text>
    <confirmation ref="confirmation"/>
    <business-hours-dialog
        ref="businessHoursDialog"
        @saved="savedBusinessHoursHandler"
    />
    <special-day-dialog
        ref="specialDayDialog"
        :is-supervisor="$store.state.user.owner_id === null"
        @saved="loadSpecialDays"
        @deleted="loadSpecialDays"
    />
    <edit-event-dialog
        ref="editEventDialog"
        :is-supervisor="$store.state.user.owner_id === null"
        :require-date-time="true"
        @saved="loadEvents"
        @deleted="loadEvents"
    />
    <view-event-dialog
        ref="viewEventDialog"
        :is-supervisor="$store.state.user.owner_id === null"
        @deleted="loadEvents"
        @confirmed="loadEvents"
        @clickEdit="showEditEvent"
    />
  </v-card>
</template>

<script>
import _ from 'lodash';
import moment from "moment";
import VueCal from "vue-cal"
import "vue-cal/dist/vuecal.css"
import checkAuthorizedMixins from "@cabinet/mixins/checkAuthorizedMixins.js";
import Confirmation from "@cabinet/components/Confirmation.vue";
import BusinessHoursDialog from "@cabinet/components/schedule/BusinessHoursDialog.vue";
import businessHours from "@cabinet/api/businessHours.js";
import employeesApi from "@cabinet/api/employees.js";
import { timeToMinutes } from '@cabinet/utils/utils.js'
import SpecialDayDialog from "@cabinet/components/schedule/SpecialDayDialog.vue";
import telegramIconUrl from '@cabinet/assets/icons/telegram.svg';
import EditEventDialog from "@cabinet/components/events/EditEventDialog.vue";
import ViewEventDialog from "@cabinet/components/events/ViewEventDialog.vue";
export default {
  name: 'Schedule',
  components: {ViewEventDialog, EditEventDialog, SpecialDayDialog, BusinessHoursDialog, Confirmation, VueCal},
  mixins: [
    checkAuthorizedMixins,
  ],
  data() {
    return {
      telegramIconUrl,
      loading: false,
      loadingEmployees: false,
      loadingBusinessHours: false,
      loadingSpecialDays: false,
      loadingHoursBreak: false,
      loadingEvents: false,
      employees: null,
      userId: null,
      businessHours: null,
      hoursBreaks: null,
      specialDaysAllUsers: null,
      allEvents: null,
      timeCellHeight: 20,
    };
  },
  computed: {
    isSupervisor() {
      return this.$store.state.user.owner_id === null
    },
    
    isMobile() {
      return this.$vuetify.display.mobile;
    },
    
    usersById() {
      const users = {};
      this.userList.forEach(user => {
        users[user.id] = user;
      });
      return users;
    },
    
    user() {
      return this.usersById[this.userId];
    },
    
    userList() {
      let list = [];
      list.push(this.$store.state.user);
      if (this.employees && this.employees.length) {
        list = list.concat(this.employees);
      }
      return list;
    },

    eventsToShow() {
      const events = [];
      if (this.specialDays) {
        this.specialDays.forEach((item) => {
          if (item.is_open) {
            events.push({
              id: item.id,
              start: item.date + ' ' + item.open_time,
              end: item.date + ' ' + item.close_time,
              class: 'special-work-time',
              background: true,
              entity_type: 'special_day',
              entity: item,
            });
          }
          if (!item.is_open && (!item.open_time || !item.close_time)) {
            events.push({
              start: item.date + ' 00:00',
              end: item.date + ' 23:59',
              title: this.$t('schedule.dayOff'),
              class: 'special-rest-time',
              background: true,
              entity_type: 'special_day',
              entity: item,
            });
          }
          if (!item.is_open && item.open_time && item.close_time) {
            events.push({
              start: item.date + ' ' + item.open_time,
              end: item.date + ' ' + item.close_time,
              title: this.$t('schedule.break'),
              class: 'special-rest-time',
              background: true,
              entity_type: 'special_day',
              entity: item,
            });
          }
        });
      }
      
      if (this.events) {
        this.events.forEach((item) => {
          if (item.date && item.time_from && item.time_to) {
            events.push({
              start: item.date + ' ' + item.time_from,
              end: item.date + ' ' + item.time_to,
              title: item.name,
              class: 'event event-' + item.type + (!item.confirmed ? ' unconfirmed' : ''),
              background: false,
              entity_type: 'event',
              entity: item,
            });
          }
        });
      }
      return events;
    },
    businessHoursByUsersByWeekDays() {
      const usersWeekDays = {};
      if (!this.businessHours) {
        return usersWeekDays;
      }
      this.businessHours.forEach(item => {
        if (!Object.prototype.hasOwnProperty.call(
            usersWeekDays,
            item.user_id,
        )) {
          usersWeekDays[item.user_id] = {};
        }

        let day = item.day_of_week;
        if (day === 0) {
          day = 7;
        }
        usersWeekDays[item.user_id][day] = item;
      });
      return usersWeekDays;
    },

    businessHoursByWeekDays() {
      if (!this.businessHours || !this.user) {
        return {};
      }
      let userId = this.userId;
      if (this.user.owner_id && this.user.use_common_business_hours) {
        userId = this.user.owner_id;
      }
      if (!Object.prototype.hasOwnProperty.call(
          this.businessHoursByUsersByWeekDays,
          userId,
      )) {
        return {};
      }
      return this.businessHoursByUsersByWeekDays[userId];
    },
    
    hoursBreakByUsers() {
      const hoursBreaks = {};
      if (!this.hoursBreaks) {
        return hoursBreaks;
      }
      this.hoursBreaks.forEach(item => {
        hoursBreaks[item.user_id] = item;
      });
      return hoursBreaks;
    },
    
    specialDaysByUsers() {
      if (!this.specialDaysAllUsers) {
        return {}
      }
      let specialDaysByUsers = {};
      this.userList.forEach(user => {
        specialDaysByUsers[user.id] = null;
      });
      const commonSpecialDays = [];
      this.specialDaysAllUsers.forEach(item => {
        if (item.is_common) {
          commonSpecialDays.push(item);
        } else {
          if (!specialDaysByUsers[item.user_id]) {
            specialDaysByUsers[item.user_id] = [];
          }
          specialDaysByUsers[item.user_id].push(item);
        }
      });
      if (commonSpecialDays.length) {
        _.forEach(specialDaysByUsers, (item, userId) => {
          let values = item;
          if (!values) {
            values = [];
          }
          specialDaysByUsers[userId] = values.concat(commonSpecialDays);
        });
      }
      return specialDaysByUsers;
    },
    
    specialDays() {
      if (!this.user) {
        return null;
      }
      if (!Object.prototype.hasOwnProperty.call(
          this.specialDaysByUsers,
          this.userId,
      )) {
        return null;
      }
      return this.specialDaysByUsers[this.userId];
    },
    
    hoursBreak() {
      if (!this.user) {
        return null;
      }
      let userId = this.userId;
      if (this.user.owner_id && this.user.use_common_business_hours) {
        userId = this.user.owner_id;
      }
      if (!Object.prototype.hasOwnProperty.call(
          this.hoursBreakByUsers,
          userId,
      )) {
        return null;
      }
      return this.hoursBreakByUsers[userId];
    },

    eventsByUsers() {
      const events = {};
      if (!this.allEvents) {
        return events;
      }
      this.allEvents.forEach(item => {
        if (!events[item.user_id]) {
          events[item.user_id] = [];
        }
        events[item.user_id].push(item);
      });
      return events;
    },

    events() {
      if (!this.user) {
        return null;
      }
      if (!Object.prototype.hasOwnProperty.call(
          this.eventsByUsers,
          this.userId,
      )) {
        return null;
      }
      return this.eventsByUsers[this.userId];
    },
    
    timeFrom() {
      let earliest = null;
      if (this.businessHours) {
        this.businessHours.forEach(item => {
          const minutes = timeToMinutes(item.open_time);
          if (earliest === null || earliest > minutes) {
            earliest = minutes;
          }
        });
      }

      if (this.specialDays) {
        this.specialDays.forEach((item) => {
          if (item.is_open) {
            const minutes = timeToMinutes(item.open_time);
            if (earliest === null || earliest > minutes) {
              earliest = minutes;
            }
          }
        });
      }

      if (this.events) {
        this.events.forEach((item) => {
          if (item.time_from) {
            const minutes = timeToMinutes(item.time_from);
            if (earliest === null || earliest > minutes) {
              earliest = minutes;
            }
          }
        });
      }
      
      if (earliest !== null) {
        if (earliest % 60 === 0) {
          earliest = earliest >= 60 ? earliest-60 : earliest;
        } else {
          earliest = earliest >= 30 ? earliest-30 : earliest;
        }
      }
      return earliest === null ? 0 : earliest;
    },
    timeTo() {
      let latest = null;
      if (this.businessHours) {
        this.businessHours.forEach(item => {
          const minutes = timeToMinutes(item.close_time);
          if (latest === null || latest < minutes) {
            latest = minutes;
          }
        });
      }

      if (this.specialDays) {
        this.specialDays.forEach((item) => {
          if (item.is_open) {
            const minutes = timeToMinutes(item.close_time);
            if (latest === null || latest < minutes) {
              latest = minutes;
            }
          }
        });
      }

      if (this.events) {
        this.events.forEach((item) => {
          if (item.time_to) {
            const minutes = timeToMinutes(item.time_to);
            if (latest === null || latest < minutes) {
              latest = minutes;
            }
          }
        });
      }
      
      if (latest !== null) {
        if (latest % 60 === 0) {
          latest = latest <= 1380 ? latest+60 : latest;
        } else {
          latest = latest <= 1410 ? latest+30 : latest;
        }
      }
      return latest === null ? 24 * 60 : latest;
    },
    
    
    specialHoursOpening() {
      const openingHours = {};
      for (let day = 1; day <= 7; day++) {
        if (Object.prototype.hasOwnProperty.call(
            this.businessHoursByWeekDays,
            day,
        )) {
          const businessHours = this.businessHoursByWeekDays[day];

          openingHours[day] = [
            {
              from: timeToMinutes(businessHours.open_time),
              to: timeToMinutes(businessHours.close_time),
              class: 'opening-hours',
            },
          ]
        }
      }
      return openingHours;
    },

    specialHoursBreaks() {
      const breaksHours = {};
      if (!this.hoursBreak || !this.hoursBreak.start_time || !this.hoursBreak.end_time) {
        return breaksHours;
      }
      for (let day = 1; day <= 7; day++) {
        if (Object.prototype.hasOwnProperty.call(
            this.businessHoursByWeekDays,
            day,
        )) {
          breaksHours[day] = [
            {
              from: timeToMinutes(this.hoursBreak.start_time),
              to: timeToMinutes(this.hoursBreak.end_time),
              class: 'break-hours',
            },
          ]
        }
      }
      return breaksHours;
    },
    
    specialHours() {
      const specialHours = {};
      for (let day = 1; day <= 7; day++) {
        specialHours[day] = [];
      }
      _.forEach(this.specialHoursOpening, (items, day) => {
        specialHours[day] = specialHours[day].concat(items);
      });
      _.forEach(this.specialHoursBreaks, (items, day) => {
        specialHours[day] = specialHours[day].concat(items);
      });
      
      return specialHours;
    }
  },
  mounted() {
    this.userId = this.$store.state.user.id;
    this.fetchData();
  },
  methods: {
    addEventByScheduleClick(cellTime) {
      let momentDate = moment(cellTime);
      if (momentDate.minutes() < 30) {
        momentDate.minutes(0);
      } else if (momentDate.minutes() >= 30) {
        momentDate.minutes(30);
      }
      momentDate.seconds(0);
      const momentDateTo = momentDate.clone();
      if (momentDateTo.hours() === 23 &&  momentDateTo.minutes() === 30) {
        momentDateTo.add(29, 'minutes');
      } else {
        momentDateTo.add(30, 'minutes');
      }
      console.log(this.user.id, 'userid');
      this.$refs.editEventDialog.show(
          null,
          this.user.id,
          momentDate.format('YYYY-MM-DD'),
          momentDate.format('HH:mm'),
          momentDateTo.format('HH:mm')
      )
    },
    addEvent() {
      this.$refs.editEventDialog.show(
          null,
          this.user.id
      )
    },
    async fetchData() {
      this.loading = true;
      try {
        const promises = [
          this.loadEmployees(),
          this.loadBusinessHours(),
          this.loadHoursBreak(),
          this.loadSpecialDays(),
          this.loadEvents(),
        ];
        await Promise.all(promises);
      } finally {
        this.loading = false;
      }
    },
    async loadEmployees() {
      try {
        this.loadingEmployees = true;
        const response = await employeesApi.getEmployeesScheduleList();
        this.employees = response && response.length ? response : null;
      } finally {
        this.loadingEmployees = false;
      }
    },
    
    async loadBusinessHours() {
      try {
        this.loadingBusinessHours = true;
        const response = await businessHours.getBusinessHours();
        this.businessHours = response.data.length ? response.data : null;
      } finally {
        this.loadingBusinessHours = false;
      }
    },
    async loadHoursBreak() {
      try {
        this.loadingHoursBreak = true;
        const response = await businessHours.getBusinessHoursBreak();
        this.hoursBreaks = response.data;
      } finally {
        this.loadingHoursBreak = false;
      }
    },
    async loadSpecialDays() {
      try {
        this.loadingSpecialDays = true;
        const response = await businessHours.getSpecialDays();
        this.specialDaysAllUsers = response.data.length ? response.data : null;
      } finally {
        this.loadingSpecialDays = false;
      }
    },
    async loadEvents() {
      try {
        this.loadingEvents = true;
        const response = await businessHours.getEvents();
        this.allEvents = response.data.length ? response.data : null;
      } finally {
        this.loadingEvents = false;
      }
    },
    showBusinessHoursDialog() {
      this.$refs.businessHoursDialog.show(this.userId);
    },
    onEventClick(event, e) {
      if (event.entity_type === 'special_day') {
        this.$refs.specialDayDialog.show(this.userId, event.entity);
      } else if (event.entity_type === 'event') {
        this.$refs.viewEventDialog.show(event.entity.id);
      }
      e.stopPropagation();
    },
    showEditEvent(eventId) {
      this.$refs.editEventDialog.show(eventId);
    },
    savedBusinessHoursHandler() {
      this.loadBusinessHours();
      this.loadHoursBreak();
      this.loadEmployees();
    }
  },
}
</script>
<style>
  .vuecal__no-event {display: none;}
  .vuecal__special-hours {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;

    em {font-size: 0.9em;color: #999;}
  }

  .vuecal__event.special-work-time {
    background:
        rgba(242, 255, 240, 0.5)
        repeating-linear-gradient(
            -45deg,
            rgba(109, 255, 87, 0.2),
            rgba(87, 255, 101, 0.2) 5px,
            rgba(255, 255, 255, 0) 5px,
            rgba(255, 255, 255, 0) 15px
        );
    color: #4cf654;
    border: 1px solid rgba(135, 255, 87, 0.25);

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    .vuecal__event-time {
      display: none;
      align-items: center;
    }
  }
  .vuecal__event.special-rest-time {
    background:
        rgba(255, 247, 240, 0.5)
        repeating-linear-gradient(
            -45deg,
            rgba(255, 162, 87, 0.1),
            rgba(255, 162, 87, 0.1) 5px,
            rgba(255, 255, 255, 0) 5px,
            rgba(255, 255, 255, 0) 15px
        );
    color: #f6984c;
    border: 1px solid rgba(255, 162, 87, 0.25);
    
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    .vuecal__event-time {
      display: none;
      align-items: center;
    }
  }

  .vuecal__event.event {
    .vuecal__event-time {
      display: block;
      align-items: center;
    }
  }

  .vuecal__event.event.event-appointment {
    background-color: #3e97f1;
    color: #FFFFFF;
    border: solid 1px #3684c0;
    border-radius: 4px;
    cursor: pointer;
    
    .vuecal__event-time {
      display: block;
      align-items: center;
      color: #c0e3ff;
    }
  }
  .vuecal__event.event.event-appointment:hover {
    background-color: #2d8ef1;
  }

  .vuecal__event.event.event-appointment.unconfirmed {
    background-color: rgba(62, 151, 241, 0.3);
    color: #3684c0;
    border: solid 1px rgba(62, 151, 241, 0.3);
    border-radius: 4px;
    cursor: pointer;
    
    .vuecal__event-time {
      color: #6193b9;
    }
  }
  .vuecal__event.event.event-appointment.unconfirmed:hover {
    background-color: rgba(41, 140, 239, 0.54);
  }


  .vuecal__event.event.event-custom {
    background-color: #01a61e;
    color: #FFFFFF;
    border: solid 1px #01961d;
    border-radius: 4px;
    cursor: pointer;

    .vuecal__event-time {
      display: block;
      align-items: center;
      color: #c0ffc5;
    }
  }
  .vuecal__event.event.event-custom:hover {
    background-color: #01961d;
  }
  
  .opening-hours {
    background: rgba(205, 255, 202, 0.5);
  }
  .break-hours {
    background: rgba(246, 152, 76, 0.5);
    color: #fd8a30;
  }
  
  .day-off {
    background:
        rgba(255, 247, 240, 0.5)
        repeating-linear-gradient(
            -45deg,
            rgba(255, 162, 87, 0.1),
            rgba(255, 162, 87, 0.1) 5px,
            rgba(255, 255, 255, 0) 5px,
            rgba(255, 255, 255, 0) 15px
        );
    color: #f6984c;
    border: 1px solid rgba(255, 162, 87, 0.25);
  }

</style>
