<template>
  <div>
    <div class="text-center">
      <v-avatar
          :size="size"
          color="info"
      >
        <v-img
            v-if="avatarUrl"
            :src="avatarUrl"
        />
        <v-icon
            v-else
            :size="size"
        >
          mdi-account-circle
        </v-icon>
      </v-avatar>
    </div>

    <image-cropper
        ref="imageCropper"
        :width="imageSize"
        :height="imageSize"
        @saved="applyNewAvatar"
    />
    <input
        type="file"
        ref="fileInput"
        @change="handleFileChange"
        style="display: none;"
        accept="image/*"
    />
    <v-toolbar class="flat" color="white">
      <v-spacer />
      <v-btn
          color="red"
          @click="deleteAvatarImg"
          :disabled="!avatarUrl"
          class="mr-1"
      >
        {{ $t('delete') }}
      </v-btn>
      <v-btn
          color="primary"
          variant="tonal"
          @click="$refs.fileInput.click()"
      >
        {{ $t('choose') }}
      </v-btn>
      <v-spacer />
    </v-toolbar>
  </div>
</template>
<script>
import ImageCropper from "@cabinet/components/ImageCropper.vue";

export default {
  name: 'Avatar',
  components: {ImageCropper},
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    size: {
      type: Number,
      required: true
    },
    imageSize: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      avatarUrl: this.modelValue.avatarUrl,
      isAvatarTempUrl: false,
    };
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      console.log(file, 'file');
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const src = e.target.result;
          this.$refs.imageCropper.show(src);
        };
        reader.readAsDataURL(file);
        this.$refs.fileInput.value = null;
      }
    },
    applyNewAvatar(tempUrl) {
      this.isAvatarTempUrl = true;
      this.avatarUrl = tempUrl;
      this.updateModelValue();
    },
    deleteAvatarImg() {
      this.isAvatarTempUrl = false;
      this.avatarUrl = null;
      this.updateModelValue();
    },
    updateModelValue() {
      const data = {
        avatarUrl: this.avatarUrl,
      };
      if (this.isAvatarTempUrl) {
        data.isAvatarTempUrl = true;
      }
      this.$emit('update:modelValue', data);
    }
  }
}
</script>