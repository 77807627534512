<template>
  <v-dialog
      v-model="dialog"
      max-width="800"
      class="subscribe-dialog"
  >
    <v-card
        min-height="350"
    >
      <v-card-title>{{ $t('settings.notifications.telegramSubscribeTitle') }}</v-card-title>
      <v-divider />
      <v-card-text>
        <ul>
          <li>
            {{ $t('settings.notifications.findBot') }}
            <ul class="dashed">
              <li>
                {{ $t('settings.notifications.byScanningQrCode') }}
                <v-img :src="qrUrl" width="150"></v-img>
              </li>

              <li>{{ $t('settings.notifications.byClickingOnLink') }} <a :href="'https://t.me/' + botId" target="_blank">@{{ botId }}</a></li>
              <li>{{ $t('settings.notifications.findManually') }} "<b>{{ botId }}</b>" {{ $t('settings.notifications.or') }} "<b>{{ botName }}</b>"</li>
            </ul>
          </li>
          <li v-html="$t('settings.notifications.pressStartButton')" />
          <li v-html="$t('settings.notifications.chooseSubscribe')" />
        </ul>
        <div class="text-center">
          <span class="subscription-code">{{ subscriptionCode }}</span>
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
            @click="dialog = false;"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
            variant="tonal"
            @click="dialog = false;"
        >
          {{ $t('done') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import soclink_kz_bot from '@cabinet/assets/images/soclink_kz_bot.jpg';
export default {
  name: 'SubscribeTelegramDialog',
  props: {
    subscriptionCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      botId: null,
      botName: null,
      qrUrls: {
        soclink_kz_bot
      },
    };
  },
  created() {
    this.botId = this.siteUrl = import.meta.env.VITE_TELEGRAM_BOT_ID;
    this.botName = this.siteUrl = import.meta.env.VITE_TELEGRAM_BOT_NAME;
  },
  computed: {
    qrUrl() {
      return this.qrUrls[this.botId];
    },
  },
  methods: {
    show() {
      this.dialog = true;
    },
    hide() {
      this.dialog = false;
    },
  }
}
</script>
<style>
.subscribe-dialog {
  .subscription-code {
    font-weight: bold;
    font-size: 2em;
    background-color: #e7e7e7;
    padding: 5px 10px;
    border-radius: 3px;
  }
  
  ul {
    list-style-type: decimal;
    margin: 0 0 0 10px;
    padding: 0;
  }
  
  li {
    padding: 8px;
  }

  ul ul {
    margin-left: 20px;
    list-style-type: none;
  }
  
  ul.dashed > li {
    text-indent: -16px;
  }
  ul.dashed > li:before {
    content: "—";
    font-weight: bold;
    text-indent: -20px;
  }
  
  li a {
    text-decoration: underline;
    color: #333;
  }
}
</style>