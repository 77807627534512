<template>
  <div
      class="d-flex align-center justify-center"
      style="height: 80vh"
  >
    <v-card
        width="400"
        class="mx-auto"
        flat
    >
      <v-card-title class="text-center">
        {{ $t('forgotPassword.title') }}
      </v-card-title>

      <v-card-text v-if="sent" class="text-center">
        <p>{{ $t('forgotPassword.text1') }}</p>

        <v-btn
            color="primary"
            block
            class="mt-2"
            @click="$router.push({name: routeNames.LOGIN})"
        >
          {{ $t('forgotPassword.login') }}
        </v-btn>
      </v-card-text>
      
      <v-card-text v-else>
        <v-alert
            v-model="errorAlert"
            class="mb-2"
            type="warning"
            density="compact"
            closable
        >
          {{ errorMessage }}
        </v-alert>
        <v-form
            ref="forgotPasswordForm"
            @submit.prevent="submit"
        >
          <v-text-field
              v-model="email"
              :label="$t('forgotPassword.email')"
              :rules="rules.emailValidation"
              :error-messages="errors.email"
          />

          <v-btn
              :loading="submitting"
              type="submit"
              color="primary"
              block
              class="mt-2"
          >
            {{ $t('forgotPassword.recoverPassword') }}
          </v-btn>
          
          <div class="mt-3">
            <p class="text-body-2">{{ $t('forgotPassword.haveAccount') }}
              <router-link :to="{name: routeNames.LOGIN}">{{ $t('forgotPassword.login') }}</router-link>
            </p>
          </div>
        </v-form>
      </v-card-text>
      
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { INIT } from '@cabinet/store/actions';
import ROUTE_NAMES from "@cabinet/router/routeNames.js";
import auth from "@cabinet/api/auth.js";
import parseErrorsMixins from "@cabinet/mixins/parseErrorsMixins.js";
import {
  compose,
  ruleRequired,
  ruleEmail,
} from "@cabinet/utils/validationRules.js";

export default {
  name: 'ForgotPassword',
  mixins: [parseErrorsMixins],
  data() {
    return {
      submitting: false,
      email: null,
      sent: false,
      routeNames: ROUTE_NAMES,
    };
  },
  computed: {
    rules() {
      return compose({
        emailValidation: [
          ruleRequired(),
          ruleEmail(),
        ],
      });
    },
  },
  methods: {
    ...mapActions([INIT]),
    async submit() {
      this.hideError();
      const validate = await this.$refs.forgotPasswordForm.validate();
      if (!validate.valid) {
        return;
      }
      this.submitting = true;
      try {
        const submitData = {
          email: this.email,
        };
        await auth.forgotPassword(submitData);
        this.sent = true;
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.submitting = false;
      }
    },
  }
}
</script>
