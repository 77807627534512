<template>
  <v-card>
    <v-card-text class="mt-0 text-center">
      {{ $t('loading') }}...
      <v-progress-linear
          indeterminate
          color="primary"
          class="mb-2"
      ></v-progress-linear>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'DialogLoading',

  data() {
    return {

    };
  },
}
</script>
