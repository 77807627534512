<template>
  <div>
    <v-card
        :loading="loading"
        flat
        tile
    >
      <v-sheet
          class="pt-4 pl-2 pr-2"
      >
        <v-list max-width="500">
          <v-list-item title="Telegram"lines="three">
            <template v-slot:prepend>
              <v-avatar size="40">
                <v-img :src="telegramIconUrl"></v-img>
              </v-avatar>
            </template>
            <template v-slot:subtitle>
              {{ $t('settings.notifications.telegramSubtitle') }}<br />
              <span v-if="subscriptionsData.has_telegram_subscription" style="color: #008000">
                {{ $t('settings.notifications.subscribed') }}
              </span>
              <span v-else style="color: #FF0000">
                {{ $t('settings.notifications.notSubscribed') }}
              </span>
            </template>
            <template v-slot:append>
              <v-btn
                  v-if="subscriptionsData.has_telegram_subscription"
                  variant="tonal"
                  class="ml-1"
                  color="red"
                  @click="unsubscribeTelegram"
              >
                {{ $t('settings.notifications.unsubscribe') }}
              </v-btn>
              <v-btn
                  v-else
                  variant="tonal"
                  class="ml-1"
                  @click="subscribeTelegram"
              >
                {{ $t('settings.notifications.subscribe') }}
              </v-btn>
            </template>
          </v-list-item>

          <v-list-item v-if="webPushSupports" title="Web Push" subtitle="" lines="three">
            <template v-slot:prepend>
              <v-avatar size="40" class="rounded-0">
                <v-icon size="40">mdi-message-badge-outline</v-icon>
              </v-avatar>
            </template>
            <template v-slot:subtitle>
              {{ $t('settings.notifications.webpushSubtitle') }}<br />
              <span v-if="isWebPushSubscribed" style="color: #008000">
                {{ $t('settings.notifications.subscribed') }}
              </span>
              <span v-else style="color: #FF0000">
                {{ $t('settings.notifications.notSubscribed') }}
              </span>
            </template>
            <template v-slot:append>
              <v-btn
                  v-if="isWebPushSubscribed"
                  variant="tonal"
                  class="ml-1"
                  color="red"
                  @click="unsubscribeWebPush"
              >
                {{ $t('settings.notifications.unsubscribe') }}
              </v-btn>
              <v-btn v-else
                     :loading="webPushSubscribing"
                     variant="tonal"
                     class="ml-1"
                     @click="webpushSubscribe"
              >
                {{ $t('settings.notifications.subscribe') }}
              </v-btn>
            </template>
          </v-list-item>
        </v-list>
        
        <v-alert
            v-model="errorAlert"
            class="mb-2"
            type="warning"
            density="compact"
            closable
        >
          {{ errorMessage }}
        </v-alert>
        <v-form
            ref="notificationsForm"
            @submit.prevent="saveSettings"
        >
          
        </v-form>
      </v-sheet>

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary"
               :disabled="!edited"
               @click="cancelEdit"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn color="primary"
               variant="tonal"
               :disabled="!edited"
               :loading="saving"
               @click="saveSettings"
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <confirmation ref="confirmation"/>
    <subscribe-telegram-dialog
        ref="telegramSubscriptionDialog"
        v-if="subscriptionsData.telegram_subscription_code"
        :subscription-code="subscriptionsData.telegram_subscription_code"
    />
  </div>
</template>
<script>
import _ from 'lodash';
import telegramIconUrl from '@cabinet/assets/icons/telegram.svg';
import parseErrorsMixins from "@cabinet/mixins/parseErrorsMixins.js";
import userApi from "@cabinet/api/user.js";
import notificationsApi from "@cabinet/api/notifications.js";
import Confirmation from "@cabinet/components/Confirmation.vue";
import SubscribeTelegramDialog from "@cabinet/components/settings/SubscribeTelegramDialog.vue";
import {eventBus, events} from "@cabinet/utils/eventBus.js";

export default {
  name: 'Notifications',
  components: {SubscribeTelegramDialog, Confirmation},
  mixins: [parseErrorsMixins],
  data() {
    return {
      loading: true,
      saving: false,
      webPushSubscriptionEndpoint: null,
      webPushSubscribing: false,
      subscriptionsData: {
        has_telegram_subscription: false,
        telegram_subscription_code: null,
        web_push_subscriptions: [],
      },
      settings: {},
      settingsOriginal: {},
      telegramIconUrl,
    };
  },
  computed: {
    edited() {
      return !_.isEqual(this.settings, this.settingsOriginal);
    },
    rules() {
      return {};
    },
    serviceWorkerRegistration() {
      return this.$store.state.serviceWorkerRegistration;
    },
    webPushSupports() {
      return this.$store.state.serviceWorkerRegistration && 'PushManager' in window;
    },
    isWebPushSubscribed() {
      let isSubscribed = false;
      this.subscriptionsData.web_push_subscriptions.forEach(item => {
        if (item.endpoint === this.webPushSubscriptionEndpoint) {
          isSubscribed = true;
        }
      });
      return isSubscribed;
    },
  },
  watch: {
    webPushSupports() {
      if (this.webPushSupports) {
        this.checkWebPushSubscription();
      }
    }
  },
  mounted() {
    eventBus.$on(events.TELEGRAM_BOT_SUBSCRIBED, this.botSubscribed);
    eventBus.$on(events.TELEGRAM_BOT_UNSUBSCRIBED, this.botUnsubscribed);
    this.fetchData();
    if (this.webPushSupports) {
      this.checkWebPushSubscription();
    }
  },
  beforeUnmount() {
    eventBus.$off(events.TELEGRAM_BOT_SUBSCRIBED, this.botSubscribed);
    eventBus.$off(events.TELEGRAM_BOT_SUBSCRIBED, this.botUnsubscribed);
  },
  methods: {
    checkWebPushSubscription() {
      const that = this;
      this.serviceWorkerRegistration.pushManager.getSubscription()
          .then(function(subscription) {
            if (subscription) {
              that.webPushSubscriptionEndpoint = subscription.endpoint;
            } else {
              that.webPushSubscriptionEndpoint = null;
            }
        });
    },
    
    async webpushSubscribe() {
      try {
        this.webPushSubscribing = true;
        let subscription = await this.serviceWorkerRegistration.pushManager.getSubscription();
        if (!subscription) {
          subscription = await this.serviceWorkerRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: this.urlBase64ToUint8Array('BM7TAnGwHxiz0F9i0ypSxPjTXYdww05oz9iiXrKz-kbcIryJWOPen8jgEl_LrOnmeMHl8B7R7mHAcN73GslV5Pk'),
          })
        }
        var rawKey = subscription.getKey ? subscription.getKey('p256dh') : '';
        var key = rawKey ? btoa(String.fromCharCode.apply(null, new Uint8Array(rawKey))) : null;
        var rawAuthSecret = subscription.getKey ? subscription.getKey('auth') : '';
        var token = rawAuthSecret ? btoa(String.fromCharCode.apply(null, new Uint8Array(rawAuthSecret))) : null;
        var contentEncoding = (PushManager.supportedContentEncodings || ['aesgcm'])[0];

        await notificationsApi.subscribeWebPush({
          endpoint: subscription.endpoint,
          key,
          token,
          contentEncoding
        });
        this.webPushSubscriptionEndpoint = subscription.endpoint;
        await this.fetchSubscriptionsData();
      } catch (e) {
        console.log('Declined');
      } finally {
        this.webPushSubscribing = false;
      }
    },

    urlBase64ToUint8Array(base64String) {
      const padding = '='.repeat((4 - base64String.length % 4) % 4);
      const base64 = (base64String + padding)
          .replace(/\-/g, '+') // Заменяем символы - на +
          .replace(/_/g, '/'); // Заменяем символы _ на /

      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },
    
    botSubscribed(payload) {
      this.fetchSubscriptionsData();
      this.$refs.telegramSubscriptionDialog.hide();
    },
    botUnsubscribed(payload) {
      this.fetchSubscriptionsData();
    },
    
    async fetchData() {
      this.loading = true;
      try {
        await this.fetchSubscriptionsData();
        await this.$refs.notificationsForm.resetValidation();
      } finally {
        this.loading = false;
      }
    },
    
    async fetchSubscriptionsData() {
      const subscriptionsDataResponse = await notificationsApi.getSubscriptionsData();
      this.subscriptionsData = subscriptionsDataResponse.data;
    },

    cancelEdit() {
      this.$refs.confirmation.showConfirm(
          this.$t('settings.profile.cancelEditTitle'),
          this.$t('settings.profile.cancelEditText'),
          () => {
            this.settings = _.cloneDeep(this.settingsOriginal);
          }
      );
    },

    async saveSettings() {
      this.hideError();
      const validate = await this.$refs.profileForm.validate()
      if (!validate.valid) {
        return;
      }
      this.saving = true;
      try {
        const response = await userApi.saveProfile(this.profile);
        const profileData = response.data;
        profileData.currentPassword = null;
        profileData.newPassword = null;
        this.profile = _.cloneDeep(profileData);
        this.profileOriginal = _.cloneDeep(profileData)
        this.retypePassword = null;
        this.showSetNewPassword = false;
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.saving = false;
      }
    },

    subscribeTelegram() {
      this.$refs.telegramSubscriptionDialog.show();
    },

    unsubscribeTelegram() {
      this.$refs.confirmation.showConfirm(
          this.$t('confirmation'),
          this.$t('settings.notifications.unsubscribeTelegram'),
          async () => {
            await notificationsApi.unsubscribeTelegram();
            await this.fetchSubscriptionsData();
          }
      );
    },

    unsubscribeWebPush() {
      this.$refs.confirmation.showConfirm(
          this.$t('confirmation'),
          this.$t('settings.notifications.unsubscribeWebPush'),
          async () => {
            await this.unsubscribeWebPushProcess();
            await this.fetchSubscriptionsData();
          }
      );
    },
    
    async unsubscribeWebPushProcess() {
      const subscription = await this.serviceWorkerRegistration.pushManager.getSubscription();
      if (subscription) {
        try {
          const endpoint = subscription.endpoint;
          await subscription.unsubscribe();
          this.webPushSubscriptionEndpoint = null;
          await notificationsApi.unsubscribeWebPush({
            endpoint,
          });
          await this.fetchSubscriptionsData();
        } catch (e) {
          console.log('Unsubscription error: ', e);
        }
      }
    }
  }
};

</script>