import { createStore } from 'vuex';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';
import editor from './Editor';
const storeObject = {
    modules: {
        editor,
    },
    state() {
        return {
            initializing: true,
            user: null,
            title: null,
            i18n: null,
            serviceWorkerRegistration: null,
        }
    },
    actions,
    getters,
    mutations,
}

const store = createStore(storeObject);
export default store;