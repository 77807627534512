<template>
  <v-dialog
      v-model="dialog"
      max-width="500"
      persistent
  >
    <v-card>
      <v-card-text class="pa-0">
        <cropper
            v-if="src"
            ref="cropper"
            :src="src"
            :auto-zoom="true"
            :stencil-size="{
              width,
              height,
            }"
            class="cropper"
            image-restriction="stencil"
        />
      </v-card-text>

      <v-card-actions>
        <v-btn @click="this.dialog = false">
          {{ $t('cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
            :loading="uploading"
            @click="saveImage"
        >
          {{ $t('done') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import Pica from "pica";
import {Cropper} from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import uploads from "@cabinet/api/uploads.js";

export default {
  name: 'ImageCropper',
  components: {Cropper},
  props: {
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    thumbnail: {
      type: Boolean,
      required: false,
      default: false,
    },
    thumbnailWidth: {
      type: Number,
      required: false,
    },
    thumbnailHeight: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      dialog: false,
      src: null,
      uploading: false,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.mobile;
    },
  },
  methods: {
    show(src) {
      this.dialog = true;
      this.src = src;
    },
    async saveImage() {
      try {
        this.uploading = true;
        const blob = await this.getResizedImageBlob(this.width, this.height);
        const tmpUrl = await this.uploadBlobDataToTmp(blob);
        if (this.thumbnail) {
          const thumbnailBlob = await this.getResizedImageBlob(this.thumbnailWidth, this.thumbnailHeight);
          const thumbnailTmpUrl = await this.uploadBlobDataToTmp(thumbnailBlob);
          this.$emit('saved', {
            tmpUrl,
            thumbnailTmpUrl,
          });
        } else {
          this.$emit('saved', tmpUrl);
        }
        this.dialog = false;
        this.src = null;
      } finally {
        this.uploading = false;
      }
    },
    async getResizedImageBlob(width, height) {
      const result = this.$refs.cropper.getResult();
      const croppedCanvas = result.canvas;

      const scaledCanvas = document.createElement('canvas');
      scaledCanvas.width = width;
      scaledCanvas.height = height;

      // Initialize Pica with maximum quality settings
      const pica = new Pica({
        alpha: true,
        unsharpAmount: 160,  // Increase unsharp amount for better clarity
        unsharpThreshold: 1  // Lower threshold for more sharpening
      });

      await pica.resize(croppedCanvas, scaledCanvas, {
        unsharpAmount: 160,
        unsharpThreshold: 1
      });

      const imageDataUrl = scaledCanvas.toDataURL('image/webp');
      return await this.dataURLtoBlob(imageDataUrl);
    },

    dataURLtoBlob(dataURL) {
      const byteString = atob(dataURL.split(',')[1]);
      const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },

    async uploadBlobDataToTmp(blob) {
      const uploadsData = await uploads.getTmpUploadsData('webp');
      await axios.put(uploadsData.upload_url, blob, {
        headers: {
          'Content-Type': blob.type
        }
      })
      return uploadsData.url;
    }
  },
};
</script>
<style>
.cropper {
  max-height: calc(100vh - 220px);
  background: #E8EEFE;
}
</style>