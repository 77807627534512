<template>
  <v-app-bar
      flat
      color="background"
  >
    <template v-slot:prepend>
      <v-btn
          v-if="isMobile"
          icon="mdi-menu"
          size="small"
          style="z-index: 2"
          @click="$emit('clickMenuExpand')"
      />
    </template>
    <editor-menu v-if="showHeaderEditorMenu && !isMobile" class="editor-menu"/>
    <v-spacer />
    <notifications />
    <language :authorized="true" :small="showHeaderEditorMenu"/>
    <profile-menu :small="showHeaderEditorMenu"/>
  </v-app-bar>
</template>

<script>
import Language from "@cabinet/components/Language.vue";
import EditorMenu from "@cabinet/components/editor/EditorMenu.vue";
import ProfileMenu from "@cabinet/components/ProfileMenu.vue";
import Notifications from "@cabinet/components/Notifications.vue";

export default {
  name: 'AppBar',
  components: {Notifications, ProfileMenu, EditorMenu, Language},
  data() {
    return {
      
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.mobile;
    },
    showHeaderEditorMenu() {
      return this.$store.state.editor.showHeaderEditorMenu;
    }
  },
}
</script>
<style>
.editor-menu {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>