<template>
  <layout>
    <loading v-if="$store.state.initializing"/>
    <router-view
        v-if="$store.getters.initialized"
        :key="$route.fullPath"
    />
  </layout>
</template>

<script>
import { mapActions } from 'vuex';
import { INIT } from './store/actions';
import Layout from "@cabinet/components/Layout.vue";
import Loading from "@cabinet/components/Loading.vue";

export default {
  name: 'App',
  components: {Loading, Layout},
  data() {
    return {
    };
  },
  async created() {
    await this.INIT();
  },
  methods: {
    ...mapActions([INIT]),
  }
}
</script>
