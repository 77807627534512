import { en } from 'vuetify/locale'
export default {
    $vuetify: {
        ...en
    },
    10: '10',
    25: '25',
    50: '50',
    100: '100',
    titles: {
        editor: 'Editor',
    },
    loadingYourPage: 'Loading your page',
    datetimeFormat: 'MM/DD/YYYY h:mm A',
    dateFormat: 'MM/DD/YYYY',
    shortTimeFormat: 'h:mm A',
    date: 'Date',
    cancel: 'Cancel',
    apply: 'Apply',
    save: 'Save',
    delete: 'Delete',
    done: 'Done',
    create: 'Create',
    add: 'Add',
    edit: 'Edit',
    close: 'Close',
    clear: 'Clear',
    loading: 'Loading',
    confirm: 'Confirm',
    confirmation: 'Confirmation',
    loadMore: 'Load More',
    choose: 'Choose',
    dateRangePicker: {
        dayOfWeek: {
            sun: 'Sun',
            mon: 'Mon',
            tue: 'Tue',
            wed: 'Wed',
            thu: 'Thu',
            fri: 'Fri',
            sat: 'Sat',
        },
        monthNames: {
            jan: 'Jan',
            feb: 'Feb',
            mar: 'Mar',
            apr: 'Apr',
            may: 'May',
            jun: 'Jun',
            jul: 'Jul',
            aug: 'Aug',
            sep: 'Sep',
            oct: 'Oct',
            nov: 'Nov',
            dec: 'Dec',
        },
        ranges: {
            today: 'Today',
            yesterday: 'Yesterday',
            lastSevenDays: 'Last 7 days',
            monthToDate: 'Month to date',
            previousMonth: 'The previous Month',
        },
    },
    notifications: {
        noNotifications: 'No notifications',
    },
    phoneInput: {
        country: 'Country',
        invalidPhoneMessage: 'The "Phone Number" field is not a valid phone number (example: +7 (705) 123 4567)',
    },
    emailVerify: {
        hello: 'Hello',
        text1: 'A link for email verification has been sent to your email <b>{email}</b>.',
        text2: 'To confirm your email address and continue using the service, please follow the link in the email.',
        logout: 'Logout',
        sendAgain: 'Send Again',
    },
    timeRangePicker: {
        start: 'Start',
        end: 'End',
    },
    customTimePicker: {
        hour: 'HOUR',
        min: 'MIN',
        clear: 'Clear',
    },
    registration: {
        title: 'Sign Up', 
        name: 'Your Name',
        email: 'Your Email',
        password: 'Password',
        retypePassword: 'Retype Password',
        register: 'Register',
        login: 'Login',
        haveAccount: 'Already have an account?',
    },
    forgotPassword: {
        title: 'Forgot your password?',
        email: 'Your Email',
        recoverPassword: 'Recover Password',
        haveAccount: 'Already have an account?',
        login: 'Login',
        text1: 'We have sent you a link to reset your password by email. To continue, check your Email and follow the instructions in the letter.',
    },
    resetPassword: {
        title: 'Set new password',
        email: 'Your Email',
        password: 'New Password',
        retypePassword: 'Retype New Password',
        setPassword: 'Set password',
        login: 'Login',
        text1: 'The new password has been successfully set! Use the login form to log into your account.',
    },
    auth: {
        signIn: 'Sign in',
        dontHaveAccount: 'Don\'t have an account?',
        signUp: 'Sign Up',
        forgotPassword: 'Forgot Password?',
        login: 'Login',
        loginField: 'Your Email / Username',
        password: 'Password',
    },
    profileMenu: {
        profile: 'Profile',
        logout: 'Logout',
    },
    blocks: {
        avatar: 'Avatar',
        divider: 'Divider',
        text: 'Text',
        back_call: 'Back Call',
        map: 'Map',
        appointment: 'Online Appointment',
        messengers: 'Messengers',
        socials: 'Social Networks',
        gallery: 'Picture Gallery',
    },
    editor: {
        content: 'Content',
        design: 'Design',
        noAvailableSettings: 'There aren\'t settings available',
        avatar: {
            title: 'Title',
            subtitle: 'Subtitle',
            circle: 'Circle',
            square: 'Square',
            shape: 'Avatar shape',
            size: 'Avatar size',
            delete: 'Delete',
            choose: 'Choose',
        },
        text: {
            title: 'Title',
            text: 'Text',
            positionLeft: 'Left',
            positionCenter: 'Center',
            positionRight: 'Right',
            titlePosition: 'Title Position',
        },
        map: {
            size: 'Map size',
            description: 'Click on the map to specify location:',
        },
        appointment: {
            buttonName: 'Button name',
            buttonNamePlaceholder: 'Default: Make an appointment',
            enableServices: 'Enable service selection',
            servicesTooltip: 'Add the list of services you provide in the “<b>Services</b>” section.\n' +
                'Please note, if you enable the “Select Employees” option, you can assign the ' +
                'provided services to employees individually.',
            enableSchedule: 'Enable appointment by working hours',
            scheduleTooltip: 'The working hours setup is located in the “<b>Schedule</b>” section. ' +
                'Please note, if you enable the “Select Employees” option, you can set up the schedule ' +
                'individually for each employee.',
            enableEmployees: 'Enable employees selection',
            selectEmployees: 'Select employees for online appointment:',
            employees: 'Employees',
            forwardDays: 'Days available for booking',
            gapMinutes: 'Minutes of break after each appointment',
            reservationInterval: 'Appointment interval, every:',
            advanceMinutes: 'Booking no later than:',
            oneDay: 'day',
            daysTwoToFour: 'days',
            daysFiveAndMore: 'days',
            min: 'min.',
            hour: '1 hour',
            hours2: '2 hours',
            hours3: '3 hours',
            hours6: '6 hours',
            hours12: '12 hours',
            no_limit: 'No limit',
        },
        messengers: {
            title: 'Title',
            noMessengers: 'No added messengers.',
            username: 'Username',
            whatsapp: {
                title: 'WhatsApp',
                titlePlaceholder: 'Example: Contact by WhatsApp',
                phone: 'Phone',
                phoneLabel: 'Phone number',
                chat: 'Chat',
                chatLabel: 'Link to a chat',
                message: 'Text message',
                messagePlaceholder: 'Example: Hello, I want to make an order.',
            },
            telegram: {
                title: 'Telegram',
                titlePlaceholder: 'Example: Contact by Telegram',
            },
            viber: {
                title: 'Viber',
                titlePlaceholder: 'Example: Contact by Viber',
                phone: 'Phone',
                phoneLabel: 'Phone number',
                channel: 'Channel',
                channelLabel: 'Link to a channel',
            },
            vkontakte: {
                title: 'Vkontakte',
                titlePlaceholder: 'Example: Contact by Vkontakte',
            },
            skype: {
                title: 'Skype',
                titlePlaceholder: 'Example: Contact by Skype',
            },
            facebook: {
                title: 'Facebook',
                titlePlaceholder: 'Example: Contact by Facebook Messenger',
            },
        },
        socials: {
            title: 'Title',
            noSocials: 'No added messengers.',
            username: 'Username',
            youtube: {
                title: 'YouTube',
                titlePlaceholder: 'Example: My Youtube Channel',
                channel: 'Link to your Youtube channel',
            },
            instagram: {
                title: 'Instagram',
                titlePlaceholder: 'Example: My Instagram',
                profile: 'Link to your Instagram profile',
            },
            vkontakte: {
                title: 'VKontakte',
                titlePlaceholder: 'Example: My VKontakte',
                profile: 'Link to your VK profile',
            },
            tiktok: {
                title: 'TikTok',
                titlePlaceholder: 'Example: My TikTok',
                profile: 'Link to your TikTok profile',
            },
        },
        gallery: {
            title: 'Title',
            noData: 'No added images',
            deleteImageConfirmationTitle: 'Confirmation',
            deleteImageConfirmationText: 'Are you sure you want to delete this image?',
        },
        deleteBlockTitle: 'Confirmation',
        deleteBlockText: 'Are you sure you want to delete this block?',
        cancelChangesBlockTitle: 'Confirmation',
        cancelChangesBlockText: 'Are you sure you want to undo the changes?',
        addBlockDialog: {
            title: 'Choose a block type',
        },
        footerSettings: {
            title: 'Footer settings',
            description: 'Add links to social networks:',
            youtubeUrl: 'Youtube url',
            instagramUrl: 'Instagram url',
            tiktokUrl: 'Tiktok url',
            vkUrl: 'Vkontakte url',
        },
    },
    validation: {
        required: 'This field is required',
        email: 'E-mail must be valid',
        emailNoSpaces: 'No spaces are allowed',
        domain: 'Must be a valid domain name',
        url: 'Must be a valid URL address',
        alphaNumericDash: 'Field must only contain Latin letters, numbers, hyphens, and underscores',
        passwordConfirmation: 'The password confirmation does not match.',
        minString: 'Field must be longer than %{num}',
    },
    navigation: {
        statistics: 'Statistics',
        myPages: 'My pages', 
        myDomains: 'My domains',
        settings: 'Settings',
        appointments: 'Online appointment',
        backCalls: 'Back calls',
        schedule: 'Schedule',
        employees: 'Employees',
        services: 'Services',
    },
    statistics: {
        dates: 'Date Range',
        page: 'Page',
        noPages: 'No added pages',
        views: 'Views',
        uniques: 'Uniques',
    },
    pages: {
        published: 'Published',
        drafts: 'Drafts',
        createPage: 'Create',
        imageCreating: 'Preview creating',
        noImage: 'No preview',
        yourPage: 'Your page',
    },
    page: {
        myPages: 'My Pages',
        editMode: 'Edit Mode',
        pageName: 'Page title',
        uniqueName: 'Unique name',
        domain: 'Domain',
        notLinked: 'Not linked',
        saveSettings: 'Save Settings',
        setAsMain: 'Set as the main page',
        next: 'Next',
        back: 'Back',
        createPage: 'Create Page',
        publish: 'Publish',
        unpublish: 'Unpublish',
        published: 'Published',
        unpublishConfirmationTitle: 'Confirmation',
        unpublishConfirmationText: 'Would you like to remove the page from being published?',
        notPublished: 'Not published',
        chooseDesign: 'Choose Design',
        chooseVariant: 'Choose Variant',
        yourLink: 'Your link',
        directory: 'Directory',
        subdomain: 'Subdomain',
        ownDomain: 'Own domain'
    },
    editorMenu: {
        cancel: 'Cancel',
        back: 'Back',
        addBlock: 'Add Block',
        save: 'Save',
    },
    settings: {
        tabProfile: 'Profile',
        tabNotifications: 'Notifications',
        profile: {
            name: 'Your name',
            email: 'Email',
            username: 'Username',
            employeeEmail: 'Email (for notifications)',
            currentPassword: 'Current password',
            newPassword: 'New password',
            retypePassword: 'Retype new password',
            changePassword: 'Change Password',
            cancelEditTitle: 'Confirmation',
            cancelEditText: 'Are you sure you want to undo the changes?',
        },
        notifications: {
            telegramSubtitle: 'Telegram-bot notifications',
            webpushSubtitle: 'Web-browser notifications',
            notSubscribed: 'You are not subscribed',
            subscribed: 'You are subscribed!',
            subscribe: 'Subscribe',
            unsubscribe: 'Unsubscribe',
            unsubscribeTelegram: 'Do you really want to unsubscribe from Telegram bot?',
            unsubscribeWebPush: 'Do you really want to unsubscribe from WebPush notifications?',
            telegramSubscribeTitle: 'How to subscribe?',
            findBot: 'Find the bot in one of the following ways:',
            byScanningQrCode: 'By scanning the QR code:',
            byClickingOnLink: 'By clicking on the link',
            findManually: 'Find bot manually in Telegram search by keyword',
            or: 'or',
            pressStartButton: 'Press the <b>Start</b> button.',
            chooseSubscribe: 'From the menu, select "<b>Subscribe</b>" and submit your subscription code:',
        },
    },
    appointments: {
        title: 'Online Appointments',
        tableHeader: {
            date: 'Date',
            time: 'Time',
            employee: 'Employee',
            name: 'Name/Service',
            page: 'Page',
            created: 'Created',
        },
        deleteEventText: 'Are you sure you want to delete this entry?',
        employee: 'Employee',
    },
    event: {
        addEvent: 'Add entry',
        editEvent: 'Edit entry',
        confirmOverlapsTitle: 'Time Conflict',
        confirmOverlaps: 'Save anyway',
        confirmOverlapsText: 'The event time conflicts with another existing event.',
        date: 'Date',
        time: 'Time',
        employee: 'Employee',
        service: 'Service',
        title: 'Title',
        contactName: 'Contact Name',
        contactPhone: 'Contact Phone',
        notConfirmed: 'Not Confirmed',
        confirm: 'Confirm',
    },
    backCalls: {
        title: 'Your back calls',
        tableHeader: {
            page: 'Page',
            phone: 'Phone',
            created: 'Created',
            actions: 'Actions',
        },
        markAsReviewed: 'Mark as completed',
        deleteBackCallText: 'Are you sure you want to delete this back call request?',
    },
    domains: {
        title: 'My domains',
        add: 'Add',
        deleteDomainText: 'Are you sure you want to delete this domain?',
        domain: 'Domain',
        status: 'Status',
        linked: 'Linked',
        notLinked: 'Not Linked',
        ssl: 'SSL',
        sslYes: 'Yes',
        sslNo: 'No',
        page: 'Main page',
        totalPages: 'Total pages',
        actions: 'Actions',
        addDomain: 'Add Domain',
    },
    schedule: {
        title: 'Schedule',
        workHours: 'Opening hours',
        settingsWorkHours: 'Setting operating hours',
        addEvent: 'Add new entry',
        addDayOff: 'Add a day off',
        addWorkingTime: 'Add a working day',
        addBreak: 'Add a break',
        dayOff: 'Day off',
        workingTime: 'Working time',
        break: 'Break',
        supervisor: 'Supervisor',
        applyToAllEmployees: 'Apply to all employees',
        sharedTimeHint: 'This time is shared among all employees and can only be edited by your supervisor.',
        weekdays: {
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
        },
        deleteConfirmationText: 'Are you sure you want to delete it?',
        workingHours: 'Working hours',
        breakTime: 'Break time',
        useSupervisorSettings: 'Use supervisor settings',
        hoursBreakHint: 'Break time will be applied to each working day:',
    },
    employees: {
        title: 'Employees',
        name: 'Name',
        username: 'Username',
        password: 'Password',
        retypePassword: 'Retype password',
        newPassword: 'New password',
        retypeNewPassword: 'Retype new password',
        type: 'Type',
        services: 'Services',
        actions: 'Actions',
        addEmployee: 'Add Employee',
        editEmployee: 'Edit employee',
        deleteEmployeeText: 'Are you sure you want to delete this employee?',
        reportCredentials: 'Inform the employee of their Username and Password.',
        reportNewCredentials: 'Inform the employee of their new password.',
        chooseServices: 'Choose employee services',
        userType: {
            account: 'Supervisor',
            employee: 'Employee',
        }
    },
    services: {
        title: 'Services',
        chooseEmployees: 'Choose employees',
        deleteServiceText: 'Are you sure you want to delete this service?',
        servicesHint: 'Service without selected specialists applies to all specialists.',
        table: {
            title: 'Title',
            time: 'Time',
            price: 'Price',
            users: 'Employees',
            choose: 'choose',
            allEmployees: 'all employees',
        },
        editDialog: {
            addService: 'Add Service',
            editService: 'Edit Service',
            title: 'Title',
            description: 'Description',
            time: 'Time',
            price: 'Price',
        }
        
    },
};