/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import vuetify from './vuetify';
import store from '../store';
import router from "../router/index.js";
import { abilitiesPlugin } from '@casl/vue';
import ability from './ability';
import api from "@cabinet/api/api.js";
import interceptors from "@cabinet/plugins/interceptors.js";
import i18n from "@cabinet/plugins/i18n.js";
import vueCookies from 'vue-cookies';
import serviceWorker from "@cabinet/plugins/serviceWorker.js";
import * as Sentry from '@sentry/vue';
import 'flag-icons/css/flag-icons.min.css';
import 'v-phone-input/dist/v-phone-input.css';
import { createVPhoneInput } from 'v-phone-input'

export function registerPlugins (app) {
  const vPhoneInput = createVPhoneInput({
    countryIconMode: 'svg',
  });
  
  // Vuetify
  app.use(vuetify);

  // Phone Input
  app.use(vPhoneInput);

  // Vuex
  app.use(store);

  serviceWorker.registerServiceWorker(store);
  
  // Router
  app.use(router);
  
  // CASL abilities
  app.use(abilitiesPlugin, ability, {
    useGlobalProperties: true
  })
  
  // API Interceptors
  interceptors.registerInterceptors(api, router, store);

  // Internationalization
  app.use(i18n);
  
  //Vue Cookies
  app.use(vueCookies);

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
    integrations: [Sentry.browserTracingIntegration({router}), Sentry.replayIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [import.meta.env.VITE_APP_DOMAIN, import.meta.env.VITE_CABINET_API_BASE_URL],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
