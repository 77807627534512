<template>
  <div
      class="d-flex align-center justify-center"
      style="height: 80vh"
  >
    <v-card
        v-if="checkedAuthorization"
        width="400"
        class="mx-auto"
        flat
    >
      <v-card-title class="text-center">
        {{ $t('auth.signIn') }}
      </v-card-title>
      <v-card-text>
        <v-alert
            v-model="errorAlert"
            class="mb-2"
            type="warning"
            density="compact"
            closable
        >
          {{ errorMessage }}
        </v-alert>
        <v-form
            ref="loginForm"
        >
          <v-text-field
              v-model="loginData.login"
              :label="$t('auth.loginField')"
              :rules="rules.loginValidation"
              :error-messages="errors.login"
          />

          <v-text-field
              v-model="loginData.password"
              :label="$t('auth.password')"
              :rules="rules.passwordValidation"
              :error-messages="errors.password"
              type="password"
          />
          <router-link :to="{name: routeNames.FORGOT_PASSWORD}">
            {{ $t('auth.forgotPassword') }}
          </router-link>

          <v-btn
              :loading="authorizing"
              color="primary"
              block class="mt-2"
              @click="login"
          >
            {{ $t('auth.signIn') }}
          </v-btn>
        </v-form>
        <div class="mt-3">
          <p class="text-body-2">{{ $t('auth.dontHaveAccount') }}
            <router-link :to="{name: routeNames.REGISTRATION}">{{ $t('auth.signUp') }}</router-link>
          </p>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { INIT } from '@cabinet/store/actions';
import ROUTE_NAMES from "@cabinet/router/routeNames.js";
import auth from "@cabinet/api/auth.js";
import parseErrorsMixins from "@cabinet/mixins/parseErrorsMixins.js";
import {
  compose,
  ruleRequired
} from "@cabinet/utils/validationRules.js";

export default {
  name: 'Login',
  mixins: [parseErrorsMixins],
  data() {
    return {
      authorizing: false,
      checkedAuthorization: false,
      loginData: {
        login: '',
        password: '',
      },
      routeNames: ROUTE_NAMES,
    };
  },
  computed: {
    rules() {
      return compose({
        loginValidation: [
          ruleRequired(),
        ],
        passwordValidation: [
          ruleRequired(),
        ],
      });
    },
  },
  mounted() {
    let route = null;
    if (this.$store.getters.authorized) {
      if (this.$can('get', 'page_' + ROUTE_NAMES.PAGES)) {
        route = this.$router.resolve({ name: ROUTE_NAMES.PAGES });
      } else if (this.$can('get', 'page_' + ROUTE_NAMES.SCHEDULE)) {
        route = this.$router.resolve({ name: ROUTE_NAMES.SCHEDULE });
      }
    }
    if (route) {
      window.location.replace(route.href);
    } else {
      this.checkedAuthorization = true;
    }
  },
  methods: {
    ...mapActions([INIT]),
    async login() {
      this.hideError();
      const validate = await this.$refs.loginForm.validate()
      if (!validate.valid) {
        return;
      }
      this.authorizing = true;
      try {
        await auth.login(this.loginData);
        this.$store.dispatch(INIT);
        const url = new URL(window.location.href);
        const redirectUrl = url.searchParams.get('redirect');
        if (redirectUrl) {
          window.location.replace(redirectUrl);
        } else {
          window.location.reload();
        }
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.authorizing = false;
      }
    },
  }
}
</script>
