<template>
  <v-card>
    <v-card-title
      class="white--text"
    >
      {{ TitleName }}
    </v-card-title>
    <v-divider />

    <v-card-text class="pt-4 pb-4">
      <v-row class="justify-center align-center" no-gutters>
        <v-row 
            class="align-center justify-center"
            style="max-width: 120px"
            no-gutters
        >
          <v-col cols="5" class="text-center">
            <v-btn
                icon="mdi-chevron-up"
                variant="flat"
                density="comfortable"
                class="mb-1"
                :ripple="false"
                @click="onAddHourClicked"
            />
            <v-text-field
                v-model="hourModel"
                style="width: 50px"
                class="pt-0 my-0 no-underline centered-input"
                type="number"
                hide-spin-buttons
                :min="MinHour"
                :max="MaxHour"
                step="5"
                required
                hide-details
                @wheel="$event.target.blur()"
            />
            <span>{{ LblHour }}</span>
            <v-btn
                icon="mdi-chevron-down"
                variant="flat"
                density="comfortable"
                @click="onSubtractHourClicked"
            />
          </v-col>
          
          <v-col cols="2" class="text-center">
            <div
                style="font-size: 24px;"
            >
              :
            </div>
          </v-col>
          
          <v-col cols="5" class="text-center">
            <v-btn
                icon="mdi-chevron-up"
                variant="flat"
                density="comfortable"
                class="mb-1"
                @click="onAddMinuteClicked"
            />
            <v-text-field
                v-model="minuteModel"
                style="width: 50px"
                class="pt-0 my-0 no-underline centered-input"
                type="number"
                hide-spin-buttons
                :min="minMinute"
                :max="maxMinute"
                step="5"
                required
                hide-details
            ></v-text-field>
            <span>{{ LblMinute }}</span>
            <v-btn
                icon="mdi-chevron-down"
                variant="flat"
                density="comfortable"
                @click="onSubtractMinuteClicked"
            />
          </v-col>
        </v-row>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <v-btn
          v-if="!HideBtnClear"
          variant="flat"
          @click="clearTime()"
      >{{BtnClear}}
      </v-btn>
      <v-btn
          color="primary"
          variant="tonal"
          @click="save"
      >Ok</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: 'CustomTimePicker',
  data() {
    return {
      /* Time Picker */
      hour: 0,
      minute: 0,
      minMinute: 0,
      maxMinute: 59,

      TitleName: null,
      LblHour: null,
      LblMinute: null,
      BtnClear: null,
      BtnNow: null,
      HideBtnClear: false,
      MinHour: null,
      MaxHour: null,
      /* /Time Picker */
    };
  },
  props: [
    'modelValue',
    'titleName',
    'lblHour',
    'lblMinute',
    'btnClear',
    'btnNow',
    'hideBtnClear',
    'hideBtnNow',
    'minHour',
    'maxHour',
  ],
  created() {
    this.TitleName = !this.titleName || this.titleName === '' ? 'Select Time' : this.titleName;
    this.LblHour = !this.lblHour || this.lblHour === '' ? this.$t('customTimePicker.hour') : this.lblHour;
    this.LblMinute = !this.lblMinute || this.lblMinute === '' ? this.$t('customTimePicker.min') : this.lblMinute;
    this.BtnClear = !this.btnClear || this.btnClear === '' ? this.$t('customTimePicker.clear') : this.btnClear;
    this.BtnNow = !this.btnNow || this.btnNow === '' ? 'NOW' : this.btnNow;
    this.HideBtnClear = !this.hideBtnClear ? false : this.hideBtnClear;
    this.MinHour = !this.minHour || this.minHour === '' ? 0 : this.minHour;
    this.MaxHour = !this.maxHour || this.maxHour === '' ? 23 : this.maxHour;
  },

  computed: {
    hourModel: {
      get() {
        return this.forceTwoDigits(this.hour);
      },
      set(v) {
        this.hour = Number(v);
      },
    },
    minuteModel: {
      get() {
        return this.forceTwoDigits(this.minute);
      },
      set(v) {
        this.minute = Number(v);
      },
    },
    fullTimeModel: {
      get() {
        return !this.hour && !this.minute
          ? null
          : `${this.hourModel}:${this.minuteModel}`;
      },
      set(v) {
        let h = 0;
        let m = 0;
        if (v) {
          [h, m] = v.split(':');
        }
        this.hourModel = h;
        this.minuteModel = m;
      },
    },
  },
  mounted() {
    this.fullTimeModel = this.modelValue;
  },

  watch: {
    hour(hour) {
      this.$nextTick(() => {
        if (hour < this.MinHour) this.hour = this.MinHour;
        if (hour > this.MaxHour) this.hour = this.MaxHour;
      });
    },
    minute(minute) {
      this.$nextTick(() => {
        if (minute < this.minMinute) this.minute = this.minMinute;
        if (minute > this.maxMinute) this.minute = this.maxMinute;
      });
    },
  },

  methods: {
    forceTwoDigits(num) {
      return (num < 10 ? '0' : '') + num;
    },
    onAddHourClicked() {
      if (this.hour < this.MaxHour) {
        this.hour += 1;
      } else {
        this.hour = this.MinHour;
      }
    },
    onSubtractHourClicked() {
      if (this.hour > this.MinHour) {
        this.hour -= 1;
      } else {
        this.hour = this.MaxHour;
      }
    },
    onAddMinuteClicked() {
      if (this.minute < this.maxMinute) {
        this.minute += 1;
      } else {
        this.minute = this.minMinute;
      }
    },
    onSubtractMinuteClicked() {
      if (this.minute > this.minMinute) {
        this.minute -= 1;
      } else {
        this.minute = this.maxMinute;
      }
    },
    clearTime() {
      this.hour = 0;
      this.minute = 0;
      this.save();
    },
    save() {
      this.$emit('update:modelValue', this.fullTimeModel);
      this.$emit('close');
    },
  },
};
</script>
<style scoped>
.v-text-field.align-right input {
  text-align: right !important;
}

.v-text-field.align-left input {
  text-align: left !important;
}

.v-text-field.align-center input {
  text-align: center !important;
}

.v-text-field.no-underline .v-input__slot::before {
  display: none !important;
}

.centered-input :deep(input) {
  text-align: center;
}
</style>
