import CommonBlock from "@cabinet/components/editor/blocks/CommonBlock.vue";


export default {
    components: {CommonBlock},
    props: {
        theme: {
            type: Object,
            required: true,
        },
        data: {
            type: Object,
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            themeModel: this.theme,
            dataModel: this.data,
            loadingModel: this.loading,
        };
    },
    watch: {
        themeModel: {
            handler() {
                this.$emit('update:theme', this.themeModel);
            },
            deep: true,
        },
        dataModel: {
            handler() {
                this.$emit('update:data', this.dataModel);
            },
            deep: true,
        },
        loadingModel() {
            this.$emit('update:loading', this.loadingModel);
        },
    },
}