/**
 * Convert time string to number.
 *
 * @param {String} time - Time in format HH:MM
 *
 * @return {Number}
 */
export function convertTimeToNumber(time) {
  const [hours, minutes] = time.split(':').map(Number);
  const timeNumber = hours + (minutes / 60);
  return timeNumber;
}

/**
 * Convert time number to time string\.
 *
 * @param {Number} timeNumber - Time as number
 *
 * @return {Number}
 */
export function convertNumberToTime(timeNumber) {
  // Extract hours and minutes from the number
  const hours = Math.floor(timeNumber);
  const minutes = Math.round((timeNumber - hours) * 60);

  // Format hours and minutes into a time string
  const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

  // Concatenate hours and minutes into a time string
  const timeString = `${formattedHours}:${formattedMinutes}`;

  // Return the formatted time string
  return timeString;
}
