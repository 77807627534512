<template>
  <v-card flat>
    <v-toolbar>
      <v-toolbar-title>
        {{ $t('employees.title') }}
      </v-toolbar-title>
      
      <v-btn
          variant="tonal"
          @click="$refs.editEmployeeDialog.show()"
      >
        <template v-slot:prepend>
          <v-icon>mdi-plus</v-icon>
        </template>
        {{ $t('add') }}
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-data-table
          ref="servicesTable"
          :headers="headers"
          :items="employees"
          :loading="loading"
          :fixed-header="true"
          class="employees-table"
      >
        <template v-slot:item.avatarUrl="{ item }">
          <v-avatar
              :size="36"
              color="info"
          >
            <v-img
                v-if="item.avatarUrl"
                :src="item.avatarUrl"
            />
            <v-icon
                v-else
                :size="36"
            >
              mdi-account-circle
            </v-icon>
          </v-avatar>
        </template>
        <template v-slot:item.type="{ item }">
          {{ typeMap(item.type) }}
        </template>
        
        <template v-slot:item.actions="{ item }">
          <v-btn
              v-if="item.type !== 'account'"
              density="compact"
              variant="flat"
              icon="mdi-delete-outline"
              :loading="item.deleting"
              class="mr-2"
              @click="deleteEmployee(item)"
          />
          <v-btn
              v-if="item.type !== 'account'"
              density="compact"
              variant="flat"
              icon="mdi-pencil-outline"
              @click="editEmployee(item)"
          />
        </template>
      </v-data-table>
    </v-card-text>
    <confirmation ref="confirmation"/>
    <edit-employee-dialog
        ref="editEmployeeDialog"
        @saved="fetchEmployees"
    />
  </v-card>
</template>

<script>
import checkAuthorizedMixins from "@cabinet/mixins/checkAuthorizedMixins.js";
import Confirmation from "@cabinet/components/Confirmation.vue";
import employees from "@cabinet/api/employees.js";
import routeNames from "@cabinet/router/routeNames.js";
import EditEmployeeDialog from "@cabinet/components/employees/EditEmployeeDialog.vue";
export default {
  name: 'Employees',
  components: {EditEmployeeDialog, Confirmation},
  mixins: [
    checkAuthorizedMixins,
  ],
  data() {
    return {
      loading: false,
      employees: [],
      routeNames,
    };
  },
  computed: {
    headers() {
      return [
        {
          title: null,
          align: 'start',
          sortable: false,
          key: 'avatarUrl',
          width: 40,
        },
        {
          title: this.$t('employees.name'),
          align: 'start',
          sortable: false,
          key: 'name',
        },
        {
          title: this.$t('employees.type'),
          align: 'start',
          sortable: false,
          key: 'type',
        },
        {
          title: this.$t('employees.actions'),
          align: 'center',
          sortable: false,
          key: 'actions',
          width: 100,
        },
      ]
    }
  },
  mounted() {
    this.fetchEmployees();
  },
  methods: {
    typeMap(type) {
      return this.$t('employees.userType.' + type);
    },
    
    async fetchEmployees() {
      this.loading = true
      try {
        const employeesList = await employees.getEmployees();
        this.employees = employeesList.map(item => {
          item.deleting = false;
          return item;
        });
      } finally {
        this.loading = false;
      }
    },
    
    editEmployee(employee) {
      this.$refs.editEmployeeDialog.show(employee);
    },

    deleteEmployee(item) {
      this.$refs.confirmation.showConfirm(
          this.$t('confirmation'),
          this.$t('employees.deleteEmployeeText'),
          async() => {
            await employees.deleteEmployee(item.id);
            this.fetchEmployees();
          }
      );
    },
  },
}
</script>
<style>
  .employees-table {
    .choose-service {
      white-space: nowrap;
    }
    
    .v-data-table-header__content {
      font-weight: bold;
    }
    .v-table__wrapper {
      min-height: 200px;
      max-height: calc(100vh - 280px);
      .v-data-table__tr td {
        white-space: nowrap;
      }
    }
  }
</style>
