<template>
  <common-block>
    <template v-slot:common>
    </template>
    <template v-slot:content>
      <v-expansion-panels
          v-if="data.socials && data.socials.length"
          v-model="expanded"
          class="my-4"
          variant="default"
      >
        <draggable
            v-model="data.socials"
            item-key="id"
            :component-data="{name: 'fade'}"
            v-bind="dragOptions"
            style="width: 100%"
        >
          <template #item="{element, index}">
            <v-expansion-panel>
              <template v-slot:title>
                <v-icon class="mr-3 cursor-grab drag-icon">
                  mdi-drag-horizontal
                </v-icon>
                {{ $t('editor.socials.' + element.type + '.title') }}
              </template>
              <template v-slot:text>
                <component
                    v-if="componentExists(element.type)"
                    :data="element"
                    v-bind:is="element.type"
                    :ref="element.type + '_' + index"
                ></component>
                
                <v-btn
                    variant="tonal"
                    color="error"
                    density="compact"
                    @click="deleteItem(index)"
                >
                  {{ $t('delete') }}
                </v-btn>
              </template>
            </v-expansion-panel>
          </template>
        </draggable>
      </v-expansion-panels>
      <div v-else class="text-center">
        {{ $t('editor.socials.noSocials') }}
      </div>
      <div>
        <v-menu>
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" variant="tonal" :disabled="!unusedItems.length">
              <template v-slot:prepend>
                <v-icon>mdi-plus</v-icon>
              </template>
              {{ $t('add') }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item
                v-for="item in unusedItems"
                :key="'add_item_' + item.type"
                @click="addItem(item)"
            >
              <v-list-item-title>{{ $t('editor.socials.' + item.type + '.title') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
    <template v-slot:design>
      
    </template>
  </common-block>
</template>
<script>
import _ from 'lodash';
import blockMixin from "@cabinet/components/editor/blocks/blockMixin.js";
import draggable from "vuedraggable";
import youtube from "@cabinet/components/editor/blocks/Socials_1/youtube.vue";
import instagram from "@cabinet/components/editor/blocks/Socials_1/instagram.vue";
import vkontakte from "@cabinet/components/editor/blocks/Socials_1/vkontakte.vue";
import tiktok from "@cabinet/components/editor/blocks/Socials_1/tiktok.vue";
export default {
  name: 'Socials_1',
  components: {
    draggable,
    youtube,
    instagram,
    vkontakte,
    tiktok,
  },
  mixins: [blockMixin],
  data() {
    return {
      expanded: null,
      socials: [
        {
          type: 'youtube',
          title: null,
          url: null,
        },
        {
          type: 'instagram',
          title: null,
          url: null,
        },
        {
          type: 'vkontakte',
          title: null,
          url: null,
        },
        {
          type: 'tiktok',
          title: null,
          url: null,
        },
      ],
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 350,
        disabled: false,
        handle: '.drag-icon',
      };
    },
    selectedItemsByType() {
      const map = {};
      if (this.data.socials && this.data.socials.length) {
        this.data.socials.forEach(item => {
          map[item.type] = item;
        });
      }
      return map;
    },
    unusedItems() {
      const unused = [];
      this.socials.forEach(item => {
        if (!Object.prototype.hasOwnProperty.call(
            this.selectedItemsByType,
            item.type,
        )) {
          unused.push(item);
        }
      });
      return unused;
    }
  },
  methods: {
    componentExists(componentName) {
      return Object.prototype.hasOwnProperty.call(
          this.$options.components,
          componentName,
      );
    },
    deleteItem(index) {
      this.data.socials.splice(index, 1);
      this.expanded = null;
    },
    addItem(item) {
      const cloneItem = _.cloneDeep(item);
      this.data.socials.push(cloneItem);
    },
  },
}
</script>
<style>

</style>