<template>
  <v-card flat class="pb-2">
    <v-toolbar>
      <v-toolbar-title>
        {{ $t('pages.published') }}
      </v-toolbar-title>
      <v-btn variant="tonal" :to="{name: routeNames.CREATE_PAGE}">
        <template v-slot:prepend>
          <v-icon>mdi-plus</v-icon>
        </template>
        {{ $t('pages.createPage') }}
      </v-btn>
    </v-toolbar>
    <div class="cards-container mt-4">
      <template
          v-for="(page, index) in published"
          :key="'published_' + index"
      >
        <page-item
            :page="page"
            @click="$router.push({name: routeNames.PAGE, params: {uuid: page.uuid}})"
        />
      </template>
      <template v-if="!published.length">
        <add-page-item @click="$router.push({name: routeNames.CREATE_PAGE})" />
      </template>
    </div>
    <template v-if="drafts.length">
      <v-toolbar>
        <v-toolbar-title>
          {{ $t('pages.drafts') }}
        </v-toolbar-title>
      </v-toolbar>
      <div class="cards-container mt-4">
        <template
            v-for="(page, index) in drafts"
            :key="'draft_' + index"
        >
          <page-item
              :page="page"
              @click="$router.push({name: routeNames.PAGE, params: {uuid: page.uuid}})"
          />
        </template>
      </div>
    </template>
    
  </v-card>
</template>

<script>
import pages from "@cabinet/api/pages.js";
import checkAuthorizedMixins from "@cabinet/mixins/checkAuthorizedMixins.js";
import ROUTE_NAMES from "@cabinet/router/routeNames.js";
import PageItem from "@cabinet/components/pages/PageItem.vue";
import {eventBus, events} from "@cabinet/utils/eventBus.js";
import AddPageItem from "@cabinet/components/pages/AddPageItem.vue";
export default {
  name: 'Pages',
  components: {AddPageItem, PageItem},
  mixins: [
      checkAuthorizedMixins,
  ],
  data() {
    return {
      routeNames: ROUTE_NAMES,
      loading: false,
      pages: [],
    };
  },
  computed: {
    published() {
      return this.pages.filter(page => {
        return page.published;
      });
    },
    drafts() {
      return this.pages.filter(page => {
        return !page.published;
      });
    },
    pagesUuidMap() {
      const map = {};
      this.pages.forEach((item, index) => {
        map[item.uuid] = index;
      });
      return map;
    },
  },
  mounted() {
    eventBus.$on(events.PAGE_IMAGE_RENDERED, this.pageImageRendered);
    this.fetchData();
  },
  beforeUnmount() {
    eventBus.$off(events.PAGE_IMAGE_RENDERED, this.pageImageRendered);
  },
  methods: {
    pageImageRendered(payload) {
      if (Object.prototype.hasOwnProperty.call(this.pagesUuidMap, payload.uuid)) {
        const index = this.pagesUuidMap[payload.uuid];
        this.pages[index].image_url = payload.image_url;
        this.pages[index].is_rendering = false;
      }
    },
    async fetchData() {
      this.loading = true;
      try {
        const response = await pages.getPages();
        this.pages = response.data;
      } finally {
        this.loading = false;
      }
    }
  },
}
</script>
<style>
.cards-container {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
</style>
