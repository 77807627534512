/* eslint func-names:0 */
export default {
    data() {
        return {
            errorAlert: false,
            errorMessage: null,
            errors: {},
        };
    },
    methods: {
        hideError() {
            this.errorAlert = false;
            this.errorMessage = null;
            this.errors = {};
        },
        showError(message) {
            this.errorMessage = message;
            this.errorAlert = true;
        },
        parseErrorResponse(response, showMessage = false) {
            if ((response.status === 400 || response.status === 422) && response.data.errors !== undefined) {
                this.errors = response.data.errors;
                
                if (showMessage) {
                    this.showError(response.data.message);
                }
            } else if (response.data && response.data.message) {
                this.showError(response.data.message);
            } else {
                this.showError('Unexpected server error');
            }
        },
    },
};
