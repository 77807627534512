/**
 * app.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */
import { registerPlugins } from '@cabinet/plugins';
import App from './App.vue';
import { createApp } from 'vue';

const app = createApp(App);

registerPlugins(app);

app.mount('#app');