import api from './api';

export default {
    /**
     * Get list of business hours.
     *
     * @return Promise<Object>
     */
    getBusinessHours() {
        return api
            .get('/api/business-hours')
            .then(response => response.data);
    },

    /**
     * Get list of business hours for user by Id.
     *
     * @return Promise<Object>
     */
    getUserBusinessHours(userId) {
        return api
            .get('/api/business-hours/user/' + userId)
            .then(response => response.data);
    },

    /**
     * Get business hours break for specific user.
     *
     * @return Promise<Object>
     */
    getUserBusinessHoursBreak(userId) {
        return api
            .get('/api/business-hours/user/' + userId + '/break')
            .then(response => response.data);
    },

    /**
     * Get business hours break
     *
     * @return Promise<Object>
     */
    getBusinessHoursBreak() {
        return api
            .get('/api/business-hours/break')
            .then(response => response.data);
    },

    /**
     * Save list of business hours.
     *
     * @return Promise<Object>
     */
    saveBusinessHours(userId, data) {
        return api
            .post('/api/business-hours/user/' + userId, data)
            .then(response => response.data);
    },

    /**
     * Get list of special hours.
     *
     * @return Promise<Object>
     */
    getSpecialDays() {
        return api
            .get('/api/business-hours/special-days')
            .then(response => response.data);
    },

    /**
     * Delete special day.
     *
     * @param {Number} id
     *
     * @return Promise<Object>
     */
    deleteSpecialDay(id) {
        return api
            .delete('/api/business-hours/special-days/' + id)
            .then(response => response.data);
    },

    /**
     * Update special day.
     *
     * @param {Number} userId
     * @param {Number} id
     * @param {Object} data
     *
     * @return Promise<Object>
     */
    updateSpecialDay(userId, id, data) {
        return api
            .put('/api/business-hours/user/' + userId + '/special-days/' + id, data)
            .then(response => response.data);
    },

    /**
     * Create special day.
     *
     * @param {Number} userId
     * @param {Object} data
     *
     * @return Promise<Object>
     */
    createSpecialDay(userId, data) {
        return api
            .post('/api/business-hours/user/' + userId + '/special-days', data)
            .then(response => response.data);
    },

    /**
     * Get list of events.
     *
     * @return Promise<Object>
     */
    getEvents() {
        return api
            .get('/api/business-hours/events')
            .then(response => response.data);
    },
}