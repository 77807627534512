import {SET_SERVICE_WORKER_REGISTRATION} from "@cabinet/store/mutations.js";

export default {
    registerServiceWorker(store) {
        if ('serviceWorker' in navigator) {
            window.addEventListener('load', () => {
                navigator.serviceWorker.register('/service-worker.js')
                    .then(reg => {
                        store.commit(SET_SERVICE_WORKER_REGISTRATION, reg);
                    })
                    .catch(err => {
                        store.commit(SET_SERVICE_WORKER_REGISTRATION, null);
                    });
            });
        } else {
            store.commit(SET_SERVICE_WORKER_REGISTRATION, null);
        }
    }
}