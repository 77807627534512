<template>
  <div>
    <v-alert
        v-model="errorAlert"
        class="mb-2"
        type="warning"
        density="compact"
        closable
    >
      {{ errorMessage }}
    </v-alert>
    <v-form
        ref="pageSettingsForm"
        @submit.prevent="savePageSettings"
    >
      <v-text-field
          v-model="pageModel.name"
          :label="$t('page.pageName')"
          :maxlength="255"
          :rules="rules.nameValidation"
          :error-messages="errors.name"
      />

      <v-select
          v-model="pageModel.design_id"
          :items="designs"
          item-title="name"
          item-value="id"
          :disabled="!designs.length"
          :label="$t('page.chooseDesign')"
          :loading="loadingDesigns"
          @update:model-value="changeDesignHandler"
      />

      <v-select
          v-model="pageModel.design_variant_id"
          :items="variants"
          :disabled="!variants.length"
          item-title="name"
          item-value="id"
          :label="$t('page.chooseVariant')"
          :loading="loadingVariants"
      />
    </v-form>
  </div>
</template>
<script>
import _ from 'lodash';
import parseErrorsMixins from "@cabinet/mixins/parseErrorsMixins.js";
import {compose, ruleRequired} from "@cabinet/utils/validationRules.js";
import pages from "@cabinet/api/pages.js";
import designs from "@cabinet/api/designs.js";
export default {
  name: 'PageForm',
  mixins: [parseErrorsMixins],
  props: {
    page: {
      type: Object,
      required: true,
    },
    saving: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loadingDesigns: true,
      loadingVariants: false,
      savingModel: this.saving,
      pageModel: this.page,
      designs: [],
      variants: [],
    };
  },
  computed: {
    rules() {
      
      return compose({
        nameValidation: [
          ruleRequired(),
        ],
      });
    },
  },
  mounted() {
    this.fetchDesigns();
  },
  watch: {
    savingModel() {
      this.$emit('update:saving', this.savingModel);
    },
    pageModel: {
      handler() {
        this.$emit('update:page', this.pageModel);
      },
      deep: true,
    },
  },

  methods: {
    async fetchDesigns() {
      try {
        this.loadingDesigns = true;
        const response = await designs.getDesigns();
        this.designs = response.data;
        if (!this.pageModel.design_id) {
          this.pageModel.design_id = this.designs[0].id;
        }
        await this.fetchDesignVariants();
      } finally {
        this.loadingDesigns = false;
      }
    },
    async fetchDesignVariants() {
      try {
        this.loadingVariants = true;
        const response = await designs.getVariants(this.pageModel.design_id);
        this.variants = response.data;
        if (!this.pageModel.design_variant_id) {
          this.pageModel.design_variant_id = this.variants[0].id;
        }
      } finally {
        this.loadingVariants = false;
      }
    },
    changeDesignHandler() {
      this.pageModel.design_variant_id = null;
      this.fetchDesignVariants();
    },
    
    async savePageSettings() {
      this.hideError();
      const validate = await this.$refs.pageSettingsForm.validate()
      if (!validate.valid) {
        return;
      }
      this.savingModel = true;
      try {
        const submitData = _.cloneDeep(this.pageModel);
        if (this.pageModel.uuid) {
          await pages.savePage(this.page.uuid, submitData);
          this.$emit('saved', this.pageModel);
        } else {
          const response = await pages.createPage(submitData);
          const page = response.data;
          this.$emit('saved', page);
        }
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.savingModel = false;
      }
    },
  }
};

</script>