import api from './api';
import { responseHandler } from './api';

export default {
    /**
     * Get Back Calls.
     *
     * @return Promise<Object>
     */
    getBackCalls(params) {
        return api
            .get('/api/back-calls', {
                params
            })
            .then(response => response.data);
    },
    
    /**
     * Mark as reviewed
     *
     * @return Promise<Object>
     */
    markAsReviewed(id) {
        return api
            .post('/api/back-calls/' + id + '/mark-as-reviewed')
            .then(response => response.data);
    },

    /**
     * Delete the back call.
     *
     * @return Promise<Object>
     */
    delete(id) {
        return api
            .delete('/api/back-calls/' + id)
            .then(response => response.data);
    },
}