<template>
  <v-list-item @click="clickHandler">
    <v-list-item-title :class="[!readAt ? 'font-weight-bold' : '']">{{ data.title }}</v-list-item-title>

    <v-list-item-subtitle>
      {{ data.subtitle }}
    </v-list-item-subtitle>
    <v-list-item-subtitle class="mt-1">
      <i>{{ createdAtFormatted }}</i>
    </v-list-item-subtitle>

    <template v-slot:append>
      <v-btn
          :loading="deleting"
          density="compact"
          variant="tonal"
          icon="mdi-delete-outline"
          @click.stop="deleteNotification"
      />
    </template>
  </v-list-item>
</template>
<script setup>
</script>
<script>
import ROUTE_NAMES from "@cabinet/router/routeNames.js";
import moment from 'moment';
export default {
  name: 'NewAppointment',
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    readAt: {
      type: String,
      required: false,
    },
    createdAt: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      deleting: false,
    }
  },
  computed: {
    createdAtFormatted() {
      return moment(this.createdAt).format(this.$t('datetimeFormat'));
    },
  },
  methods: {
    clickHandler() {
      if (this.deleting) {
        return;
      }
      this.$router.push({ name: ROUTE_NAMES.APPOINTMENTS });
      this.$emit('click', this.id);
    },
    deleteNotification() {
      this.deleting = true;
      this.$emit('delete', this.id);
    }
  },
}
</script>