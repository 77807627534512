<template>
  <v-card flat>
    <v-toolbar>
      <v-toolbar-title>
        {{ $t('domains.title') }}
      </v-toolbar-title>
      
      <v-btn
          variant="tonal"
          @click="$refs.addDomainDialog.show()"
      >
        <template v-slot:prepend>
          <v-icon>mdi-plus</v-icon>
        </template>
        {{ $t('domains.add') }}
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-data-table
          ref="domainsTable"
          :headers="headers"
          :items="domains"
          :loading="loading"
          :fixed-header="true"
          class="domains-table"
      >
        <template v-slot:item.status="{ item }">
          <span v-if="item.resolved" class="linked-domain">{{ $t('domains.linked') }}</span>
          <span v-else class="not-linked-domain">{{ $t('domains.notLinked') }}</span>
        </template>
        <template v-slot:item.ssl="{ item }">
          <span v-if="item.ssl">{{ $t('domains.sslYes') }}</span>
          <span v-else>{{ $t('domains.sslNo') }}</span>
        </template>
        <template v-slot:item.main_page_name="{ item }">
          <router-link
              v-if="item.main_page_uuid"
              :to="{name: routeNames.PAGE, params: {uuid: item.main_page_uuid}}"
          >
            {{ item.main_page_name }}
          </router-link>
          <span v-else>—</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
              density="compact"
              variant="tonal"
              icon="mdi-delete-outline"
              :loading="item.deleting"
              @click="deleteDomain(item)"
          />
        </template>
      </v-data-table>
    </v-card-text>
    <confirmation ref="confirmation"/>
    <add-domain-dialog
        ref="addDomainDialog"
        @created="fetchDomains"
    />
  </v-card>
</template>

<script>
import checkAuthorizedMixins from "@cabinet/mixins/checkAuthorizedMixins.js";
import Confirmation from "@cabinet/components/Confirmation.vue";
import domains from "@cabinet/api/domains.js";
import routeNames from "@cabinet/router/routeNames.js";
import AddDomainDialog from "@cabinet/components/domains/AddDomainDialog.vue";
export default {
  name: 'Domains',
  components: {AddDomainDialog, Confirmation},
  mixins: [
    checkAuthorizedMixins,
  ],
  data() {
    return {
      loading: false,
      domains: [],
      routeNames,
    };
  },
  computed: {
    headers() {
      return [
        {
          title: this.$t('domains.domain'),
          align: 'start',
          sortable: false,
          key: 'domain',
        },
        {
          title: this.$t('domains.status'),
          align: 'start',
          sortable: false,
          key: 'status',
        },
        {
          title: this.$t('domains.ssl'),
          align: 'start',
          sortable: false,
          key: 'ssl',
        },
        {
          title: this.$t('domains.page'),
          align: 'start',
          sortable: false,
          key: 'main_page_name',
        },
        {
          title: this.$t('domains.totalPages'),
          align: 'start',
          sortable: false,
          key: 'total_pages',
        },
        {
          title: this.$t('domains.actions'),
          align: 'center',
          sortable: false,
          key: 'actions',
        },
      ]
    }
  },
  mounted() {
    this.fetchDomains();
  },
  methods: {
    async fetchDomains() {
      this.loading = true
      try {
        const domainsList = await domains.getDomains();
        this.domains = domainsList.map(item => {
          item.deleting = false;
          return item;
        });
      } finally {
        this.loading = false;
      }
    },
    
    deleteDomain(item) {
      this.$refs.confirmation.showConfirm(
          this.$t('confirmation'),
          this.$t('domains.deleteDomainText'),
          async() => {
            await domains.delete(item.id);
            this.fetchDomains();
          }
      );
    }
  },
}
</script>
<style>
  .domains-table {
    .v-data-table-header__content {
      font-weight: bold;
    }
    .v-table__wrapper {
      min-height: 200px;
      max-height: calc(100vh - 280px);
      .v-data-table__tr td {
        white-space: nowrap;
      }
    }
    
    .linked-domain {
      color: green;
    }
    .not-linked-domain {
      color: red;
    }
  }
</style>
