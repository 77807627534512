<template>
  <v-row no-gutters>
    <v-col cols="6">
      <v-select
          v-model="timeStartValue"
          :items="timeStartItems"
          :return-object="false"
          :rules="rules"
          :label="$t('timeRangePicker.start')"
          clearable
          variant="outlined"
          style="min-width: 150px"
          class="mr-1"
      />
    </v-col>
    <v-col cols="6">
      <v-select
          v-model="timeEndValue"
          :items="timeEndItems"
          :return-object="false"
          :rules="rules"
          :label="$t('timeRangePicker.end')"
          clearable
          variant="outlined"
          style="min-width: 150px"
          class="ml-1"
      />
    </v-col>
  </v-row>
</template>

<script>
import { timeToMinutes, minutesToTime } from '@cabinet/utils/utils.js'
export default {
  name: 'TimeRangePicker',
  props: {
    timeStart: {
      type: String,
      required: false,
      default: null,
    },
    timeEnd: {
      type: String,
      required: false,
      default: null,
    },
    rules: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      timeStartValue: this.timeStart,
      timeEndValue: this.timeEnd,
      step: 30,
    };
  },
  computed: {
    timeStartItems() {
      const items = [];
      for (let i = 0; i <= 47; i++) {
        const minutes = this.step * i;
        const time = minutes === 1440 ? '23:59' : this.minutesToTime(minutes);
        items.push({
          title: time,
          value: time,
        });
      }
      return items;
    },
    timeEndItems() {
      const items = [];
      let startValue = this.timeStartValue ? this.timeStartValue : null;
      if (startValue === '23:59') {
        startValue = '24:00';
      }
      const start = startValue ? this.timeToMinutes(startValue) : null;
      for (let i = 1; i <= 48; i++) {
        const minutes = this.step * i;
        if (start !== null && minutes <= start) {
          continue;
        }
        const time = minutes === 1440 ? '23:59' : this.minutesToTime(minutes);
        items.push({
          title: time,
          value: time,
        });
      }
      return items;
    },
  },
  watch: {
    timeStartValue() {
      const start = this.timeStartValue ? this.timeToMinutes(this.timeStartValue) : null;
      const end = this.timeEndValue ? this.timeToMinutes(this.timeEndValue) : null;
      if (this.timeStartValue && this.timeEndValue && start >= end) {
        this.timeEndValue = null;
      }
      this.$emit('update:timeStart', this.timeStartValue);
    },
    timeEndValue() {
      this.$emit('update:timeEnd', this.timeEndValue);
    }
  },
  methods: {
    minutesToTime(minutes) {
      return minutesToTime(minutes);
    },
    timeToMinutes(time) {
      return timeToMinutes(time);
    }
  },
}
</script>
