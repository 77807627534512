<template>
  <v-dialog
      v-model="dialog"
      max-width="500"
      class="add-employee-dialog"
      persistent
  >
    <v-card min-height="350">
      <v-card-title>{{ isNew ? $t('employees.addEmployee') : $t('employees.editEmployee') }}</v-card-title>
      <v-divider />
      <v-card-text>
        <avatar
            v-model="avatar"
            :image-size="150"
            :size="100"
        />

        <v-alert
            v-if="isNew"
            type="info"
            class="mb-2"
            variant="tonal"
        >
          {{ $t('employees.reportCredentials') }}
        </v-alert>
        
        <v-alert
            v-model="errorAlert"
            class="mb-2"
            type="warning"
            density="compact"
            closable
        >
          {{ errorMessage }}
        </v-alert>
        
        <v-form
            ref="saveEmployeeForm"
            @submit.prevent="save"
        >
          <v-text-field
              v-model="submitData.name"
              :label="$t('employees.name')"
              :maxlength="255"
              :rules="rules.nameValidation"
              :error-messages="errors.name"
          />
          <v-text-field
              v-model="submitData.username"
              :label="$t('employees.username')"
              :maxlength="255"
              :rules="rules.nameValidation"
              :error-messages="errors.username"
          />
          
          <template v-if="isNew">
            <v-text-field
                v-model="submitData.newPassword"
                :label="$t('employees.password')"
                :rules="rules.passwordValidation"
                :error-messages="errors.newPassword"
                type="password"
                :maxlength="40"
            />
            <v-text-field
                v-model="retypePassword"
                :label="$t('employees.retypePassword')"
                :rules="rules.retypePasswordValidation"
                type="password"
                :maxlength="40"
            />
          </template>
          

          <v-expand-transition v-if="!isNew">
            <div v-if="showSetNewPassword">
              <v-alert
                  type="info"
                  class="mb-2"
                  variant="tonal"
              >
                {{ $t('employees.reportNewCredentials') }}
              </v-alert>
              
              <v-text-field
                  v-model="submitData.newPassword"
                  :label="$t('employees.newPassword')"
                  :rules="rules.passwordValidation"
                  :error-messages="errors.newPassword"
                  type="password"
                  :maxlength="40"
              />
              <v-text-field
                  v-model="retypePassword"
                  :label="$t('employees.retypeNewPassword')"
                  :rules="rules.retypePasswordValidation"
                  type="password"
                  :maxlength="40"
              />
            </div>
          </v-expand-transition>

          <v-btn v-if="!isNew" variant="tonal" density="compact" @click="showHideChangePassword">
            <template v-slot:prepend>
              <v-icon v-if="showSetNewPassword">mdi-chevron-up</v-icon>
              <v-icon v-if="!showSetNewPassword">mdi-chevron-down</v-icon>
            </template>
            {{ $t('settings.profile.changePassword') }}
          </v-btn>
          
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
            @click="dialog = false;"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
            :loading="submitting"
            variant="tonal"
            @click="save"
        >
          {{ isNew ? $t('add') : $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import parseErrorsMixins from "@cabinet/mixins/parseErrorsMixins.js";
import employeesApi from "@cabinet/api/employees.js";
import {compose, ruleRequired, ruleMin} from "@cabinet/utils/validationRules.js";
import DialogLoading from "@cabinet/components/DialogLoading.vue";
import Avatar from "@cabinet/components/Avatar.vue";
export default {
  name: 'EditEmployeeDialog',
  components: {Avatar, DialogLoading},
  mixins: [parseErrorsMixins],
  data() {
    return {
      dialog: false,
      isNew: true,
      retypePassword: null,
      showSetNewPassword: false,
      loading: false,
      employeeId: null,
      submitData: {
        name: null,
        username: null,
        newPassword: null,
      },
      avatar: {
        avatarUrl: null,
      },
      submitting: false,
    };
  },
  computed: {
    rules() {
      const passwordValidation = [];
      const retypePasswordValidation = [];
      if (this.isNew || this.showSetNewPassword) {
        passwordValidation.push(ruleRequired());
        passwordValidation.push(ruleMin('string', 6));
        retypePasswordValidation.push(ruleRequired());
        retypePasswordValidation.push(
            (v) => v === this.submitData.newPassword || this.$t('validation.passwordConfirmation')
        );
      }
      
      return compose({
        nameValidation: [
          ruleRequired(),
        ],
        passwordValidation,
        retypePasswordValidation,
      });
    },
  },
  methods: {
    async show(employee = null) {
      this.dialog = true;
      this.errors = {};
      const submitData = {
        name: null,
        username: null,
        newPassword: null,
        avatarUrl: null,
      };
      this.retypePassword = null;
      const avatar = {
        avatarUrl: null,
      };
      this.employeeId = null;
      if (employee) {
        this.isNew = false;
        submitData.name = employee.name;
        submitData.username = employee.username;
        this.employeeId = employee.id;
        avatar.avatarUrl = employee.avatarUrl;
      } else {
        this.isNew = true;
      }
      this.submitData = submitData;
      this.avatar = avatar;
    },
    hide() {
      this.dialog = false;
    },
    async save() {
      this.hideError();
      const validate = await this.$refs.saveEmployeeForm.validate()
      if (!validate.valid) {
        return;
      }
      try {
        this.submitting = true;
        const submitData = _.cloneDeep(this.submitData);
        submitData.avatarUrl = this.avatar.avatarUrl;
        if (this.avatar.isAvatarTempUrl) {
          submitData.isAvatarTempUrl = true;
        }
        if (this.isNew) {
          await employeesApi.createEmployee(submitData);
        } else {
          await employeesApi.saveEmployee(this.employeeId, submitData);
        }
        this.$emit('saved');
        this.dialog = false;
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.submitting = false;
      }
    },

    showHideChangePassword() {
      if (this.showSetNewPassword) {
        this.retypePassword = null;
        this.submitData.newPassword = null;
      }
      this.showSetNewPassword = !this.showSetNewPassword;
    },
  }
}
</script>
<style>
.add-employee-dialog {

}
</style>