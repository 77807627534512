export const SET_USER = 'SET_USER';
export const SET_TITLE = 'SET_TITLE';
export const SET_INITIALIZING = 'SET_INITIALIZING';

export const SET_SERVICE_WORKER_REGISTRATION = 'SET_SERVICE_WORKER_REGISTRATION';

export default {
    [SET_USER]: (state, user) => {
        state.user = user;
    },

    [SET_TITLE]: (state, title) => {
        state.title = title;
        document.title = title;
    },

    [SET_INITIALIZING]: (state, value) => {
        state.initializing = value;
    },

    [SET_SERVICE_WORKER_REGISTRATION]: (state, value) => {
        state.serviceWorkerRegistration = value;
    },
}