<template>
  <v-dialog
      v-model="dialog"
      max-width="500"
      class="view-event-dialog"
  >
    <dialog-loading v-if="loading"/>
    <v-card v-else-if="event">
      <v-toolbar>
        <v-toolbar-title>{{ event.service_title || event.name }}</v-toolbar-title>
        <v-btn icon="mdi-close" @click="this.dialog = false"/>
      </v-toolbar>
      <v-divider />
      <v-card-text style="max-height: calc(100vh - 150px); overflow-y: scroll" class="text-center">
        <v-alert
            v-model="errorAlert"
            class="mb-5"
            type="warning"
            density="compact"
            closable
        >
          {{ errorMessage }}
        </v-alert>
        <v-row>
          <v-col v-if="event.date" cols="12">
            <div class="event-title">{{ $t('event.date') }}:</div>
            <div>{{ formatDate(event.date) }}</div>
          </v-col>
          <v-col
              v-if="event.time_from && event.time_to"
              cols="12"
          >
            <div class="event-title">{{ $t('event.time') }}:</div>
            <div>{{ formatTime(event.time_from) }} - {{ formatTime(event.time_to) }}</div>
            
          </v-col>
          <v-col v-if="isSupervisor && event.employee_id" cols="12">
            <div class="event-title">{{ $t('event.employee') }}:</div>
            <div>{{ event.employee_name }}</div>
          </v-col>
          <v-col v-if="event.service_id" cols="12">
            <div class="event-title">{{ $t('event.service') }}:</div>
            <div>{{ event.service_title }}</div>
          </v-col>
          <v-col v-if="event.contact_name" cols="12">
            <div class="event-title">{{ $t('event.contactName') }}:</div>
            <div>{{ event.contact_name }}</div>
          </v-col>
          <v-col v-if="event.contact_phone" cols="12">
            <div class="event-title">{{ $t('event.contactPhone') }}:</div>
            <div>{{ event.contact_phone }}</div>
          </v-col>
          <v-col v-if="event.type === 'appointment' && !event.confirmed" cols="12">
            <div class="event-title text-red">{{ $t('event.notConfirmed') }}:</div>
            <v-btn
                :loading="confirming"
                variant="tonal"
                color="green"
                @click="confirmAppointment"
            >
              {{ $t('event.confirm') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />
      <v-card-actions>
        <v-btn
            v-if="event.id"
            color="red-btn"
            variant="tonal"
            @click="clickDeleteHandler"
        >
          {{ $t('delete') }}
        </v-btn>
        <v-spacer />

        <v-btn
            prepend-icon="mdi-pencil-outline"
            color="primary-btn"
            variant="tonal"
            theme="dark"
            @click="clickEditHandler"
        >
          {{ $t('edit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <confirmation ref="confirmation" />
  </v-dialog>
</template>

<script>
import moment from "moment";
import parseErrorsMixins from "@cabinet/mixins/parseErrorsMixins.js";
import TimeRangePicker from "@cabinet/components/TimeRangePicker.vue";
import eventsApi from "@cabinet/api/events.js";
import DialogLoading from "@cabinet/components/DialogLoading.vue";
import DatePicker from "@cabinet/components/DatePicker.vue";
import ConfirmationOverlaps from "@cabinet/components/events/ConfirmationOverlaps.vue";
import Confirmation from "@cabinet/components/Confirmation.vue";

export default {
  name: 'ViewEventDialog',
  mixins: [parseErrorsMixins],
  components: {
    Confirmation,
    ConfirmationOverlaps,
    DatePicker,
    DialogLoading,
    TimeRangePicker
  },
  props: {
    isSupervisor: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: true,
      confirming: false,
      event: null,
      services: [],
      employees: [],
    };
  },
  computed: {
    servicesById() {
      const map = {};
      this.services.forEach(item => {
        map[item.id] = item.title;
      });
      return map;
    },
  },
  methods: {
    formatDate(dateTime) {
      return moment.parseZone(dateTime)
          .format(this.$t('dateFormat'));
    },
    formatTime(time) {
      return moment(time, "HH:mm:ss").format(this.$t('shortTimeFormat'));
    },
    async show(eventId) {
      this.loading = true;
      this.dialog = true;
      this.event = null;
      await this.loadData(eventId);
    },
    async loadData(eventId) {
      this.loading = true;
      const promises = [];
      promises.push(this.loadEvent(eventId));
      try {
        await Promise.all(promises);
      } finally {
        this.loading = false;
      }
    },
    async loadEvent(eventId) {
      const eventData = await eventsApi.getEvent(eventId);
      this.event = eventData.data 
    },
    close() {
      this.dialog = false;
    },
    clickEditHandler() {
      this.close();
      this.$emit('clickEdit', this.event.id);
    },
    clickDeleteHandler() {
      this.$refs.confirmation.showConfirm(
          this.$t('confirmation'),
          this.$t('appointments.deleteEventText'),
          async() => {
            await eventsApi.delete(this.event.id);
            this.$emit('deleted');
            this.close();
          }
      );
    },
    async confirmAppointment() {
      this.confirming = true;
      try {
        await eventsApi.confirmEvent(this.event.id);
        this.$emit('confirmed');
        this.event.confirmed = true;
      } finally {
        this.confirming = false;
      }
    },
  }
}
</script>
<style>
.view-event-dialog {
  .event-title {
    font-weight: bold;
  }
}
</style>