/* eslint func-names:0 */
import domains from "@cabinet/api/domains.js";

export default {
    data() {
        return {
            domains: [],
            domainsLoading: false,
            makeAsMain: false,
        };
    },
    computed: {
        domainIdIndexMap() {
            const map = {};
            this.domains.forEach((domain, index) => {
                map[domain.id] = index;
            });
            return map;
        }, 
    },
    mounted() {
        this.loadDomains();
    },
    methods: {
        getBaseUrl(domainId) {
            const isCustomDomain = !!domainId;
            if (isCustomDomain) {
                if (!this.domains.length) {
                    return null;
                }
                const domainIndex = this.domainIdIndexMap[domainId];
                return (this.domains[domainIndex].ssl ? 'https' : 'http') + '://' + this.domains[domainIndex].domain
            } else {
                return import.meta.env.VITE_SITE_MAIN_URL;
            }
        },

        getPageUrl(linkType, account, domainId) {
            const isCustomDomain = !!domainId;
            const pageBaseUrl = this.getBaseUrl(domainId);
            if (!pageBaseUrl) {
                return null;
            }
            if (isCustomDomain && this.makeAsMain) {
                return pageBaseUrl;
            } else {
                return linkType === 'path'
                    ? pageBaseUrl + '/' + account
                    : 'https://' + account + '.' + import.meta.env.VITE_APP_DOMAIN;
            }
        },

        async loadDomains() {
            try {
                this.domainsLoading = true;
                this.domains = await domains.getDomains();
                let makeAsMain = false;
                this.domains.forEach(domain => {
                    if (domain.main_page_uuid === this.uuid) {
                        makeAsMain = true;
                    }
                });
                this.makeAsMain = makeAsMain;
            } finally {
                this.domainsLoading = false;
            }
        },
    },
};
