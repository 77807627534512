import {en, ru} from 'vuetify/locale'
export default {
    $vuetify: {
        ...ru
    },
    10: '10',
    25: '25',
    50: '50',
    100: '100',
    titles: {
        editor: 'Редактор',
    },
    loadingYourPage: 'Загрузка страницы...',
    datetimeFormat: 'YYYY.MM.DD HH:mm',
    dateFormat: 'YYYY.MM.DD',
    shortTimeFormat: 'HH:mm',
    date: 'Дата',
    cancel: 'Отмена',
    apply: 'Применить',
    save: 'Сохранить',
    delete: 'Удалить',
    done: 'Готово',
    create: 'Создать',
    add: 'Добавить',
    edit: 'Редактировать',
    close: 'Закрыть',
    clear: 'Очистить',
    loading: 'Загрузка',
    confirm: 'Подтвердить',
    confirmation: 'Подтверждение',
    loadMore: 'Загрузить еще',
    choose: 'Выбрать',
    dateRangePicker: {
        dayOfWeek: {
            sun: 'Вс',
            mon: 'Пн',
            tue: 'Вт',
            wed: 'Ср',
            thu: 'Чт',
            fri: 'Пт',
            sat: 'Сб',
        },
        monthNames: {
            jan: 'Январь',
            feb: 'Февраль',
            mar: 'Март',
            apr: 'Апрель',
            may: 'Май',
            jun: 'Июнь',
            jul: 'Июль',
            aug: 'Август',
            sep: 'Сентябрь',
            oct: 'Октябрь',
            nov: 'Ноябрь',
            dec: 'Декабрь',
        },
        ranges: {
            today: 'Сегодня',
            yesterday: 'Вчера',
            lastSevenDays: 'Последние 7 дней',
            monthToDate: 'Этот месяц',
            previousMonth: 'Прошлый месяц',
        },
    },
    notifications: {
        noNotifications: 'Нет уведомлений',
    },
    phoneInput: {
        country: 'Страна',
        invalidPhoneMessage: 'Телефон должен быть действительным номером (пример: +7 (705) 123 4567)',
    },
    emailVerify: {
        hello: 'Привет',
        text1: 'На Ваш Email <b>{email}</b> была отправлена ссылка для подтверждения электронной почты.',
        text2: 'Чтобы подтвердить ваш адрес электронной почты и продолжить пользоваться сервисом, пожалуйста пройдите по ссылке из письма.',
        logout: 'Выйти',
        sendAgain: 'Отправить повторно',
    },
    timeRangePicker: {
        start: 'Начало',
        end: 'Конец',
    },
    customTimePicker: {
        hour: 'Час',
        min: 'Мин',
        clear: 'Очистить',
    },
    registration: {
        title: 'Регистрация',
        name: 'Ваше Имя',
        email: 'Ваш Email',
        password: 'Придумайте Пароль',
        retypePassword: 'Повторите Пароль',
        register: 'Зарегистрироваться',
        login: 'Войти',
        haveAccount: 'У Вас уже есть аккаунт?',
    },
    forgotPassword: {
        title: 'Забыли пароль?',
        email: 'Ваш Email',
        recoverPassword: 'Восстановить Пароль',
        haveAccount: 'У Вас уже есть аккаунт?',
        login: 'Войти',
        text1: 'Мы отправили Вам ссылку для сброса пароля на Email. Для продолжения проверьте свой Email и следуйте инструкции из письма.',
    },
    resetPassword: {
        title: 'Установить новый пароль',
        email: 'Ваш Email',
        password: 'Новый пароль',
        retypePassword: 'Повторите новый пароль',
        setPassword: 'Установить пароль',
        login: 'Войти',
        text1: 'Новый пароль успешно установлен! Воспользуйтесь формой входа чтобы войти в свой аккаунт.',
    },
    auth: {
        signIn: 'Войти',
        dontHaveAccount: 'Еще нет аккаунта?',
        signUp: 'Зарегистрироваться',
        forgotPassword: 'Забыли пароль?',
        login: 'Войти',
        loginField: 'Email / Имя пользователя',
        password: 'Пароль',
    },
    profileMenu: {
        profile: 'Профиль',
        logout: 'Выход',
    },
    blocks: {
        avatar: 'Аватар',
        divider: 'Разделитель',
        text: 'Текст',
        back_call: 'Обратный звонок',
        map: 'Карта',
        appointment: 'Онлайн запись',
        messengers: 'Мессенджеры',
        socials: 'Социальные сети',
        gallery: 'Галерея картинок',
    },
    editor: {
        content: 'Контент',
        design: 'Дизайн',
        noAvailableSettings: 'Нет доступных настроек',
        avatar: {
            title: 'Заголовок',
            subtitle: 'Подзаголовок',
            circle: 'Круг',
            square: 'Квадрат',
            shape: 'Форма аватара',
            size: 'Размер аватара',
            delete: 'Удалить',
            choose: 'Выбрать',
        },
        text: {
            title: 'Заголовок',
            text: 'Текст',
            positionLeft: 'Слева',
            positionCenter: 'По центру',
            positionRight: 'Справа',
            titlePosition: 'Положение заголовка',
        },
        map: {
            size: 'Размер карты',
            description: 'Кликните по карте для указания местоположения:',
        },
        appointment: {
            buttonName: 'Название кнопки',
            buttonNamePlaceholder: 'По умолчанию: Онлайн запись',
            enableServices: 'Включить выбор услуг',
            servicesTooltip: 'Добавьте список услуг которые Вы выполняете в разделе "<b>Услуги</b>".\n' +
                'Обратите внимание, если Вы включите опцию "Выбор сотрудников", Вы можете назначить ' +
                'выполняемые услуги сотрудникам индивидуально.',
            enableSchedule: 'Включить запись по рабочему времени',
            scheduleTooltip: 'Настройка рабочего времени находится в разделе "<b>Расписание</b>" Обратите внимание, ' +
                'если Вы включите опцию "Выбор сотрудников", Вы можете настроить расписание индивидуально ' +
                'для каждого сотрудника.',
            enableEmployees: 'Включить выбор сотрудников',
            selectEmployees: 'Выберите сотрудников для онлайн записи:',
            employees: 'Сотрудники',
            forwardDays: 'Открыть запись на дней вперед',
            gapMinutes: 'Минут на перерыв после каждой записи',
            reservationInterval: 'Интервал записи, каждые:',
            advanceMinutes: 'Запись не позднее чем за:',
            oneDay: 'день',
            daysTwoToFour: 'дня',
            daysFiveAndMore: 'дней',
            min: 'мин.',
            hour: '1 час',
            hours2: '2 часа',
            hours3: '3 часа',
            hours6: '6 часов',
            hours12: '12 часов',
            no_limit: 'Без ограничения',
        },
        messengers: {
            title: 'Заголовок',
            noMessengers: 'Нет добавленных мессенджеров.',
            username: 'Имя пользователя',
            whatsapp: {
                title: 'WhatsApp',
                titlePlaceholder: 'Например: Написать в WhatsApp',
                phone: 'Телефон',
                phoneLabel: 'Номер телефона',
                chat: 'Чат',
                chatLabel: 'Ссылка на чат',
                message: 'Текст сообщения',
                messagePlaceholder: 'Пример: Здравствуйте, я хочу сделать заказ.',
            },
            telegram: {
                title: 'Telegram',
                titlePlaceholder: 'Например: Написать в Telegram',
            },
            viber: {
                title: 'Viber',
                titlePlaceholder: 'Например: Написать в Viber',
                phone: 'Телефон',
                phoneLabel: 'Номер телефона',
                channel: 'Канал',
                channelLabel: 'Ссылка на канал',
            },
            vkontakte: {
                title: 'Vkontakte',
                titlePlaceholder: 'Например: Написать в ВКонтакте',
            },
            skype: {
                title: 'Skype',
                titlePlaceholder: 'Например: Написать в Skype',
            },
            facebook: {
                title: 'Facebook',
                titlePlaceholder: 'Например: Написать в Facebook Messenger',
            },
        },
        socials: {
            title: 'Заголовок',
            noSocials: 'Нет добавленных социальных сетей',
            youtube: {
                title: 'YouTube',
                titlePlaceholder: 'Например: Мой YouTube канал',
                channel: 'Ссылка на Youtube канал',
            },
            instagram: {
                title: 'Instagram',
                titlePlaceholder: 'Например: Мой Instagram',
                profile: 'Ссылка на профиль Instagram',
            },
            vkontakte: {
                title: 'ВКонтакте',
                titlePlaceholder: 'Например: Мой ВКонтакте',
                profile: 'Ссылкана на профиль ВКонтакте',
            },
            tiktok: {
                title: 'TikTok',
                titlePlaceholder: 'Например: Мой TikTok',
                profile: 'Ссылкана на профиль TikTok',
            },
        },
        gallery: {
            title: 'Заголовок',
            noData: 'Нет добавленных картинок',
            deleteImageConfirmationTitle: 'Подтверждение',
            deleteImageConfirmationText: 'Вы действительно хотите удалить эту картинку?',
        },
        deleteBlockTitle: 'Подтверждение',
        deleteBlockText: 'Вы действительно хотите удалить блок?',
        cancelChangesBlockTitle: 'Подтверждение',
        cancelChangesBlockText: 'Вы действительно хотите отменить изменения?',
        addBlockDialog: {
            title: 'Выберите тип блока',
        },
        footerSettings: {
            title: 'Настройки футера',
            description: 'Добавьте ссылки на социальные сети:',
            youtubeUrl: 'Ссылка на Youtube',
            instagramUrl: 'Ссылка на Instagram',
            tiktokUrl: 'Ссылка на Tiktok',
            vkUrl: 'Ссылка на Vkontakte',
        },
    },
    validation: {
        required: 'Обязательное поле',
        email: 'Не правильный E-mail адрес',
        emailNoSpaces: 'Пробелы не разрешены',
        domain: 'Должно быть действительное доменное имя',
        url: 'Значение должно быть действительной ссылкой',
        alphaNumericDash: 'Поле может содержать только латинские буквы, цифры, дефисы и подчеркивания',
        passwordConfirmation: 'Пароли не совпадают',
        minString: 'Значение должно быть не короче %{num} символов',
    },
    navigation: {
        statistics: 'Статистика',
        myPages: 'Мои страницы',
        myDomains: 'Мои домены',
        settings: 'Настройки',
        appointments: 'Онлайн записи',
        backCalls: 'Обратный звонок',
        schedule: 'Расписание',
        employees: 'Сотрудники',
        services: 'Услуги',
    },
    statistics: {
        dates: 'Даты',
        page: 'Страница',
        noPages: 'Нет добавленных страниц',
        views: 'Просмотры',
        uniques: 'Уникальные',
    },
    pages: {
        published: 'Опубликованные',
        drafts: 'Черновики',
        createPage: 'Создать',
        imageCreating: 'Картинка создается',
        noImage: 'Нет картинки',
        yourPage: 'Ваша страница',
    },
    page: {
        myPages: 'Мои страницы',
        editMode: 'Режим редактирования',
        pageName: 'Имя страницы',
        uniqueName: 'Уникальное имя',
        domain: 'Домен',
        notLinked: 'Не привязан',
        saveSettings: 'Сохранить настройки',
        setAsMain: 'Сделать главной',
        next: 'Далее',
        back: 'Назад',
        createPage: 'Создать страницу',
        publish: 'Опубликовать',
        unpublish: 'Снять с публикации',
        published: 'Опубликовано',
        unpublishConfirmationTitle: 'Снять с публикации',
        unpublishConfirmationText: 'Вы действительно хотите снять с публикации эту страницу?',
        notPublished: 'Не опубликовано',
        chooseDesign: 'Выберите дизайн',
        chooseVariant: 'Вариант',
        yourLink: 'Ваша ссылка',
        directory: 'Директория',
        subdomain: 'Поддомен',
        ownDomain: 'Собственный домен'
    },
    editorMenu: {
        cancel: 'Отмена',
        back: 'Вернуться',
        addBlock: 'Добавить блок',
        save: 'Сохранить',
    },
    settings: {
        tabProfile: 'Профиль',
        tabNotifications: 'Уведомления',
        profile: {
            name: 'Ваше Имя',
            email: 'Электронная почта',
            username: 'Имя пользователя',
            employeeEmail: 'Email (для уведомлений)',
            currentPassword: 'Текущий пароль',
            newPassword: 'Новый пароль',
            retypePassword: 'Повторите новый пароль',
            changePassword: 'Сменить пароль',
            cancelEditTitle: 'Подтверждение',
            cancelEditText: 'Вы действительно хотите отменить изменения?',
        },
        notifications: {
            telegramSubtitle: 'Уведомления в Telegram бота',
            webpushSubtitle: 'Уведомления в браузере',
            notSubscribed: 'Вы не подписаны',
            subscribed: 'Вы подписаны!',
            subscribe: 'Подписаться',
            unsubscribe: 'Отписаться',
            unsubscribeTelegram: 'Вы действительно хотите отписаться от Telegram бота?',
            unsubscribeWebPush: 'Вы действительно хотите отписаться от WebPush уведомлений?',
            telegramSubscribeTitle: 'Как подписаться?',
            findBot: 'Найдите бота одним из следующих способов:',
            byScanningQrCode: 'Отсканировав QR код:',
            byClickingOnLink: 'Пройдя по ссылке',
            findManually: 'Найти бота в ручную в поиске Telegram по ключевому слову',
            or: 'или',
            pressStartButton: 'Нажмите кнопку <b>Начать</b>.',
            chooseSubscribe: 'В меню выберите пункт "<b>Подписаться</b>" и отправьте Ваш код подписки:',
        },
    },
    appointments: {
        title: 'Онлайн записи',
        tableHeader: {
            date: 'Дата',
            time: 'Время',
            employee: 'Сотрудник',
            name: 'Название/Услуга',
            page: 'Страница',
            created: 'Создано',
        },
        deleteEventText: 'Вы действительно хотите удалить эту запись?',
        employee: 'Сотрудник'
    },
    event: {
        addEvent: 'Добавить запись',
        editEvent: 'Редактировать запись',
        confirmOverlaps: 'Сохранить все равно',
        confirmOverlapsTitle: 'Конфликт времени записи',
        confirmOverlapsText: 'Время записи пересекается с временем другой существующей записи.',
        date: 'Дата',
        time: 'Время',
        employee: 'Сотрудник',
        service: 'Услуга',
        title: 'Название',
        contactName: 'Контактное имя',
        contactPhone: 'Контактный телефон',
        notConfirmed: 'Не подтвержден',
        confirm: 'Подтвердить',
    },
    backCalls: {
        title: 'Запросы на обратный звонок',
        tableHeader: {
            page: 'Страница',
            phone: 'Телефон',
            created: 'Создан',
            actions: 'Действия',
        },
        markAsReviewed: 'Отметить как выполненный',
        deleteBackCallText: 'Вы увереный что хотите удалить запрос на обратный звонок?',
    },
    domains: {
        title: 'Мои домены',
        add: 'Добавить',
        deleteDomainText: 'Вы увереный что хотите удалить домен?',
        domain: 'Домен',
        status: 'Статус',
        linked: 'Привязан',
        notLinked: 'Не привязан',
        ssl: 'SSL',
        sslYes: 'Да',
        sslNo: 'Нет',
        page: 'Главная страница',
        totalPages: 'Всего страниц',
        actions: 'Действия',
        addDomain: 'Добавить домен',
    },
    schedule: {
        title: 'Расписание',
        workHours: 'Часы работы',
        settingsWorkHours: 'Настройка часов работы',
        addEvent: 'Добавить запись',
        addDayOff: 'Добавить выходной',
        addWorkingTime: 'Добавить рабочее время',
        addBreak: 'Добавить перереыв',
        dayOff: 'Выходной',
        workingTime: 'Рабочее время',
        break: 'Перерыв',
        supervisor: 'Супервайзер',
        applyToAllEmployees: 'Применить ко всем сотрудникам',
        sharedTimeHint: 'Это время общее для всех сотрудников и может быть отредактировано только Вашим супервайзером.',
        weekdays: {
            sunday: 'Воскресенье',
            monday: 'Понедельник',
            tuesday: 'Вторник',
            wednesday: 'Среда',
            thursday: 'Четверг',
            friday: 'Пятница',
            saturday: 'Суббота',
        },
        deleteConfirmationText: 'Вы действительно хотите удалить?',
        workingHours: 'Часы работы',
        breakTime: 'Время перерыва',
        useSupervisorSettings: 'Использовать настройки супервайзера',
        hoursBreakHint: 'Время перерыва будет применено к каждому рабочему дню:',
    },
    employees: {
        title: 'Сотрудники',
        name: 'Имя',
        username: 'Имя пользователя',
        password: 'Пароль',
        retypePassword: 'Повторите пароль',
        newPassword: 'Новый пароль',
        retypeNewPassword: 'Повторите новый пароль',
        type: 'Тип',
        services: 'Услуги',
        actions: 'Действия',
        addEmployee: 'Добавить сотрудника',
        editEmployee: 'Редактировать сотрудника',
        deleteEmployeeText: 'Вы уверены что хотите удалить сотрудника?',
        reportCredentials: 'Сообщите сотруднику его Имя пользователя и Пароль.',
        reportNewCredentials: 'Сообщите сотруднику его новый пароль.',
        chooseServices: 'Выбрать услуги сотрудника',
        userType: {
            account: 'Супервайзер',
            employee: 'Сотрудник',
        }
    },
    services: {
        title: 'Услуги',
        chooseEmployees: 'Выбрать сотрудников',
        deleteServiceText: 'Вы уверены что хотели удалить услугу?',
        servicesHint: 'Услуга без выбранных специалистов, применяется ко всем специалистам.',
        table: {
            title: 'Название',
            time: 'Время',
            price: 'Цена',
            users: 'Сотрудники',
            choose: 'выбрать',
            allEmployees: 'все сотрудники',
        },
        editDialog: {
            addService: 'Добавить услугу',
            editService: 'Редактировать услугу',
            title: 'Название',
            description: 'Описание',
            time: 'Время',
            price: 'Цена',
        }
    },
};