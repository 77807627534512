<template>
  <div
      class="d-flex align-center justify-center"
      style="height: 80vh"
  >
    <v-card
        width="400"
        class="mx-auto"
        flat
    >
      <v-card-title class="text-center">
        {{ $t('registration.title') }}
      </v-card-title>
      
      <v-card-text>
        <v-alert
            v-model="errorAlert"
            class="mb-2"
            type="warning"
            density="compact"
            closable
        >
          {{ errorMessage }}
        </v-alert>
        <v-form
            ref="registrationForm"
            @submit.prevent="registration"
        >
          <v-text-field
              v-model="registerData.name"
              :label="$t('registration.name')"
              :rules="rules.nameValidation"
              :error-messages="errors.name"
          /> 
          
          <v-text-field
              v-model="registerData.email"
              :label="$t('registration.email')"
              :rules="rules.emailValidation"
              :error-messages="errors.email"
          />

          <v-text-field
              v-model="registerData.password"
              :label="$t('registration.password')"
              :rules="rules.passwordValidation"
              :error-messages="errors.password"
              type="password"
          />
          <v-text-field
              v-model="retypePassword"
              :label="$t('registration.retypePassword')"
              :rules="rules.retypePasswordValidation"
              :error-messages="errors.password"
              type="password"
          />
          <v-btn
              :loading="registering"
              type="submit"
              color="primary"
              block class="mt-2"
          >
            {{ $t('registration.register') }}
          </v-btn>
          
          <div class="mt-3">
            <p class="text-body-2">{{ $t('registration.haveAccount') }}
              <router-link :to="{name: routeNames.LOGIN}">{{ $t('registration.login') }}</router-link>
            </p>
          </div>
        </v-form>
      </v-card-text>
      
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import { INIT } from '@cabinet/store/actions';
import ROUTE_NAMES from "@cabinet/router/routeNames.js";
import auth from "@cabinet/api/auth.js";
import parseErrorsMixins from "@cabinet/mixins/parseErrorsMixins.js";
import {
  compose,
  ruleRequired,
  ruleEmail,
  ruleMin
} from "@cabinet/utils/validationRules.js";

export default {
  name: 'Registration',
  mixins: [parseErrorsMixins],
  data() {
    return {
      registering: false,
      registerData: {
        name: '',
        email: '',
        password: '',
      },
      retypePassword: null,
      routeNames: ROUTE_NAMES,
    };
  },
  computed: {
    rules() {
      const retypePasswordValidation = [];
      retypePasswordValidation.push(ruleRequired());
      retypePasswordValidation.push(
          (v) => v === this.registerData.password || this.$t('validation.passwordConfirmation')
      );
      return compose({
        nameValidation: [
          ruleRequired(),
        ],
        emailValidation: [
          ruleRequired(),
          ruleEmail(),
        ],
        passwordValidation: [
          ruleRequired(),
          ruleMin('string', 6),
        ],
        retypePasswordValidation,
      });
    },
  },
  mounted() {
    if (this.$store.getters.authorized) {
      this.$router.push({ name: ROUTE_NAMES.PAGES });
    }
  },
  methods: {
    ...mapActions([INIT]),
    async registration() {
      this.hideError();
      const validate = await this.$refs.registrationForm.validate();
      if (!validate.valid) {
        return;
      }
      this.registering = true;
      try {
        const registrationData = _.cloneDeep(this.registerData);
        registrationData.locale = this.$vuetify.locale.current || 'en';
        const offset = new Date().getTimezoneOffset();
        registrationData.time_shift_minutes = offset ? offset * -1 : 0;
        registrationData.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        await auth.registration(registrationData);
        this.$router.push({name: ROUTE_NAMES.EMAIL_VERIFY});
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.registering = false;
      }
    },
  }
}
</script>
