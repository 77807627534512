<template>
  <v-card
      flat
      class="page-item-phone-container ma-2"
      @click="$emit('click')"
  >
    <v-responsive :aspect-ratio="9 / 15">
      <v-sheet
          v-if="page.is_rendering"
          width="100%"
          height="100%"
          class="d-flex flex-column justify-center align-center"
      >
        <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
        />
        <span class="image-description">{{ $t('pages.imageCreating') }}...</span>
      </v-sheet>
      <v-img v-else-if="page.image_url" :src="page.image_url"/>
      <v-sheet
          v-else
          width="100%"
          height="100%"
          class="d-flex flex-column justify-center align-center"
      >
        <v-avatar size="40">
          <v-img :src="disappointedFaceSvg" width="100"></v-img>
        </v-avatar>
        <span class="image-description">{{ $t('pages.noImage') }}</span>
      </v-sheet>
    </v-responsive>
    <div class="container-title">
      {{ page.name }}
    </div>
  </v-card>
</template>

<script>
import disappointedFaceSvg from '@cabinet/assets/icons/dissapointed-face-emoji.svg';
export default {
  name: 'PageItem',
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      disappointedFaceSvg
    };
  },
}
</script>
<style>
.page-item-phone-container {
  width: 200px;
  border: 5px solid #505050;
  border-radius: 15px;
  
  .image-description {
    font-size: 10px;
  }
  
  .container-title {
    position: absolute;
    bottom: -1px;
    text-align: center;
    background-color: rgba(61, 61, 61, 0.7);
    color: #FFFFFF;
    width: 100%;
    line-height: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 10px;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4x);
  }
}
</style>