import api from './api';

export default {
    /**
     * Get Tmp uploads data.
     *
     * @return Promise<Object>
     */
    getTmpUploadsData(extension) {
        return api
            .get('/api/get-tmp-upload-data', {
                params: {
                    extension
                }
            })
            .then(response => response.data);
    },

}