import api from './api';
import { responseHandler } from './api';

export default {
    /**
     * Get event.
     *
     * @return Promise<Object>
     */
    getEvent(eventId) {
        return api
            .get('/api/events/' + eventId)
            .then(responseHandler);
    },
    
    /**
     * Events.
     *
     * @return Promise<Object>
     */
    getEvents(params) {
        return api
            .get('/api/events', {
                params
            })
            .then(responseHandler);
    },

    /**
     * Create event.
     *
     * @return Promise<Object>
     */
    createEvent(data) {
        return api
            .post('/api/events', data)
            .then(responseHandler);
    },

    /**
     * Update event.
     *
     * @return Promise<Object>
     */
    updateEvent(eventId, data) {
        return api
            .put('/api/events/' + eventId, data)
            .then(responseHandler);
    },

    /**
     * Confirm event.
     *
     * @return Promise<Object>
     */
    confirmEvent(eventId) {
        return api
            .post('/api/events/' + eventId + '/confirm')
            .then(responseHandler);
    },

    /**
     * Delete the event.
     *
     * @return Promise<Object>
     */
    delete(id) {
        return api
            .delete('/api/events/' + id)
            .then(response => response.data);
    },
}