<template>
  <v-card flat>
    <v-toolbar>
      <v-toolbar-title>
        {{ $t('backCalls.title') }}
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-data-table-server
          ref="backCallsTable"
          v-model:items-per-page="itemsPerPage"
          v-model:sort-by="sortBy"
          v-model:page="page"
          :headers="headers"
          :items="backCalls"
          :items-length="totalItems"
          :loading="loading"
          :search="search"
          :fixed-header="true"
          class="back-calls-table"
          @update:options="loadItems"
      >
        <template v-slot:item.back_call_created_at="{ item }">
          {{ formatDateTime(item.back_call_created_at) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-avatar
              v-if="item.reviewed"
              size="29"
              color="#eaf5ea"
              class="mr-1"
          >
            <v-icon color="success">
              mdi-check
            </v-icon>
          </v-avatar>
          <v-btn
              v-else
              density="compact"
              variant="tonal"
              icon="mdi-check"
              class="mr-1"
              :title="$t('backCalls.markAsReviewed')"
              :loading="item.markingAsReviewed"
              @click="markAsReviewed(item)"
          />
          
          <v-btn
              density="compact"
              variant="tonal"
              icon="mdi-delete-outline"
              :loading="item.deleting"
              @click="deleteBackCall(item)"
          />
        </template>
      </v-data-table-server>
    </v-card-text>
    <confirmation ref="confirmation"/>
  </v-card>
</template>

<script>
import checkAuthorizedMixins from "@cabinet/mixins/checkAuthorizedMixins.js";
import backCalls from "@cabinet/api/backCalls.js";
import moment from "moment";
import Confirmation from "@cabinet/components/Confirmation.vue";

export default {
  name: 'BackCalls',
  components: {Confirmation},
  mixins: [
    checkAuthorizedMixins,
  ],
  data() {
    return {
      loading: false,
      itemsPerPage: 10,
      page: 1,
      sortBy: [
        {
          key: 'back_call_created_at',
          order: 'desc',
        }
      ],
      totalItems: 0,
      search: '',
      backCalls: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          title: this.$t('backCalls.tableHeader.page'),
          align: 'start',
          sortable: false,
          key: 'page_name',
        },
        {
          title: this.$t('backCalls.tableHeader.phone'),
          align: 'start',
          sortable: false,
          key: 'phone',
        },
        {
          title: this.$t('backCalls.tableHeader.created'),
          align: 'start',
          key: 'back_call_created_at',
        },
        {
          title: this.$t('backCalls.tableHeader.actions'),
          align: 'center',
          sortable: false,
          key: 'actions',
        },
      ]
    }
  },
  methods: {
    formatDateTime(dateTime) {
      return moment(dateTime)
          .format(this.$t('datetimeFormat'));
    },
    async loadItems ({ page, itemsPerPage, sortBy }) {
      this.loading = true
      try {
        const params = {
          page,
          itemsPerPage,
          sortBy
        };
        const response = await backCalls.getBackCalls(params);
        this.totalItems = response.meta.total;
        this.backCalls = response.data.map(item => {
          item.markingAsReviewed = false;
          item.deleting = false;
          return item;
        });
      } finally {
        this.loading = false;
      }
    },
    async markAsReviewed(item) {
      item.markingAsReviewed = true;
      try {
        await backCalls.markAsReviewed(item.id);
        item.reviewed = true;
      } finally {
        item.markingAsReviewed = false;
      }
    },
    deleteBackCall(item) {
      this.$refs.confirmation.showConfirm(
          this.$t('confirmation'),
          this.$t('backCalls.deleteBackCallText'),
          async() => {
            await backCalls.delete(item.id);
            this.loadItems({
              page: this.page,
              itemsPerPage: this.itemsPerPage,
              sortBy: this.sortBy
            })
          }
      );
    }
  },
}
</script>
<style>
  .back-calls-table {
    .v-data-table-header__content {
      font-weight: bold;
    }
    .v-table__wrapper {
      min-height: 200px;
      max-height: calc(100vh - 280px);
      .v-data-table__tr td {
        white-space: nowrap;
      }
    }
  }
</style>
