<template>
  <v-card flat>
    <v-toolbar>
      <v-toolbar-title>
        {{ $t('appointments.title') }}
      </v-toolbar-title>
      
      <v-btn
          variant="tonal"
          @click="$refs.editEventDialog.show()"
      >
        <template v-slot:prepend>
          <v-icon>mdi-plus</v-icon>
        </template>
        {{ $t('add') }}
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-toolbar
          color="transparent"
      >
        <date-picker
            v-model="filters.date"
            :hide-details="true"
            :max-width="200"
        />
        <v-select
            v-if="isSupervisor"
            v-model="filters.employeeId"
            :items="employees"
            :return-object="false"
            class="ml-3"
            variant="outlined"
            style="max-width: 250px"
            :label="$t('appointments.employee')"
            hide-details
            clearable
            item-value="id"
            item-title="name"
        />
      </v-toolbar>
      <v-data-table-server
          ref="eventsTable"
          v-model:items-per-page="itemsPerPage"
          v-model:sort-by="sortBy"
          v-model:page="page"
          :headers="headers"
          :items="events"
          :items-length="totalItems"
          :loading="loading"
          :search="search"
          :fixed-header="true"
          class="events-table"
          @update:options="loadItems"
      >
        <template v-slot:item.name="{ item }">
          <a href="javascript:;" @click="showEvent(item)">
            {{ item.service_id ? item.service_title : item.name }}
          </a>
        </template>
        <template v-slot:item.date="{ item }">
          {{ item.date ? formatDate(item.date) : '—'}}
        </template>
        <template v-slot:item.time_from="{ item }">
          {{ item.time_from && item.time_to ? formatTime(item.time_from) + ' - ' + formatTime(item.time_to): '—'}}
        </template>
        <template v-slot:item.page_name="{ item }">
          <router-link
              v-if="item.page_uuid"
              :to="{name: ROUTE_NAMES.PAGE, params: {uuid: item.page_uuid}}"
          >
            {{ item.page_name }}
          </router-link>
          <span v-else>—</span>
        </template>
        <template v-slot:item.event_created_at="{ item }">
          {{ formatDateTime(item.event_created_at) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
              density="compact"
              variant="tonal"
              icon="mdi-pencil-outline"
              class="mr-1"
              :loading="item.deleting"
              @click="editEvent(item.id)"
          />
          <v-btn
              density="compact"
              variant="tonal"
              icon="mdi-delete-outline"
              :loading="item.deleting"
              @click="deleteEvent(item.id)"
          />
        </template>
      </v-data-table-server>
    </v-card-text>
    <confirmation ref="confirmation"/>
    <edit-event-dialog
        ref="editEventDialog"
        :is-supervisor="isSupervisor"
        :require-date-time="false"
        @deleted="reload"
        @saved="reload"
    />
    <view-event-dialog
        ref="viewEventDialog"
        :is-supervisor="isSupervisor"
        @deleted="reload"
        @clickEdit="editEvent"
    />
  </v-card>
</template>

<script>
import checkAuthorizedMixins from "@cabinet/mixins/checkAuthorizedMixins.js";
import eventsApi from "@cabinet/api/events.js";
import employeesApi from '@cabinet/api/employees.js'
import moment from "moment";
import Confirmation from "@cabinet/components/Confirmation.vue";
import ROUTE_NAMES from "@cabinet/router/routeNames.js";
import EditEventDialog from "@cabinet/components/events/EditEventDialog.vue";
import {concat} from "lodash";
import ViewEventDialog from "@cabinet/components/events/ViewEventDialog.vue";
import DatePicker from "@cabinet/components/DatePicker.vue";

export default {
  name: 'Events',
  components: {DatePicker, ViewEventDialog, EditEventDialog, Confirmation},
  mixins: [
    checkAuthorizedMixins,
  ],
  data() {
    return {
      loading: false,
      filters: {
        date: null,
        employeeId: null,
      },
      employees: [],
      itemsPerPage: 10,
      page: 1,
      sortBy: [
        {
          key: 'event_created_at',
          order: 'desc',
        }
      ],
      totalItems: 0,
      search: '',
      events: [],
      ROUTE_NAMES,
    };
  },
  computed: {
    isSupervisor() {
      return this.$store.state.user.owner_id === null;
    },
    headers() {
      const header = [
        {
          title: this.$t('appointments.tableHeader.name'),
          align: 'start',
          sortable: false,
          key: 'name',
        },
        {
          title: this.$t('appointments.tableHeader.date'),
          align: 'start',
          sortable: false,
          key: 'date',
        },
        {
          title: this.$t('appointments.tableHeader.time'),
          align: 'start',
          sortable: false,
          key: 'time_from',
        },
      ];
      if (this.isSupervisor) {
        header.push({
          title: this.$t('appointments.tableHeader.employee'),
          align: 'start',
          sortable: false,
          key: 'employee_name',
        });
        header.push({
          title: this.$t('appointments.tableHeader.page'),
          align: 'start',
          sortable: false,
          key: 'page_name',
        });
      }
      const header2 = [
        {
          title: this.$t('appointments.tableHeader.created'),
          align: 'start',
          key: 'event_created_at',
        },
        {
          title: this.$t('backCalls.tableHeader.actions'),
          align: 'center',
          sortable: false,
          key: 'actions',
        },
      ];
      return concat(header, header2);
    }
  },
  watch: {
    filters: {
      handler() {
        this.loadItems({
          page: 1,
          itemsPerPage: this.itemsPerPage,
          sortBy: this.sortBy
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.loadEmployees();
  },
  methods: {
    formatDateTime(dateTime) {
      return moment(dateTime)
          .format(this.$t('datetimeFormat'));
    },
    formatDate(dateTime) {
      return moment.parseZone(dateTime)
          .format(this.$t('dateFormat'));
    },
    formatTime(time) {
      return moment(time, "HH:mm:ss").format(this.$t('shortTimeFormat'));
    },
    async loadEmployees() {
      this.employees = await employeesApi.getEmployees();
    },
    async loadItems ({ page, itemsPerPage, sortBy }) {
      this.loading = true
      try {
        const params = {
          page,
          itemsPerPage,
          sortBy,
          date: this.filters.date,
          employeeId: this.filters.employeeId,
        };
        
        const response = await eventsApi.getEvents(params);
        this.totalItems = response.meta.total;
        this.events = response.data.map(item => {
          item.deleting = false;
          return item;
        });
      } finally {
        this.loading = false;
      }
    },
    showEvent(event) {
      this.$refs.viewEventDialog.show(event.id);
    },
    editEvent(eventId) {
      this.$refs.editEventDialog.show(eventId);
    },
    reload() {
      this.loadItems({
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        sortBy: this.sortBy
      });
    },
    deleteEvent(eventId) {
      this.$refs.confirmation.showConfirm(
          this.$t('confirmation'),
          this.$t('appointments.deleteEventText'),
          async() => {
            await eventsApi.delete(eventId);
            this.reload();
          }
      );
    }
  },
}
</script>
<style>
  .events-table {
    .v-data-table-header__content {
      font-weight: bold;
    }
    .v-table__wrapper {
      min-height: 200px;
      max-height: calc(100vh - 280px);
      .v-data-table__tr td {
        white-space: nowrap;
      }
    }
  }
</style>
