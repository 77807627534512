<template>
  <v-card flat>
    <v-toolbar>
      <v-toolbar-title>
        {{ $t('services.title') }}
      </v-toolbar-title>
      
      <v-btn
          variant="tonal"
          @click="$refs.editServiceDialog.show()"
      >
        <template v-slot:prepend>
          <v-icon>mdi-plus</v-icon>
        </template>
        {{ $t('add') }}
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-data-table
          ref="servicesTable"
          :headers="headers"
          :items="services"
          :loading="loading"
          :fixed-header="true"
          class="services-table"
      >
        <template v-slot:item.time="{ item }">
          {{ formatTime(item.time) }}
        </template>
        
        <template v-slot:item.price="{ item }">
          {{ formatPrice(item.price) }} ₸
        </template>

        <template v-slot:item.total_users="{ item }">
          <span
              v-if="item.total_users"
              class="choose-employees"
              @click="chooseEmployees(item)"
          >
            {{ item.total_users }} ({{ $t('services.table.choose') }})
          </span>
          <span
              v-else
              class="choose-employees"
              @click="chooseEmployees(item)"
          >
            {{ $t('services.table.allEmployees') }}
          </span>
        </template>
        
        <template v-slot:item.actions="{ item }">
          <v-btn
              v-if="item.type !== 'account'"
              density="compact"
              variant="flat"
              icon="mdi-delete-outline"
              :loading="item.deleting"
              class="mr-2"
              @click="deleteService(item)"
          />
          <v-btn
              v-if="item.type !== 'account'"
              density="compact"
              variant="flat"
              icon="mdi-pencil-outline"
              @click="editEmployee(item)"
          />
        </template>
      </v-data-table>
    </v-card-text>
    <confirmation ref="confirmation"/>
    <edit-service-dialog
        ref="editServiceDialog"
        @saved="fetchServices"
    />
    <choose-employees-dialog
        ref="chooseEmployeesDialog"
        @saved="fetchServices"
    />
  </v-card>
</template>

<script>
import checkAuthorizedMixins from "@cabinet/mixins/checkAuthorizedMixins.js";
import Confirmation from "@cabinet/components/Confirmation.vue";
import servicesApi from "@cabinet/api/services.js";
import routeNames from "@cabinet/router/routeNames.js";
import EditServiceDialog from "@cabinet/components/services/EditServiceDialog.vue";
import { convertNumberToTime, convertTimeToNumber } from '@cabinet/utils/convertNumberToTime';
import ChooseEmployeesDialog from "@cabinet/components/services/ChooseEmployeesDialog.vue";
export default {
  name: 'Services',
  components: {ChooseEmployeesDialog, EditServiceDialog, Confirmation},
  mixins: [
    checkAuthorizedMixins,
  ],
  data() {
    return {
      loading: false,
      services: [],
      routeNames,
    };
  },
  computed: {
    headers() {
      return [
        {
          title: this.$t('services.table.title'),
          align: 'start',
          sortable: false,
          key: 'title',
        },
        {
          title: this.$t('services.table.time'),
          align: 'start',
          sortable: false,
          key: 'time',
        },
        {
          title: this.$t('services.table.price'),
          align: 'start',
          sortable: false,
          key: 'price',
        },
        {
          title: this.$t('services.table.users'),
          align: 'start',
          sortable: false,
          key: 'total_users',
        },
        {
          title: this.$t('employees.actions'),
          align: 'center',
          sortable: false,
          key: 'actions',
          width: 100,
        },
      ]
    }
  },
  mounted() {
    this.fetchServices();
  },
  methods: {
    
    formatPrice(price) {
      return new Intl.NumberFormat().format(price);
    },
    
    formatTime(number) {
      return convertNumberToTime(number);
    },
    
    async fetchServices() {
      this.loading = true
      try {
        const servicesList = await servicesApi.getServices();
        this.services = servicesList.map(item => {
          item.deleting = false;
          return item;
        });
      } finally {
        this.loading = false;
      }
    },
    
    editEmployee(employee) {
      this.$refs.editServiceDialog.show(employee);
    },

    deleteService(item) {
      this.$refs.confirmation.showConfirm(
          this.$t('confirmation'),
          this.$t('services.deleteServiceText'),
          async() => {
            await servicesApi.deleteService(item.id);
            this.fetchServices();
          }
      );
    },

    chooseEmployees(service) {
      this.$refs.chooseEmployeesDialog.show(service);
    },
  },
}
</script>
<style>
  .services-table {
    .choose-employees {
      cursor: pointer;
      text-decoration: underline;
      white-space: nowrap;
    }
    
    .v-data-table-header__content {
      font-weight: bold;
    }
    .v-table__wrapper {
      min-height: 200px;
      max-height: calc(100vh - 280px);
      .v-data-table__tr td {
        white-space: nowrap;
      }
    }
  }
</style>
