import api from './api';

export default {
    /**
     * Create domain.
     *
     * @return Promise<Object>
     */
    createDomain(data) {
        return api
            .post('/api/domains', data)
            .then(response => response.data);
    },
    
    /**
     * Get Domains.
     *
     * @return Promise<Object>
     */
    getDomains() {
        return api
            .get('/api/domains')
            .then(response => response.data);
    },

    /**
     * Delete domain.
     *
     * @return Promise<Object>
     */
    delete(id) {
        return api
            .delete('/api/domains/' + id)
            .then(response => response.data);
    },
}