import api from './api';

export default {
    /**
     * Get List of block types.
     *
     * @return Promise<Object>
     */
    getBlockTypes() {
        return api
            .get('/api/blocks/types')
            .then(response => response.data);
    },

}