<template>
  <div>
    <v-text-field
        v-model="data.title"
        :label="$t('editor.messengers.title')"
        :placeholder="$t('editor.messengers.' + data.type + '.titlePlaceholder')"
    />

    <v-radio-group
        v-model="data.chatType"
        inline
    >
      <v-radio
          :label="$t('editor.messengers.' + data.type + '.phone')"
          value="phone"
      ></v-radio>
      <v-radio
          :label="$t('editor.messengers.' + data.type + '.chat')"
          value="chat"
      ></v-radio>
    </v-radio-group>
    <v-phone-input
        v-if="data.chatType === 'phone'"
        v-model="data.phone"
        ref="phone"
        :all-countries="allCountries"
        :label="$t('editor.messengers.' + data.type + '.phoneLabel')"
        :country-label="$t('phoneInput.country')"
        default-country="KZ"
        country-icon-mode="svg"
        :invalid-message="$t('phoneInput.invalidPhoneMessage')"
    />

    <v-text-field
        v-if="data.chatType === 'chat'"
        v-model="data.chat"
        :label="$t('editor.messengers.' + data.type + '.chatLabel')"
        placeholder="https://chat.whatsapp.com/****************"
    />
    
    <v-textarea
        v-model="data.message"
        :label="$t('editor.messengers.' + data.type + '.message')"
        :placeholder="$t('editor.messengers.' + data.type + '.messagePlaceholder')"
    />
  </div>
</template>
<script>
export default {
  name: 'whatsapp',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    };
  },
}
</script>