<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="800"
  >
    <dialog-loading v-if="loading"/>
    
    <v-card v-else>
      <v-card-title>{{ $t('schedule.settingsWorkHours') }}</v-card-title>
      <v-divider />
      <v-tabs
          v-model="tab"
          color="primary"
          direction="horizontal"
      >
        <v-tab value="hours">
          <v-icon start>
            mdi-briefcase-outline
          </v-icon>
          {{ $t('schedule.workingHours') }} <span v-if="isEditedHours || isEditedUseCommonHours">*</span>
        </v-tab>
        <v-tab value="breaks">
          <v-icon start>
            mdi-coffee-outline
          </v-icon>
          {{ $t('schedule.breakTime') }} <span v-if="isEditedBreaks || isEditedUseCommonHours">*</span>
        </v-tab>
      </v-tabs>
      <v-divider />
      <div class="ml-2 mr-2" style="height: calc(100vh - 300px); overflow-y: scroll">
        <v-checkbox
            v-if="!isSupervisor"
            v-model="useCommonBusinessHours"
            :label="$t('schedule.useSupervisorSettings')"
        />
        <v-sheet>
          <v-window v-model="tab">
            <v-window-item value="hours" eager>
              <div>
                <v-form
                    ref="businessHoursForm"
                    :disabled="useCommonBusinessHours"
                    @submit.prevent="save"
                >
                  <template
                      v-for="weekDay in weekDaysToShow"
                      :key="(useCommonBusinessHours ? 'supervisor_' : '') + 'day_of_week_' + weekDay.day_of_week"
                  >
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-checkbox
                            v-model="weekDay.enabled"
                            :label="$t(weekDay.name)"
                            hide-details
                        />
                      </v-col>
                      <v-col v-if="weekDay.enabled" cols="12" >
                        <time-range-picker
                            v-model:time-start="weekDay.open_time"
                            v-model:time-end="weekDay.close_time"
                            :rules="rules.timesValidation"
                        />
                      </v-col>
                    </v-row>
                  </template>
                </v-form>
              </div>
            </v-window-item>
            <v-window-item value="breaks" eager>
              <template v-if="hoursBreakToShow">
                <div class="mt-5 mb-5">
                  {{ $t('schedule.hoursBreakHint') }}
                </div>
                <v-form
                    ref="breaksForm"
                    :disabled="useCommonBusinessHours"
                    @submit.prevent="save"
                >
                  <time-range-picker
                      :key="'break_' + (hoursBreakToShow.user_id || 'null')"
                      v-model:time-start="hoursBreakToShow.start_time"
                      v-model:time-end="hoursBreakToShow.end_time"
                      :rules="rules.breakTimesValidation"
                  />
                </v-form>
              </template>
            </v-window-item>
          </v-window>
        </v-sheet>
      </div>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
            @click="this.dialog = false"
        >
          <template v-if="isEdited">
            {{ $t('cancel') }}
          </template>
          <template v-else>
            {{ $t('close') }}
          </template>
        </v-btn>
        <v-btn
            :loading="saving"
            :disabled="!isEdited"
            prepend-icon="mdi-check-circle"
            color="primary-btn"
            variant="tonal"
            theme="dark"
            @click="save"
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import TimeRangePicker from "@cabinet/components/TimeRangePicker.vue";
import {compose, ruleRequired} from "@cabinet/utils/validationRules.js";
import businessHours from "@cabinet/api/businessHours.js";
import DialogLoading from "@cabinet/components/DialogLoading.vue";

export default {
  name: 'BusinessHoursDialog',
  components: {
    DialogLoading,
    TimeRangePicker
  },
  data() {
    return {
      dialog: false,
      saving: false,
      loading: false,
      tab: 'breaks',
      weekDaysOrig: null,
      userId: null,
      useCommonBusinessHours: null,
      useCommonBusinessHoursOrig: null,
      isSupervisor: null,
      weekDays: [],
      supervisorWeekDays: [],
      weekDaysTemplate: [
        {
          day_of_week: 1,
          enabled: false,
          name: 'schedule.weekdays.monday',
          open_time: null,
          close_time: null,
        },
        {
          day_of_week: 2,
          enabled: false,
          name: 'schedule.weekdays.tuesday',
          open_time: null,
          close_time: null,
        },
        {
          day_of_week: 3,
          enabled: false,
          name: 'schedule.weekdays.wednesday',
          open_time: null,
          close_time: null,
        },
        {
          day_of_week: 4,
          enabled: false,
          name: 'schedule.weekdays.thursday',
          open_time: null,
          close_time: null,
        },
        {
          day_of_week: 5,
          enabled: false,
          name: 'schedule.weekdays.friday',
          open_time: null,
          close_time: null,
        },
        {
          day_of_week: 6,
          enabled: false,
          name: 'schedule.weekdays.saturday',
          open_time: null,
          close_time: null,
        },
        {
          day_of_week: 0,
          enabled: false,
          name: 'schedule.weekdays.sunday',
          open_time: null,
          close_time: null,
        },
      ],
      hoursBreak: null,
      hoursBreakOrig: null,
      supervisorHoursBreak: null,
    };
  },
  computed: {
    weekDaysToShow() {
      return this.useCommonBusinessHours
          ? this.supervisorWeekDays
          : this.weekDays;
    },
    hoursBreakToShow() {
      return this.useCommonBusinessHours
          ? this.supervisorHoursBreak
          : this.hoursBreak;
    },
    rules() {
      const breakTimesValidation = [];
      if (this.hoursBreak.start_time || this.hoursBreak.end_time) {
        breakTimesValidation.push(ruleRequired());
      }
      return compose({
        timesValidation: [
          ruleRequired(),
        ],
        breakTimesValidation,
      });
    },
    isEditedHours() {
      return !_.isEqual(this.weekDays, this.weekDaysOrig);
    },
    isEditedBreaks() {
      return !_.isEqual(this.hoursBreak, this.hoursBreakOrig);
    },
    isEditedUseCommonHours() {
      return this.useCommonBusinessHours !== this.useCommonBusinessHoursOrig;
    },
    isEdited() {
      return this.isEditedHours || this.isEditedBreaks || this.isEditedUseCommonHours;
    },
  },
  methods: {
    async show(userId) {
      this.loading = true;
      this.dialog = true;
      this.userId = userId;
      this.tab = 'hours';
      try {
        const promises = [
          this.loadBusinessHours(),
          this.loadBusinessHoursBreak(),
        ];
        await Promise.all(promises);
      } finally {
        this.loading = false;
      }
      
    },
    close() {
      this.dialog = false;
    },
    async loadBusinessHours() {
      const response = await businessHours.getUserBusinessHours(this.userId);
      this.useCommonBusinessHours = response.use_common_business_hours;
      this.useCommonBusinessHoursOrig = response.use_common_business_hours;
      this.isSupervisor = response.is_supervisor;
      const weekDays = this.prepareWeekDays(response.data);
      this.weekDays = weekDays;
      this.weekDaysOrig = _.cloneDeep(weekDays);
      this.supervisorWeekDays = this.prepareWeekDays(response.supervisor_data);
    },
    prepareWeekDays(weekDaysData) {
      const weekDaysByWeekDayKey = {};
      weekDaysData.forEach(item => {
        weekDaysByWeekDayKey[item.day_of_week] = item;
      });

      let weekDays = _.cloneDeep(this.weekDaysTemplate);
      weekDays = weekDays.map(item => {
        if (Object.prototype.hasOwnProperty.call(
            weekDaysByWeekDayKey,
            item.day_of_week,
        )) {
          item.enabled = true;
          item.open_time = weekDaysByWeekDayKey[item.day_of_week].open_time;
          item.close_time = weekDaysByWeekDayKey[item.day_of_week].close_time;
        } else {
          item.enabled = false
          item.open_time = null;
          item.close_time = null;
        }
        return item;
      });
      return weekDays;
    },
    async loadBusinessHoursBreak() {
      const response = await businessHours.getUserBusinessHoursBreak(this.userId);
      
      const emptyHoursBreak = {
        user_id: null,
        start_time: null,
        end_time: null,
      };
      this.hoursBreak = _.cloneDeep(response.data || emptyHoursBreak);
      this.hoursBreakOrig = _.cloneDeep(response.data || emptyHoursBreak);
      this.supervisorHoursBreak = _.cloneDeep(response.supervisor_data || emptyHoursBreak);
    },
    async save() {
      const validateHours = await this.$refs.businessHoursForm.validate();
      if (!validateHours.valid) {
        this.tab = 'hours';
        return;
      }
      const validateBreaks = await this.$refs.breaksForm.validate();
      if (!validateBreaks.valid) {
        this.tab = 'breaks';
        return;
      }
      
      const submitData = this.prepareSubmitData();
      try {
        this.saving = true;
        await businessHours.saveBusinessHours(this.userId, submitData);
        this.weekDaysOrig = _.cloneDeep(this.weekDays);
        this.hoursBreakOrig = _.cloneDeep(this.hoursBreak);
        this.useCommonBusinessHoursOrig = this.useCommonBusinessHours;
        this.$emit('saved');
        this.dialog = false;
      } finally {
        this.saving = false;
      }
    },
    prepareSubmitData() {
      const submitData = {
        use_common_business_hours: this.useCommonBusinessHours,
        hours: [],
        break: {
          start_time: null,
          end_time: null,
        },
      };
      if (!this.useCommonBusinessHours) {
        this.weekDays.forEach(item => {
          if (item.enabled && item.open_time && item.close_time) {
            submitData.hours.push({
              day_of_week: item.day_of_week,
              open_time: item.open_time,
              close_time: item.close_time,
            });
          }
          submitData.break = this.hoursBreak;
        });
      }
      return submitData;
    },
  }
}
</script>
