<template>
  <common-block>
    <template v-slot:common>
    </template>
    <template v-slot:content>
      <v-text-field
          v-model="dataModel.title"
          :label="$t('editor.gallery.title')"
          variant="underlined"
      />
      <v-data-table :items="items" :headers="headers">
        <template v-slot:no-data>
          {{ $t('editor.gallery.noData') }}
        </template>
        <template v-slot:header.actions>
          <v-btn
              color="primary"
              variant="tonal"
              @click="$refs.fileInput.click()"
          >
            <template v-slot:prepend>
              <v-icon>mdi-plus</v-icon>
            </template>
            {{ $t('add') }}
          </v-btn>
        </template>
        <template v-slot:item.image="{ item }">
          <v-card class="my-2 align-left" rounded flat>
            <v-img
                :src="item.thumbnailUrl"
                height="64"
            ></v-img>
          </v-card>
        </template>
        <template v-slot:item.actions="{ item, index }">
          <v-btn
              variant="tonal"
              density="compact"
              color="red"
              @click="deleteImage(item, index)"
          >
            {{ $t('delete') }}
          </v-btn>
        </template>
      </v-data-table>

      <image-cropper
          ref="imageCropper"
          :width="1200"
          :height="675"
          :thumbnail="true"
          :thumbnail-width="296"
          :thumbnail-height="167"
          @saved="addNewImage"
      />
      <input
          type="file"
          ref="fileInput"
          @change="handleFileChange"
          style="display: none;"
          accept="image/*"
      />
      <confirmation ref="confirmation"/>
    </template>
    <template v-slot:design>
      
    </template>
  </common-block>
</template>
<script>
import blockMixin from "@cabinet/components/editor/blocks/blockMixin.js";
import ImageCropper from "@cabinet/components/ImageCropper.vue";
import Confirmation from "@cabinet/components/Confirmation.vue";
import _ from "lodash";

export default {
  name: 'Divider_1',
  components: {Confirmation, ImageCropper},
  mixins: [blockMixin],
  data() {
    return {
      headers: [
        {
          title: '',
          align: 'left',
          sortable: false,
          key: 'image',
          width: 140
        },
        {
          title: '',
          align: 'end',
          sortable: false,
          key: 'actions',
        },
      ],
    };
  },
  computed: {
    items() {
      if (!this.dataModel.images) {
        return [];
      }
      return this.dataModel.images;
    }
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const src = e.target.result;
          this.$refs.imageCropper.show(src);
        };
        reader.readAsDataURL(file);
      }
    },
    addNewImage(item) {
      if (!this.dataModel.images) {
        this.dataModel.images = [];
      }
      this.dataModel.images.push({
        imageUrl: item.tmpUrl,
        thumbnailUrl: item.thumbnailTmpUrl,
        isTempUrl: true,
      });
    },
    deleteImage(item, index) {
      this.$refs.confirmation.showConfirm(
          this.$t('editor.gallery.deleteImageConfirmationTitle'),
          this.$t('editor.gallery.deleteImageConfirmationText'),
          () => {
            this.dataModel.images.splice(index, 1);
          }
      );
      
    }
  }
}
</script>