import api from './api';

export default {
    /**
     * Get visitors statistic.
     *
     * @return Promise<Object>
     */
    getVisitors(params) {
        return api
            .get('/api/statistics/visitors', {
                params
            })
            .then(response => response.data);
    },

}