<template>
  <v-app-bar
      flat
      color="background"
  >
    <v-spacer />
    <language :authorized="false" :small="false"/>
    <v-btn
        v-if="showEnterBtn"
        color="primary"
        @click="$router.push({name: routesNames.LOGIN})"
    >
      {{ $t('auth.login') }}
    </v-btn>
  </v-app-bar>
</template>

<script>
import CountryFlagEsm from "vue-country-flag-next";
import Language from "@cabinet/components/Language.vue";
import ROUTE_NAMES from "@cabinet/router/routeNames.js";
export default {
  name: 'AppBarUnauthorized',
  components: {
    Language,
    CountryFlagEsm,
  },
  data() {
    return {
      routesNames: ROUTE_NAMES,
    };
  },
  computed: {
    showEnterBtn() {
      return this.$route.name !== ROUTE_NAMES.EMAIL_VERIFY && this.$route.name !== ROUTE_NAMES.LOGIN;
    },
  },
}
</script>
