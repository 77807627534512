<template>
  <v-dialog
      v-model="showConfirmPopup"
      max-width="400"
  >
    <v-card>
      <v-card-title class="headline">{{ $t('event.confirmOverlapsTitle') }}</v-card-title>
      <v-alert
          border="top"
          type="warning"
          variant="tonal"
      >
        {{ $t('event.confirmOverlapsText') }}
      </v-alert>
      <slot/>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            color="blue darken-1"
            text
            @click.native="reject()"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
            :loading="loading"
            color="primary"
            variant="tonal"
            @click.native="confirm()"
        >
          {{ $t('event.confirmOverlaps') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmationOverlaps',
  data() {
    return {
      loading: false,
      showConfirmPopup: false,
      confirmCallback: null,
      rejectCallback: null,
    };
  },
  methods: {
    /**
     * Show confirmation popup
     *
     * @param {function} confirmCallback - Callback when clicked Ok button.
     * @param {function|null} rejectCallback - Callback when clicked Cancel button.
     */
    showConfirm(confirmCallback, rejectCallback = null) {
      this.showConfirmPopup = true;
      this.confirmCallback = confirmCallback;
      this.rejectCallback = rejectCallback;
    },

    /**
     * Confirm action.
     */
    async confirm() {
      this.loading = true;
      try {
        await this.confirmCallback();
        this.showConfirmPopup = false;
        this.$emit('confirmed');
      } finally {
        this.loading = false;
      }
    },

    /**
     * Reject action.
     */
    async reject() {
      this.showConfirmPopup = false;
      if (this.rejectCallback) {
        await this.rejectCallback();
      }
    },
  },
};
</script>
