import actions from './actions';
import mutations from './mutations';

export default {
    namespaced: true,
    state: {
        hasChanges: false,
        saving: false,
        showHeaderEditorMenu: false,
    },
    actions,
    mutations,
    getters: {},
};
