import axios from 'axios';

const api = axios.create({
    baseURL: import.meta.env.VITE_CABINET_API_BASE_URL,
    withCredentials: true,
});

export function responseHandler(response) {
    return response ? response.data : undefined;
}

export default api;

