<template>
  <v-navigation-drawer
      v-model="drawer"
      ref="drawer"
      :width="width"
      :elevation="elevation"
      :expand-on-hover="expandOnHover"
      :rail="rail"
      :rail-width="railWidth"
      floating
      color="background"
      @update:rail="railChange"
  >
    <v-list>
      <v-list-item
          title="SocLink"
      >
        <template v-slot:prepend>
          <v-avatar :image="logo_png" tile/>
        </template>
        <template v-slot:append>
          <v-radio
              v-if="!isMobile"
              v-model="drawerCheckbox"
              hide-details
              @click="drawerCheckbox = !drawerCheckbox"
          />
        </template>

      </v-list-item>
    </v-list>

    <v-list density="compact" nav>
      <template v-for="(item, index) in items">
        <v-list-item
            v-if="$can('get', 'page_' + item.route)"
            v-bind:key="'menu_item_' + index"
            :prepend-icon="item.icon"
            :title="$t(item.title)"
            :to="{name: item.route}"
        />
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import logo_png from '@cabinet/assets/logo.png';
import ROUTE_NAMES from "@cabinet/router/routeNames.js";
import { items } from "@cabinet/components/navigation/navigationItems.js";
export default {
  name: 'Navigation',
  data() {
    return {
      drawer: true,
      drawerCheckbox: true,
      showMenu: false,
      expanded: false,
      width: 250,
      routesNames: ROUTE_NAMES,
      items,
      logo_png,
    };
  },
  computed: {
    elevation() {
      return !this.drawerCheckbox && this.expanded ? 4 : 0;
    },
    expandOnHover() {
      if (this.isMobile && this.drawer) {
        return false;
      }
      return !this.drawerCheckbox;
    },
    rail() {
      if (this.isMobile && this.drawer) {
        return true;
      }
      return !this.drawerCheckbox;
    },
    railWidth() {
      if (this.isMobile && this.drawer) {
        return this.width;
      }
    },
    isMobile() {
      return this.$vuetify.display.mobile;
    },
    absolute() {
      return this.isMobile && this.drawer;
    },
  },
  mounted() {
    if (this.isMobile) {
      this.drawer = false;
    }
  },
  methods: {
    showDrawer() {
      this.drawer = true;
    },
    railChange(value) {
      this.expanded = !value;
    }
  }
}
</script>
