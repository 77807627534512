import api from './api';

export default {
    /**
     * Create new employee
     *
     * @return Promise<Object>
     */
    createEmployee(data) {
        return api
            .post('/api/employees', data)
            .then(response => response.data);
    },

    /**
     * Update employee
     *
     * @return Promise<Object>
     */
    saveEmployee(id, data) {
        return api
            .put('/api/employees/' + id, data)
            .then(response => response.data);
    },

    /**
     * Delete employee
     *
     * @return Promise<Object>
     */
    deleteEmployee(id) {
        return api
            .delete('/api/employees/' + id)
            .then(response => response.data);
    },
    
    /**
     * Get list of employees for schedule employees list.
     *
     * @return Promise<Object>
     */
    getEmployees() {
        return api
            .get('/api/employees')
            .then(response => response.data);
    },
    
    /**
     * Get list of employees for schedule employees list.
     *
     * @return Promise<Object>
     */
    getEmployeesScheduleList() {
        return api
            .get('/api/employees/schedule-list')
            .then(response => response.data);
    },

    /**
     * Get employee's Services.
     *
     * @return Promise<Object>
     */
    getEmployeeServices(employeeId) {
        return api
            .get('/api/employees/' + employeeId + '/services')
            .then(response => response.data);
    },

    /**
     * Save employee's Services.
     *
     * @return Promise<Object>
     */
    saveEmployeeServices(employeeId, services) {
        return api
            .post('/api/employees/' + employeeId + '/services', {services})
            .then(response => response.data);
    },

}