<template>
  <vue3-date-range-picker
    ref="picker"
    v-model:date-range="dateRange"
    :timePicker="false"
    :ranges="ranges"
    :show-dropdowns="true"
    :opens="position"
    :disabled="disabled"
    :locale-data="localeData"
    control-container-class=""
    @select="dateRange = $event"
    @update:modelValue="applyDates"
    @toggle="toggle"
  >
    <template v-slot:input>
      <v-text-field
        v-model="dateRangeText"
        :disabled="disabled"
        readonly
        style="min-width: 250px; max-width: 250px"
        :hide-details="hideDetails"
        :error-messages="errorMessages"
        :label="label"
        :loading="loading"
        :class="classString"
        variant="outlined"
        prepend-inner-icon="mdi-calendar"
      >
      </v-text-field>
    </template>

    <template
      v-slot:footer="data"
    >
      <div class="date-range-picker-footer">
        <v-btn
            v-if="clearable"
            variant="tonal"
            @click="clear(data)"
        >{{ $t('clear') }}</v-btn>
        <v-spacer />
        <v-btn
            variant="tonal"
            color="primary"
            @click="apply(data)"
        >{{ $t('apply') }}</v-btn>
      </div>
      
    </template>
  </vue3-date-range-picker>
</template>

<script>
import moment from 'moment';
import Vue3DateRangePicker from 'vue3-daterange-picker';

/**
 * Display DateRangePicker.
 */
export default {
  name: 'DateRangePickerWithPeriods',

  components: {
    Vue3DateRangePicker,
  },
  props: {
    value: {
      type: Array,
    },
    position: {
      type: String,
      default: 'center',
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    errorMessages: {
      type: Array,
      default() {
        return [];
      },
    },
    periods: {
      type: Object,
      required: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: 'Date Range',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    classString: {
      type: String,
      required: false,
    }
  },
  data: () => ({
    /**
     * Dates range.
     */
    dates: null,
    datesTmp: null,
    applied: false,
    cancelled: false,
  }),

  computed: {
    localeData() {
      return {
        firstDay: 1,
        daysOfWeek: [
          this.$t('dateRangePicker.dayOfWeek.sun'),
          this.$t('dateRangePicker.dayOfWeek.mon'),
          this.$t('dateRangePicker.dayOfWeek.tue'),
          this.$t('dateRangePicker.dayOfWeek.wed'),
          this.$t('dateRangePicker.dayOfWeek.thu'),
          this.$t('dateRangePicker.dayOfWeek.fri'),
          this.$t('dateRangePicker.dayOfWeek.sat'),
        ],
        monthNames: [
          this.$t('dateRangePicker.monthNames.jan'),
          this.$t('dateRangePicker.monthNames.feb'),
          this.$t('dateRangePicker.monthNames.mar'),
          this.$t('dateRangePicker.monthNames.apr'),
          this.$t('dateRangePicker.monthNames.may'),
          this.$t('dateRangePicker.monthNames.jun'),
          this.$t('dateRangePicker.monthNames.jul'),
          this.$t('dateRangePicker.monthNames.aug'),
          this.$t('dateRangePicker.monthNames.sep'),
          this.$t('dateRangePicker.monthNames.oct'),
          this.$t('dateRangePicker.monthNames.nov'),
          this.$t('dateRangePicker.monthNames.dec'),
        ],
      };
    },
    
    dateRange: {
      get() {
        if (this.dates) {
          return this.dates;
        }
        const dates = {
          startDate: null,
          endDate: null,
        };
        if (this.value && this.value.length) {
          dates.startDate = moment(this.value[0]).toDate();
          if (this.value.length === 2) {
            dates.endDate = moment(this.value[1]).toDate();
          }
        }
        return dates;
      },
      set(value) {
        this.dates = value;
      },
    },

    ranges() {
      if (this.periods !== undefined) {
        return this.periods;
      }
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const yesterday = moment().subtract(1, 'days').toDate();

      let ranges = {};
      ranges[this.$t('dateRangePicker.ranges.today')] = [today, today];
      ranges[this.$t('dateRangePicker.ranges.yesterday')] = [yesterday, yesterday];
      ranges[this.$t('dateRangePicker.ranges.lastSevenDays')] = [moment().subtract(6, 'days').toDate(), today];
      ranges[this.$t('dateRangePicker.ranges.monthToDate')] = [moment().startOf('month').toDate(), today];
      ranges[this.$t('dateRangePicker.ranges.previousMonth')] = [
        moment().subtract(1, 'months').startOf('month').toDate(),
        moment().subtract(1, 'months').endOf('month').toDate(),
      ];
      
      return ranges;
    },

    /**
     * Date range input text.
     */
    dateRangeText() {
      const { startDate, endDate } = this.dateRange;

      if (!startDate) {
        return '';
      }
      
      const startDateFiltered = this.formatDate(startDate);
      const endDateFiltered = endDate && this.formatDate(endDate);

      if (endDateFiltered) {
        return `${startDateFiltered} - ${endDateFiltered}`;
      }

      return `${startDateFiltered}`;
    },
  },

  methods: {
    formatDate(dateTime) {
      return moment.parseZone(dateTime)
          .format(this.$t('dateFormat'));
    },
    
    /**
     * Cancel date time range picker.
     */
    clear(data) {
      this.dates = null;
      data.clickCancel();
      this.$emit('update:value', null);
    },
    /**
     * Emit dates values.
     */
    applyDates() {
      const dates = [];
      if (this.dates && this.dates.startDate) {
        dates.push(moment.parseZone(this.dates.startDate).format('YYYY-MM-DD'));
        if (this.dates.endDate) {
          dates.push(moment.parseZone(this.dates.endDate).format('YYYY-MM-DD'));
        }
      }
      this.$emit('update:value', dates);
      this.$emit('change');
    },
    apply(data) {
      this.applied = true;
      data.clickApply();
    },
    /**
     * Calls when datepicker opens/closes.
     */
    toggle(isOpened) {
      if (isOpened) {
        this.applied = false;
        this.cancelled = false;
      } else if (!this.applied && this.dates) {
        this.applyDates();
      }
      if (!isOpened) {
        this.$emit('closed', this.applied, this.cancelled);
      }
    },
  },
};
</script>

<style>
.date-range-picker-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  border-top: thin solid #eee;
  padding: 5px;
}
</style>
