<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <v-btn
          color="primary"
          v-bind="props"
          :disabled="unauthorizing"
      >
        <template v-if="!isMini">{{ user.name }}</template>
        <v-avatar
            v-if="isMini && user.avatarUrl"
            color="info"
            size="25"
        >
          <v-img
              :src="user.avatarUrl"
          />
        </v-avatar>
        <v-icon
            v-if="isMini && !user.avatarUrl"
            icon="mdi-account-circle"
            size="25"
        />
        <template v-if="!isMini" v-slot:append>
          <v-avatar
              v-if="user.avatarUrl"
              color="info"
              size="25"
          >
            <v-img
                :src="user.avatarUrl"
            />
          </v-avatar>
          <v-icon
              v-else
              icon="mdi-account-circle"
              size="25"
          />
        </template>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
          v-for="(item, index) in items"
          :key="index"
          :value="index"
          @click="item.action"
      >
        <template v-slot:prepend>
          <v-icon>{{ item.icon }}</v-icon>
        </template>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import auth from "@cabinet/api/auth.js";
import {SET_INITIALIZING} from "@cabinet/store/mutations.js";
import ROUTE_NAMES from "@cabinet/router/routeNames.js";
import {INIT} from "@cabinet/store/actions.js";
export default {
  name: 'ProfileMenu',
  props: {
    small: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      unauthorizing: false,
    };
  },
  computed: {
    items() {
      return [
        {
          title: this.$t('profileMenu.logout'),
          action: this.logout,
          icon: 'mdi-logout'
        },
        {
          title: this.$t('profileMenu.profile'),
          action: this.goToProfileSettings,
          icon: 'mdi-account-circle'
        },
      ]
    },
    isMobile() {
      return this.$vuetify.display.mobile;
    },
    isMini() {
      return this.isMobile || this.small;
    },
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    async logout() {
      this.unauthorizing = true;
      try {
        await auth.logout();
        this.$store.commit(SET_INITIALIZING, true);
        const route = this.$router.resolve({ name: ROUTE_NAMES.LOGIN });
        window.location.replace(route.href);
      } finally {
        this.unauthorizing = false;
      }
    },
    goToProfileSettings() {
      this.$router.push({ name: ROUTE_NAMES.SETTINGS, hash: '#profile' });
    },
  }
}
</script>
