import api from './api';

export default {
    /**
     * Get notifications.
     *
     * @return Promise<Object>
     */
    getNotifications(page, perPage, firstPageIds) {
        return api
            .get('/api/notifications', {
                params: {
                    page,
                    perPage,
                    firstPageIds,
                },
            })
            .then(response => response.data);
    },

    /**
     * Delete notification.
     *
     * @return Promise<Object>
     */
    deleteNotification(id) {
        return api
            .delete('/api/notifications/' + id)
            .then(response => response.data);
    },
    
    /**
     * Get total unread
     *
     * @return Promise<Object>
     */
    getTotalUnread() {
        return api
            .get('/api/notifications/total-unread')
            .then(response => response.data.unread);
    },
    
    /**
     * Get Subscriptions data
     *
     * @return Promise<Object>
     */
    getSubscriptionsData() {
        return api
            .get('/api/notifications/subscriptions-data')
            .then(response => response.data);
    },

    /**
     * Unsubscribe telegram subscription.
     *
     * @return Promise<Object>
     */
    unsubscribeTelegram() {
        return api
            .post('/api/notifications/telegram/unsubscribe')
            .then(response => response.data);
    },

    /**
     * Subscribe WebPush.
     *
     * @return Promise<Object>
     */
    subscribeWebPush(subscriptionData) {
        return api
            .post('/api/notifications/web-push/subscribe', subscriptionData)
            .then(response => response.data);
    },

    /**
     * Unsubscribe WebPush.
     *
     * @return Promise<Object>
     */
    unsubscribeWebPush(subscriptionData) {
        return api
            .post('/api/notifications/web-push/unsubscribe', subscriptionData)
            .then(response => response.data);
    },
}