import api from './api';

export default {
    /**
     * Save Profile
     *
     * @return Promise<Object>
     */
    saveProfile(data) {
        return api
            .put('/api/profile', data)
            .then(response => response.data);
    },
    
    /**
     * Get Profile
     *
     * @return Promise<Object>
     */
    getProfile() {
        return api
            .get('/api/profile')
            .then(response => response.data);
    },

    /**
     * Get Unverified Profile
     *
     * @return Promise<Object>
     */
    getUnverifiedProfile() {
        return api
            .get('/api/profile-unverified')
            .then(response => response.data);
    },


    
    /**
     * Get Profile
     *
     * @return Promise<Object>
     */
    setLocale(locale) {
        return api
            .post('/api/profile/locale', {
                locale,
            })
            .then(response => response.data);
    },
}