import ROUTE_NAMES from "@cabinet/router/routeNames.js";

export const items = [
    {
        title: 'navigation.statistics',
        route: ROUTE_NAMES.STATISTICS,
        icon: 'mdi-chart-line',
    },
    {
        title: 'navigation.myPages',
        route: ROUTE_NAMES.PAGES,
        icon: 'mdi-file-account-outline',
    },
    {
        title: 'navigation.appointments',
        route: ROUTE_NAMES.APPOINTMENTS,
        icon: 'mdi-calendar-check-outline',
    },
    {
        title: 'navigation.schedule',
        route: ROUTE_NAMES.SCHEDULE,
        icon: 'mdi-calendar-multiselect-outline',
    },
    {
        title: 'navigation.employees',
        route: ROUTE_NAMES.EMPLOYEES,
        icon: 'mdi-account-group-outline',
    },
    {
        title: 'navigation.services',
        route: ROUTE_NAMES.SERVICES,
        icon: 'mdi-toolbox-outline',
    },
    {
        title: 'navigation.myDomains',
        route: ROUTE_NAMES.DOMAINS,
        icon: 'mdi-web',
    },
    {
        title: 'navigation.backCalls',
        route: ROUTE_NAMES.BACK_CALLS,
        icon: 'mdi-phone-outgoing-outline',
    },
    {
        title: 'navigation.settings',
        route: ROUTE_NAMES.SETTINGS,
        icon: 'mdi-cog',
    },
]