<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <v-btn
          color="primary"
          v-bind="props"
          :disabled="loading"
      >
        <template v-if="!isMini" v-slot:prepend>
          <country-flag-esm
              :country="currentItem.country"
              size="normal"
              style="margin-top: -10px"
          />
        </template>
        <country-flag-esm
            v-if="isMini"
            :country="currentItem.country"
            size="normal"
            style="margin-top: -10px"
        />
        <template v-if="!isMini">{{ currentItem.title }}</template>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
          v-for="(item, index) in items"
          :key="index"
          :value="index"
          @click="selectLanguage(item)"
      >
        <template v-slot:prepend>
          <country-flag-esm
              :country="item.country"
              size="normal"
              class="mr-1"
              style="margin-top: -10px"
          />
        </template>

        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import CountryFlagEsm from "vue-country-flag-next";
import user from "@cabinet/api/user.js";
export default {
  name: 'Language',
  components: {
    CountryFlagEsm,
  },
  props: {
    authorized: {
      type: Boolean,
      required: true,
    },
    small: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      items: [
        { title: 'Русский', country: 'ru', locale: 'ru'},
        { title: 'English', country: 'us', locale: 'en'},
      ],
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.mobile;
    },
    isMini() {
      return this.isMobile || this.small;
    },
    itemsByLocale() {
      const itemsByLocale = {};
      this.items.forEach(item => {
        itemsByLocale[item.locale] = item;
      });
      return itemsByLocale;
    },
    currentItem() {
      let locale = this.$vuetify.locale.current;
      if (!Object.prototype.hasOwnProperty.call(
          this.itemsByLocale,
          locale,
      )) {
        locale = 'en';
      }
      return this.itemsByLocale[locale];
    },
  },
  methods: {
    async selectLanguage(item) {
      this.$vuetify.locale.current = item.locale;
      this.$cookies.set('locale', item.locale);
      if (this.authorized) {
        try {
          this.loading = true;
          await user.setLocale(item.locale);
        } finally {
          this.loading = false;
        }
        
      }
    }
  }
}
</script>
