<template>
  <v-dialog
      v-model="dialog"
      max-width="500"
      persistent
  >
    <dialog-loading v-if="loading"/>
    <v-card
        v-else
        min-height="350"
    >
      <v-card-title>{{ $t('services.chooseEmployees') }}</v-card-title>
      <v-divider />
      <v-card-text>
        <v-alert
            v-if="selectedEmployeeIds.length === 0"
            class="mb-2"
            type="info"
            density="compact"
            variant="tonal"
        >
          {{ $t('services.servicesHint') }}
        </v-alert>
        
        <v-alert
            v-model="errorAlert"
            class="mb-2"
            type="warning"
            density="compact"
            closable
        >
          {{ errorMessage }}
        </v-alert>
        
        <v-checkbox
            v-for="employee in employees"
            v-model="employee.enabled"
            :label="employee.name"
            :key="'employee_checkbox_' + employee.id"
            hide-details
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
            @click="dialog = false;"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
            :loading="submitting"
            variant="tonal"
            @click="save"
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import parseErrorsMixins from "@cabinet/mixins/parseErrorsMixins.js";
import servicesApi from "@cabinet/api/services.js";
import DialogLoading from "@cabinet/components/DialogLoading.vue";
export default {
  name: 'ChooseEmployeesDialog',
  components: {DialogLoading},
  mixins: [parseErrorsMixins],
  data() {
    return {
      dialog: false,
      loading: false,
      serviceId: null,
      employees: [],
      submitting: false,
    };
  },
  computed: {
    selectedEmployeeIds() {
      const enabledEmployees = [];
      this.employees.forEach(employee => {
        if (employee.enabled) {
          enabledEmployees.push(employee.id);
        }
      });
      return enabledEmployees;
    }
  },
  methods: {
    async show(service) {
      this.loading = true;
      this.dialog = true;
      this.errors = {};
      this.serviceId = service.id;
      const promises = [
          this.fetchServiceEmployees(service.id),
      ];
      try {
        await Promise.all(promises);
      } finally {
        this.loading = false;
      }
    },
    async fetchServiceEmployees(serviceId) {
      this.employees = await servicesApi.getServiceEmployees(serviceId);
    },
    hide() {
      this.dialog = false;
    },
    async save() {
      this.hideError();
      try {
        this.submitting = true;
        await servicesApi.saveServiceEmployees(this.serviceId, this.prepareDataToSubmit());
        this.$emit('saved');
        this.dialog = false;
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.submitting = false;
      }
    },
    
    prepareDataToSubmit() {
      const enabledEmployees = [];
      this.employees.forEach(employee => {
        if (employee.enabled) {
          enabledEmployees.push(employee.id);
        }
      });
      return enabledEmployees;
    }
  }
}
</script>
<style>

</style>