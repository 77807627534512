<template>
  <v-card flat>
    <v-tabs
        v-model="tab"
        color="primary"
        direction="horizontal"
        class="mr-4"
    >
      <v-tab value="profile">
        <v-icon start>
          mdi-account-circle
        </v-icon>
        {{ $t('settings.tabProfile') }}
      </v-tab>
      <v-tab value="notifications">
        <v-icon start>
          mdi-bell-outline
        </v-icon>
        {{ $t('settings.tabNotifications') }}
      </v-tab>
    </v-tabs>
    <v-divider/>
    <v-window v-model="tab" style="width: 100%">
      <v-window-item value="profile">
        <profile />
      </v-window-item>
      <v-window-item value="notifications">
        <notifications />
      </v-window-item>
    </v-window>
  </v-card>
</template>

<script>
import checkAuthorizedMixins from "@cabinet/mixins/checkAuthorizedMixins.js";
import Profile from "@cabinet/components/settings/Profile.vue";
import Notifications from "@cabinet/components/settings/Notifications.vue";
export default {
  name: 'Settings',
  components: {Notifications, Profile},
  mixins: [
      checkAuthorizedMixins,
  ],
  data() {
    return {
      tab: 'profile',
    };
  },
  computed: {
    
  },
  mounted() {
    let hash = window.location.hash;
    if (hash) {
      hash = hash.slice(1);
      this.tab = hash;
    }
  },
}
</script>
