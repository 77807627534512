export const SET_HAS_CHANGES = 'SET_HAS_CHANGES';
export const SET_SAVING = 'SET_SAVING';
export const SET_SHOW_HEADER_EDITOR_MENU = 'SET_SHOW_HEADER_EDITOR_MENU';

export default {
    /**
     * Set hasChanges value.
     *
     * @param {Object} state - State instance.
     * @param {Boolean} value - Values of hasChanges
     */
    [SET_HAS_CHANGES]: (state, value) => {
        state.hasChanges = value;
    },

    /**
     * Set saving value.
     *
     * @param {Object} state - State instance.
     * @param {Boolean} value - Values of saving
     */
    [SET_SAVING]: (state, value) => {
        state.saving = value;
    },

    /**
     * Set headerEditorMenu value.
     *
     * @param {Object} state - State instance.
     * @param {Boolean} value - Values of saving
     */
    [SET_SHOW_HEADER_EDITOR_MENU]: (state, value) => {
        state.showHeaderEditorMenu = value;
    },
};
