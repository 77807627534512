<template>
  <div>
    <div class="d-flex flex-wrap">
      <date-range-picker-with-periods
          v-model:value="filters.dateRange"
          position="right"
          :label="$t('statistics.dates')"
          class-string="flex-grow-1 mb-3 mr-3"
      />
      <v-select
          v-model="filters.pageUuid"
          :items="pages"
          item-value="uuid"
          item-title="name"
          :return-object="false"
          :label="$t('statistics.page')"
          :no-data-text="$t('statistics.noPages')"
          variant="outlined"
          style="max-width: 250px; min-width: 250px;"
          clearable
          hide-details
          class="flex-grow-1 mb-3"
      />
    </div>
    
    <v-card :loading="visitorsLoading">
      <v-card-title>
        Статистика посещений
      </v-card-title>
      <vue-apex-charts type="line" height="350" :options="visitorsChartOptions" :series="visitorsSeries" />
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import VueApexCharts from 'vue3-apexcharts';
import statistics from "@cabinet/api/statistics.js";
import DateRangePickerWithPeriods from "@cabinet/components/DateRangePickerWithPeriods.vue";
import pages from "@cabinet/api/pages.js";

export default {
  name: 'Statistics',
  components: {
    DateRangePickerWithPeriods,
    VueApexCharts,
  },
  data() {
    return {
      loadingPages: false,
      pages: [],
      visitors: null,
      visitorsLoading: false,
      filters: {
        dateRange: null,
        pageUuid: null,
      },
    };
  },
  computed: {
    visitorsSeries() {
      const views = {
        name: this.$t('statistics.views'),
        data: [],
      }
      const unique = {
        name: this.$t('statistics.uniques'),
        data: [],
      }
      if (this.visitors) {
        _.forEach(this.visitors, (item, date) => {
          views.data.push(item.views);
          unique.data.push(item.unique);
        });
      }
      return [views, unique];
    },
    visitorsChartOptions() {
      const options = {
        chart: {
          toolbar: {
            show: false,
          },
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          width: 2,
        },
        title: {
          align: 'left'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: [],
        }
      };
      if (this.visitors) {
        _.forEach(this.visitors, (item, date) => {
          options.xaxis.categories.push(this.formatDate(date));
        });
      }
      return options;
    }
  },
  watch: {
    filters: {
      handler() {
        this.fetchStatistics();
      },
      deep: true,
    }
  },
  mounted() {
    const today = moment().format('YYYY-MM-DD');
    const sevenDaysAgo = moment().subtract(7, 'days').format('YYYY-MM-DD');
    this.filters.dateRange = [
      sevenDaysAgo,
      today
    ];
    this.fetchPages();
    this.fetchStatistics();
  },
  methods: {
    async fetchPages() {
      this.loadingPages = true;
      try {
        const response = await pages.getPages();
        this.pages = response.data;
      } finally {
        this.loadingPages = false;
      }
    },
    async fetchStatistics() {
      this.fetchVisitors();
    },
    async fetchVisitors() {
      try {
        this.visitorsLoading = true;
        this.visitors = await statistics.getVisitors(this.filters);
      } finally {
        this.visitorsLoading = false;
      }
    },
    formatDate(dateTime) {
      return moment.parseZone(dateTime)
          .format(this.$t('dateFormat'));
    },
  },
}
</script>
