import noAuthRoutes from "@cabinet/router/noAuthRoutes.js";
import ROUTE_NAMES from "@cabinet/router/routeNames.js";
import {SET_INITIALIZING} from "@cabinet/store/mutations.js";
import {INIT} from "@cabinet/store/actions.js";

export default {
    registerInterceptors(api, router, store) {
        /**
         * When error status of api request is 401,
         * and if url required authentication, then redirect to login page.
         * @param {Object} err - error object from axios
         */
        const error = async (err) => {
            if (
                err.response
                && (err.response.status === 401
                    || err.response.status === 419
                )
                && !noAuthRoutes[router.currentRoute.value.name]
            ) {
                const route = router.resolve({ name: ROUTE_NAMES.LOGIN });
                window.location.replace(route.href);
                const delay = ms => new Promise(res => setTimeout(res, ms));
                await delay(5000);
                return Promise.reject(err);
            } else if (
                err.response
                && err.response.status === 403
                && err.response.data
                && err.response.data.code === 'EMAIL_IS_NOT_VERIFIED'
            ){
                router.push({ name: ROUTE_NAMES.EMAIL_VERIFY });
            } else {
                return Promise.reject(err);
            }
        };
        api.interceptors.response.use(response => response, error);
    }
}