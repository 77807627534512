<template>
  <v-menu
      v-model="isDateMenuOpen"
      :close-on-content-click="false"
  >
    <template v-slot:activator="{ props }">
      <v-text-field
          :label="$t('date')"
          :rules="rules"
          :hide-details="hideDetails"
          :style="maxWidth ? 'max-width:' + maxWidth + 'px;' : null"
          v-model="formattedDate"
          readonly
          v-bind="props"
          variant="outlined"
          
      >
        <template v-slot:prepend-inner>
          <v-icon>mdi-calendar-month-outline</v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker v-model="selectedDate" hide-header>
      <template v-slot:header></template>
      <template v-slot:actions>
        <v-btn @click="selectedDate = null">{{ $t('clear') }}</v-btn>
      </template>
    </v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";
export default {
  name: 'DatePicker',
  props: {
    modelValue: {
      type: String,
      required: false,
      default: null,
    },
    rules: {
      type: Array,
      required: false,
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: true,
    },
    maxWidth: {
      type: Number,
      required: false,
      default: null,
    }
  },
  data() {
    return {
      isDateMenuOpen: false,
      selectedDate: this.modelValue ? new Date(this.modelValue) : null,
    };
  },
  computed: {
    formattedDate() {
      if (!this.selectedDate) {
        return null;
      }
      const locale = this.$vuetify.locale.current;
      return this.selectedDate
          ? this.selectedDate.toLocaleDateString(locale)
          : "";
    },
  },
  watch: {
    selectedDate() {
      this.isDateMenuOpen = false;
      let formattedDate = null;
      if (this.selectedDate) {
        formattedDate = moment(this.selectedDate).format('YYYY-MM-DD');
      }
      this.$emit('update:modelValue', formattedDate);
    },
  },
  methods: {

  },
}
</script>
