<template>
  <v-dialog
      v-model="dialog"
      max-width="500"
      class="add-domain-dialog"
  >
    <v-alert
        v-model="errorAlert"
        class="mb-2"
        type="warning"
        density="compact"
        closable
    >
      {{ errorMessage }}
    </v-alert>
    <v-card
        min-height="350"
    >
      <v-card-title>{{ $t('domains.addDomain') }}</v-card-title>
      <v-divider />
      <v-card-text>
        <v-form
            ref="addDomainForm"
            @submit.prevent="createDomain"
        >
          <v-text-field
              v-model="submitData.domain"
              :label="$t('domains.domain')"
              :maxlength="63"
              :rules="rules.domainValidation"
              :error-messages="errors.domain"
          />
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
            @click="dialog = false;"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
            :loading="submitting"
            variant="tonal"
            @click="createDomain"
        >
          {{ $t('add') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import parseErrorsMixins from "@cabinet/mixins/parseErrorsMixins.js";
import domains from "@cabinet/api/domains.js";
import {compose, ruleRequired, ruleDomain} from "@cabinet/utils/validationRules.js";
export default {
  name: 'AddDomainDialog',
  mixins: [parseErrorsMixins],
  data() {
    return {
      dialog: false,
      submitData: {
        domain: null,
      },
      submitting: false,
    };
  },
  computed: {
    rules() {
      return compose({
        domainValidation: [
          ruleRequired(),
          ruleDomain(),
        ],
      });
    },
  },
  methods: {
    show() {
      this.dialog = true;
      this.submitData = {
        domain: null,
      };
    },
    hide() {
      this.dialog = false;
    },
    async createDomain() {
      this.hideError();
      const validate = await this.$refs.addDomainForm.validate()
      if (!validate.valid) {
        return;
      }
      try {
        this.submitting = true;
        await domains.createDomain(this.submitData);
        this.$emit('created');
        this.dialog = false;
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.submitting = false;
      }
    },
  }
}
</script>
<style>
.add-domain-dialog {
  
}
</style>