import api from './api';

export default {
    /**
     * Get Designs
     *
     * @return Promise<Object>
     */
    getDesigns() {
        return api
            .get('/api/designs')
            .then(response => response.data);
    },

    /**
     * Get Design Variants
     *
     * @return Promise<Object>
     */
    getVariants(designId) {
        return api
            .get('/api/designs/' + designId + '/variants')
            .then(response => response.data);
    },
}