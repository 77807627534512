import api from "@cabinet/api/api.js";
import user from "@cabinet/api/user.js";
import {
    SET_INITIALIZING,
    SET_USER,
} from "@cabinet/store/mutations.js";
import centrifuge from "@cabinet/plugins/centrifuge.js";


export const INIT = 'INIT';
export default {
    /**
     * Init application
     */
    [INIT]: async ({commit, getters, state, dispatch}) => {
        commit(SET_INITIALIZING, true);
        commit(SET_USER, null);
        try {
            await api.get('sanctum/csrf-cookie');
            const userData = await user.getProfile();
            commit(SET_USER, userData.data);
            centrifuge.subscribeUsersChannel(userData.data.id);
        } catch (e) {
            commit(SET_USER, null);
        } finally {
            commit(SET_INITIALIZING, false);
        }
    },
}