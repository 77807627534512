import api from './api';

export default {
    /**
     * Create service.
     *
     * @return Promise<Object>
     */
    createService(data) {
        return api
            .post('/api/services', data)
            .then(response => response.data);
    },

    /**
     * Save service.
     *
     * @return Promise<Object>
     */
    saveService(serviceId, data) {
        return api
            .put('/api/services/' + serviceId, data)
            .then(response => response.data);
    },

    /**
     * Get service.
     *
     * @return Promise<Object>
     */
    getService(serviceId) {
        return api
            .get('/api/services/' + serviceId)
            .then(response => response.data);
    },

    /**
     * Get Services.
     *
     * @return Promise<Object>
     */
    getServices() {
        return api
            .get('/api/services')
            .then(response => response.data);
    },
    
    /**
     * Delete service.
     *
     * @return Promise<Object>
     */
    deleteService(id) {
        return api
            .delete('/api/services/' + id)
            .then(response => response.data);
    },

    /**
     * Get service employees.
     *
     * @return Promise<Object>
     */
    getServiceEmployees(serviceId) {
        return api
            .get('/api/services/' + serviceId + '/employees')
            .then(response => response.data);
    },

    /**
     * Save service's employees.
     *
     * @return Promise<Object>
     */
    saveServiceEmployees(serviceId, employees) {
        return api
            .post('/api/services/' + serviceId + '/employees', {employees})
            .then(response => response.data);
    },
}