<template>
  <div>
    <v-tabs
        v-model="tab"
        color="primary"
        direction="horizontal"
        class="mr-4"
    >
      <v-tab value="content">
        <v-icon start>
          mdi-content-paste
        </v-icon>
        {{ $t('editor.content') }}
      </v-tab>
      <v-tab value="design" :disabled="!designEnabled">
        <v-icon start>
          mdi-palette-outline
        </v-icon>
        {{ $t('editor.design') }}
      </v-tab>
    </v-tabs>
    <v-divider />
    <div style="max-height: calc(100vh - 200px); overflow-y: scroll">
      <v-sheet class="pt-4 pl-4 pr-4">
        <slot name="common"></slot>
      </v-sheet>
      <v-window v-model="tab" style="width: 100%">
        <v-window-item value="content">
          <v-sheet class="pb-4 pl-4 pr-4">
            <slot name="content"></slot>
          </v-sheet>
        </v-window-item>
        <v-window-item value="design">
          <v-sheet class="pb-4 pl-4 pr-4">
            <slot name="design"></slot>
          </v-sheet>
        </v-window-item>
      </v-window>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CommonBlock',
  props: {
    designEnabled: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      tab: 'content',
    };
  },
}
</script>