<template>
  <common-block>
    <template v-slot:common>
      {{ $t('editor.noAvailableSettings') }}
    </template>
    <template v-slot:content>

    </template>
    <template v-slot:design>

    </template>
  </common-block>
</template>
<script>
import blockMixin from "@cabinet/components/editor/blocks/blockMixin.js";

export default {
  name: 'BackCall_1',
  mixins: [blockMixin],
}
</script>