import { Centrifuge } from 'centrifuge'
import api from "@cabinet/api/api.js";
import {eventBus} from "@cabinet/utils/eventBus.js";

export default {
    centrifugeInstance: null,
    usersChannelInitializing: null,
    usersChannelSubscription: null,
    
    async connect() {
        if (this.centrifugeInstance) {
            return this.centrifugeInstance;
        }
        const response = await api.get('/api/broadcasting/connection-token');
        const connectionToken = response.data.token;
        const centrifugoHost = import.meta.env.VITE_CENTRIFUGO_HOST;

        const centrifuge = new Centrifuge('wss://' + centrifugoHost + '/connection/websocket', {
            token: connectionToken,
            insecure: true,
        });
        
        centrifuge.on('disconnected', function(ctx) {
            console.log(ctx, 'disconnected');
        });
        centrifuge.on('error', function(ctx) {
            console.log(ctx, 'error');
        });
        
        centrifuge.connect();
        return centrifuge;
    },
    
    async subscribeUsersChannel(userId) {
        if (this.usersChannelInitializing || this.usersChannelSubscription) {
            return;
        }
        this.usersChannelInitializing = true;
        const centrifuge = await this.connect();
        
        const sub = centrifuge.newSubscription('users.' + userId, {
            getToken: async (ctx) => {
                const response = await api.post('/broadcasting/auth', ctx);
                return response.data.token;
            },
        });

        sub.on('publication', function(ctx) {
            eventBus.$emit(ctx.data.event, ctx.data.payload);
        });

        sub.subscribe();
        this.usersChannelSubscription = sub;
        this.usersChannelInitializing = false;
    }
}