<template>
  <div>
    <v-text-field
        v-model="data.title"
        :label="$t('editor.messengers.title')"
        :placeholder="$t('editor.messengers.' + data.type + '.titlePlaceholder')"
    />
    <v-text-field
        v-model="data.username"
        :label="$t('editor.messengers.username')"
    />
  </div>
</template>
<script>
export default {
  name: 'vkontakte',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    };
  },
}
</script>