<template>
  <v-dialog
      v-model="dialog"
      max-width="570"
  >
    <dialog-loading v-if="loading"/>
    <v-card
        v-else
        min-height="350"
    >
      <v-card-title>{{ $t('editor.addBlockDialog.title') }}</v-card-title>
      <v-divider />
      <v-card-text>
        <div class="d-flex flex-wrap justify-center">
          <v-sheet
              v-for="blockType in blockTypes"
              width="150"
              height="130"
              class="block-type mr-2 ml-2 mb-4 text-center"
              elevation="1"
              @click="addBlock(blockType)"
          >
            <div class="mt-4">
              <v-icon size="50">
                {{ Object.prototype.hasOwnProperty.call(blockTypesIcons, blockType.type) ? blockTypesIcons[blockType.type] : '' }}
              </v-icon>
            </div>
            <div>
              {{ $t('blocks.' + blockType.type) }}
            </div>
          </v-sheet>
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
            color="red-btn"
            variant="tonal"
            @click="dialog = false;"
        >
          {{ $t('cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import blocks from "@cabinet/api/blocks.js";
import DialogLoading from "@cabinet/components/DialogLoading.vue";
export default {
  name: 'AddBlockDialog',
  components: {DialogLoading},

  data() {
    return {
      dialog: false,
      loading: false,
      blockTypes: [],
      blockTypesIcons: {
        avatar: 'mdi-account-circle-outline',
        appointment: 'mdi-calendar-check-outline',
        back_call: 'mdi-phone-outgoing-outline',
        divider: 'mdi-dots-horizontal',
        map: 'mdi-map-marker-outline',
        text: 'mdi-text-box-outline',
        messengers: 'mdi-message-text-outline',
        socials: 'mdi-account-group-outline',
        gallery: 'mdi-view-gallery-outline',
      },
    };
  },
  computed: {

  },
  methods: {
    show() {
      this.loading = true;
      this.dialog = true;
      this.blockTypes = [];
      this.loadBlockTypes();
    },
    async loadBlockTypes() {
      this.loading = true;
      try {
        const response = await blocks.getBlockTypes();
        this.blockTypes = response.data;
      } finally {
        this.loading = false;
      }
    },
    addBlock(blockType) {
      if (blockType.blocks.length === 1) {
        const block = _.cloneDeep(blockType.blocks[0].default_data);
        this.$emit('addBlock', block);
        this.dialog = false;
      } else {
        // TODO:: Implement logic
        console.log('Not implemented yet');
      }
    }
  }
}
</script>
<style>
.block-type {
  background-color: #f5f5f5;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px;
  color: #7797c9;
}
.block-type:hover {
  background-color: #ececec;
  color: #3473d2;
}
</style>
