import ROUTE_NAMES from "@cabinet/router/routeNames.js";
import Statistics from "@cabinet/views/Statistics.vue";
import Login from "@cabinet/views/Login.vue";
import Pages from "@cabinet/views/Pages.vue";
import Page from "@cabinet/views/Page.vue";
import PageCreate from "@cabinet/views/PageCreate.vue";
import Settings from "@cabinet/views/Settings.vue";
import BackCalls from "@cabinet/views/BackCalls.vue";
import Registration from "@cabinet/views/Registration.vue";
import EmailVerify from "@cabinet/views/EmailVerify.vue";
import ForgotPassword from "@cabinet/views/ForgotPassword.vue";
import ResetPassword from "@cabinet/views/ResetPassword.vue";
import Domains from "@cabinet/views/Domains.vue";
import Schedule from "@cabinet/views/Schedule.vue";
import Employees from "@cabinet/views/Employees.vue";
import Services from "@cabinet/views/Services.vue";
import Events from "@cabinet/views/Events.vue";
import Main from "@cabinet/views/Main.vue";

const routes = [
    {
        path: '/',
        component: Main,
        name: ROUTE_NAMES.MAIN,
    },
    {
        path: '/statistics',
        component: Statistics,
        name: ROUTE_NAMES.STATISTICS,
    },
    {
        path: '/email/verify',
        component: EmailVerify,
        name: ROUTE_NAMES.EMAIL_VERIFY,
    },
    {
        path: '/login',
        component: Login,
        name: ROUTE_NAMES.LOGIN,
    },
    {
        path: '/registration',
        component: Registration,
        name: ROUTE_NAMES.REGISTRATION,
    },
    {
        path: '/forgot-password',
        component: ForgotPassword,
        name: ROUTE_NAMES.FORGOT_PASSWORD,
    },
    {
        path: '/reset-password/:token',
        component: ResetPassword,
        name: ROUTE_NAMES.RESET_PASSWORD,
    },
    {
        path: '/pages',
        component: Pages,
        name:  ROUTE_NAMES.PAGES,
    },
    {
        path: '/pages/create',
        component: PageCreate,
        name:  ROUTE_NAMES.CREATE_PAGE,
    },
    {
        path: '/pages/:uuid',
        component: Page,
        name:  ROUTE_NAMES.PAGE,
    },
    {
        path: '/domains',
        component: Domains,
        name:  ROUTE_NAMES.DOMAINS,
    },
    {
        path: '/appointments',
        component: Events,
        name:  ROUTE_NAMES.APPOINTMENTS,
    },
    {
        path: '/back-calls',
        component: BackCalls,
        name:  ROUTE_NAMES.BACK_CALLS,
    },
    {
        path: '/settings',
        component: Settings,
        name:  ROUTE_NAMES.SETTINGS,
    },
    {
        path: '/schedule',
        component: Schedule,
        name:  ROUTE_NAMES.SCHEDULE,
    },
    {
        path: '/employees',
        component: Employees,
        name:  ROUTE_NAMES.EMPLOYEES,
    },
    {
        path: '/services',
        component: Services,
        name:  ROUTE_NAMES.SERVICES,
    },
];
export default routes;