<template>
  <div>
    <v-text-field
        v-model="data.title"
        :label="$t('editor.socials.title')"
        :placeholder="$t('editor.socials.' + data.type + '.titlePlaceholder')"
    />
    <v-text-field
        v-model="data.url"
        :label="$t('editor.socials.' + data.type + '.channel')"
    />
  </div>
</template>
<script>
export default {
  name: 'youtube',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    };
  },
}
</script>