const ROUTE_NAMES = {
    MAIN: 'main',
    EMAIL_VERIFY: 'emailVerify',
    LOGIN: 'login',
    REGISTRATION: 'registration',
    FORGOT_PASSWORD: 'forgotPassword',
    RESET_PASSWORD: 'resetPassword',
    STATISTICS: 'statistics',
    PAGES: 'pages',
    PAGE: 'page',
    CREATE_PAGE: 'createPage',
    DOMAINS: 'domains',
    SETTINGS: 'settings',
    BACK_CALLS: 'backCalls',
    SCHEDULE: 'schedule',
    EMPLOYEES: 'employees',
    SERVICES: 'services',
    APPOINTMENTS: 'appointments',
};

export default ROUTE_NAMES;
