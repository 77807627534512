<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="550"
  >
    <v-card>
      <v-card-title>
        {{ $t('page.publish') }}
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-alert
            v-model="errorAlert"
            class="mb-5"
            type="warning"
            density="compact"
            closable
        >
          {{ errorMessage }}
        </v-alert>
        <v-form
            ref="publishForm"
            @submit.prevent="publish"
        >
          <v-radio-group
              v-model="linkType"
              inline
          >
            <v-radio
                :label="$t('page.directory')"
                value="path"
            />
            <v-radio
                :label="$t('page.subdomain')"
                value="subdomain"
            />
            <v-radio
                :label="$t('page.ownDomain')"
                value="domain"
            />
          </v-radio-group>
          
          
          <v-text-field
              v-if="linkType === 'path'"
              v-model="accountModel"
              :label="$t('page.uniqueName')"
              :maxlength="30"
              :rules="rules.accountValidation"
              :error-messages="errors.account"
              :prefix="mainUrl + '/'"
          />

          <v-text-field
              v-if="linkType === 'subdomain'"
              v-model="accountModel"
              :label="$t('page.uniqueName')"
              :maxlength="30"
              :rules="rules.accountValidation"
              :error-messages="errors.account"
              prefix="https://"
              :suffix="'.' + appDomain"
          />
          <div v-if="linkType === 'domain'">
            <v-text-field
                v-model="accountModel"
                :label="$t('page.uniqueName')"
                :maxlength="30"
                :rules="rules.accountValidation"
                :error-messages="errors.account"
                :prefix="domainId && !makeAsMain
                ? (currentDomain.ssl ? 'https' : 'http') + '://' + currentDomain.domain + '/'
                : null"
            />
            
            <v-select
                v-model="domainId"
                :items="domains"
                :item-props="domainItemProps"
                :label="$t('page.domain')"
                :loading="domainsLoading"
                :rules="rules.domainValidation"
                :error-messages="errors.domain_id"
                :return-object="false"
                clearable
                item-value="id"
                item-title="domain"
            />
            <v-checkbox
                v-if="domainId"
                v-model="makeAsMain"
                :label="$t('page.setAsMain')"
            />
          </div>
        </v-form>
      </v-card-text>

      <v-divider />
      <v-card-actions>
        <v-btn
            v-if="page.published"
            :loading="unpublishing"
            :disabled="publishing"
            variant="tonal"
            color="red"
            @click="unpublish"
        >
          <template v-if="isMobile">
            <v-icon>mdi-close</v-icon>
          </template>
          <template v-else>{{ $t('page.unpublish') }}</template>
        </v-btn>
        <v-spacer />
        <v-btn
            :disabled="unpublishing || publishing"
            @click="dialog = false;"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
            :loading="publishing"
            :disabled="unpublishing"
            variant="tonal"
            color="green"
            @click="publish"
        >
          {{ $t('page.publish') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <confirmation ref="confirmation"/>
  </v-dialog>
</template>

<script>
import parseErrorsMixins from "@cabinet/mixins/parseErrorsMixins.js";
import {alphaNumericDash, compose, ruleRequired} from "@cabinet/utils/validationRules.js";
import DialogLoading from "@cabinet/components/DialogLoading.vue";
import Confirmation from "@cabinet/components/Confirmation.vue";
import pages from "@cabinet/api/pages.js";
import pageUrlMixin from "@cabinet/components/page/pageUrlMixin.js";

export default {
  name: 'PublishDialog',
  mixins: [parseErrorsMixins, pageUrlMixin],
  components: {
    Confirmation,
    DialogLoading
  },
  props: {
    uuid: {
      type: String,
      required: true,
    },
    page: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      dialog: false,
      publishing: false,
      unpublishing: false,
      accountModel: this.page.account,
      linkType: null,
      domainId: this.page.domain_id,
    };
  },
  mounted() {
    this.linkType = this.page.domain_id
        ? 'domain'
        : this.page.link_type;
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.mobile;
    },
    mainUrl() {
      return import.meta.env.VITE_SITE_MAIN_URL;
    },
    appDomain() {
      return import.meta.env.VITE_APP_DOMAIN;
    },
    currentDomain() {
      if (!this.domainId) {
        return null;
      }
      const domainIndex = this.domainIdIndexMap[this.domainId];
      return this.domains[domainIndex];
    },
    rules() {
      const domainValidation = [];
      if (this.linkType === 'domain') {
        domainValidation.push(ruleRequired());
      }
      return compose({
        accountValidation: [
          ruleRequired(),
          alphaNumericDash(),
        ],
        domainValidation,
      });
    },
  },
  methods: {
    async show() {
      this.dialog = true;
      this.hideError();
    },
    close() {
      this.dialog = false;
    },
    domainItemProps(item) {
      const itemPrepared = {
        id: item.id,
        domain: item.domain,
      };

      if (!item.resolved) {
        itemPrepared.disabled = true;
        itemPrepared.subtitle = this.$t('page.notLinked');
      }

      return itemPrepared;
    },
    async unpublish() {
      this.$refs.confirmation.showConfirm(
          this.$t('page.unpublishConfirmationTitle'),
          this.$t('page.unpublishConfirmationText'),
          async () => {
            this.unpublishing = true;
            try {
              await pages.unpublish(this.page.uuid);
              this.$emit('unpublished');
              this.dialog = false;
            } finally {
              this.unpublishing = false;
            }
          }
      );
    },
    async publish() {
      this.hideError();
      const validate = await this.$refs.publishForm.validate()
      if (!validate.valid) {
        return;
      }
      try {
        this.publishing = true;
        const publishData = {
          account: this.accountModel,
          link_type: this.linkType === 'domain' ? 'path' : this.linkType,
          domain_id: this.linkType === 'domain' ? this.domainId : null,
          makeAsMain: this.makeAsMain,
        };
        await pages.publish(this.page.uuid, publishData);
        this.$emit('published', publishData);
        this.dialog = false;
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.publishing = false;
      }
    },
  }
}
</script>
