<template>
  <div>
    <v-card
        flat
    >
      <v-card-actions>
        <v-btn @click="goToMyPages">
          <template v-slot:prepend>
            <v-icon>mdi-arrow-left-thin</v-icon>
          </template>
          {{ $t('page.myPages') }}
        </v-btn>
      </v-card-actions>
      <v-divider class="mb-4"/>
      <div class="pl-3 pr-3 pb-5">
        <v-row>
          <v-col :cols="isMobile ? 12 : 6">
            <page-form
                ref="pageForm"
                v-model:saving="saving"
                v-model:page="page"
                @saved="pageSavedHandler"
            />

            <v-toolbar color="white">
              <v-spacer/>
              <v-btn
                  variant="tonal"
                  :loading="saving"
                  @click="createPage"
                  color="primary"
              >
                <template v-slot:prepend>
                  <v-icon>
                    mdi-content-save-outline
                  </v-icon>
                </template>
                {{ $t('page.createPage') }}
              </v-btn>
              <v-spacer/>
            </v-toolbar>
            
            <v-divider v-if="isMobile"/>
          </v-col>
          <v-col :cols="isMobile ? 12 : 6">
            <design-preview
                v-if="page.design_id && page.design_variant_id"
                :key="'design_preview_' + page.design_id + '_' + page.design_variant_id"
                :iframe-id="iframeId"
                :loading="false"
                :design-id="page.design_id"
                :variant-id="page.design_variant_id"
            />
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
import checkAuthorizedMixins from "@cabinet/mixins/checkAuthorizedMixins.js";
import ROUTE_NAMES from "@cabinet/router/routeNames.js";
import DesignPreview from "@cabinet/components/page/DesignPreview.vue";
import PageForm from "@cabinet/components/page/PageForm.vue";

export default {
  name: 'PageCreate',
  components: {PageForm, DesignPreview},
  mixins: [
      checkAuthorizedMixins
  ],
  data() {
    return {
      showChooseDesign: true,
      step: 'design',
      routeNames: ROUTE_NAMES,
      saving: false,
      iframeId: null,
      designs: [],
      page: {
        name: null,
        account: null,
        design_id: null,
        design_variant_id: null,
      },
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.mobile;
    },
  },
  created() {
    this.iframeId = 'preview_' + ((Math.random() + 1).toString(36).substring(7));
  },
  methods: {
    goToMyPages() {
      this.$router.push({ name: ROUTE_NAMES.PAGES });
    },
    pageSavedHandler(page) {
      this.$router.push({name: ROUTE_NAMES.PAGE, params: {uuid: page.uuid}});
    },
    createPage() {
      this.$refs.pageForm.savePageSettings();
    },
  },
}
</script>
<style>

</style>