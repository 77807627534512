<template>
  <v-card
      flat
      class="page-item-phone-container ma-2"
      @click="$emit('click')"
  >
    <v-responsive :aspect-ratio="9 / 15">
      <v-sheet
          width="100%"
          height="100%"
          class="d-flex flex-column justify-center align-center"
      >
        <v-avatar size="40">
          <v-icon width="100">mdi-plus</v-icon>
        </v-avatar>
        <span class="image-description">{{ $t('pages.createPage') }}</span>
      </v-sheet>
    </v-responsive>
    <div class="container-title">
      {{ $t('pages.yourPage') }}
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'AddPageItem',
}
</script>
<style>
.page-item-phone-container {
  width: 200px;
  border: 5px solid #505050;
  border-radius: 15px;
  
  .image-description {
    font-size: 10px;
  }
  
  .container-title {
    position: absolute;
    bottom: -1px;
    text-align: center;
    background-color: rgba(61, 61, 61, 0.7);
    color: #FFFFFF;
    width: 100%;
    line-height: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 10px;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4x);
  }
}
</style>