<template>
  <div>
    <v-card
        v-if="pageModel"
        flat
    >
      <v-card-actions>
        <v-btn @click="goToMyPages">
          <template v-slot:prepend>
            <v-icon>mdi-arrow-left-thin</v-icon>
          </template>
          {{ $t('page.myPages') }}
        </v-btn>
        <v-spacer/>
        <v-btn
            :variant="pageModel.published ? 'tonal' : 'outlined'"
            color="green"
            @click="publish"
        >
          {{ pageModel.published ? $t('page.published') : $t('page.publish') }}
        </v-btn>
      </v-card-actions>
      <v-divider class="mb-4"/>
      <div class="pl-3 pr-3 pb-5">
        <v-row>
          <v-col :cols="isMobile ? 12 : 6">
            <v-toolbar
                density="compact"
                class="mb-4 pl-4"
            >
              <span v-if="!pageModel.published" class="text-red">
                {{ $t('page.notPublished') }}
              </span>
              <a v-if="pageModel.published"
                 :href="initialPageUrl"
                 target="_blank">
                {{ initialPageUrl }}
              </a>
              <v-spacer />
              <v-btn @click="publish">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-toolbar>
            <page-form
                ref="pageForm"
                v-model:saving="saving"
                v-model:page="pageModel"
                v-model:make-as-main="makeAsMain"
                @saved="pageSavedHandler"
            />
            <v-toolbar color="white">
              <v-spacer/>
              <v-btn
                  :loading="saving"
                  :disabled="!isEdited"
                  color="primary"
                  variant="tonal"
                  @click="savePageSettings"
              >
                <template v-slot:prepend>
                  <v-icon>
                    mdi-content-save-outline
                  </v-icon>
                </template>
                {{ $t('page.saveSettings') }}
              </v-btn>
              <v-spacer/>
            </v-toolbar>
            <v-divider v-if="isMobile"/>
          </v-col>
          <v-col :cols="isMobile ? 12 : 6">
            <v-toolbar color="white">
              <v-spacer/>
              <v-btn
                  variant="tonal"
                  color="primary"
                  @click="$emit('editPageClick')"
              >
                <template v-slot:prepend>
                  <v-icon>mdi-puzzle-edit-outline</v-icon>
                </template>
                {{ $t('page.editMode') }}
              </v-btn>
              <v-spacer/>
            </v-toolbar>

            <page-preview
                v-if="showPreview"
                :key="'page_preview_' + pageModel.design_id + '_' + pageModel.design_variant_id"
                :uuid="page.uuid"
                :loading="false"
                :iframe-id="iframeId"
                :design-id="pageModel.design_id"
                :variant-id="pageModel.design_variant_id"
                type="view"
            />
          </v-col>
        </v-row>
      </div>
    </v-card>
    <publish-dialog
        v-if="pageModel"
        ref="publishDialog"
        :uuid="uuid"
        :page="pageModel"
        @unpublished="unpublishedHandler"
        @published="publishedHandler"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import PagePreview from "@cabinet/components/page/PagePreview.vue";
import ROUTE_NAMES from "@cabinet/router/routeNames.js";
import PageForm from "@cabinet/components/page/PageForm.vue";
import PublishDialog from "@cabinet/components/page/PublishDialog.vue";
import pageUrlMixin from "@cabinet/components/page/pageUrlMixin.js";
import DesignPreview from "@cabinet/components/page/DesignPreview.vue";

export default {
  name: 'PageInfo',
  mixins: [pageUrlMixin],
  components: {DesignPreview, PublishDialog, PageForm, PagePreview},
  props: {
    uuid: {
      type: String,
      required: true,
    },
    page: {
      type: Object,
      required: true,
    },
    showPreview: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      iframeId: null,
      saving: false,
      pageModel: null,
      pageOriginal: null,
    };
  },
  computed: {
    initialBaseUrl() {
      return this.getBaseUrl(this.pageOriginal.domain_id);
    },
    
    initialPageUrl() {
      return this.getPageUrl(this.pageOriginal.link_type, this.pageOriginal.account, this.pageOriginal.domain_id);
    },

    currentBaseUrl() {
      return this.getBaseUrl(this.pageModel.domain_id);
    },
    currentPageUrl() {
      return this.getPageUrl(this.pageModel.link_type, this.pageModel.account, this.pageModel.domain_id);
    },
    isMobile() {
      return this.$vuetify.display.mobile;
    },
    isEdited() {
      return !_.isEqual(this.pageOriginal, this.pageModel);
    },
    domainIdIndexMap() {
      const map = {};
      this.domains.forEach((domain, index) => {
        map[domain.id] = index;
      });
      return map;
    },
  },
  created() {
    this.iframeId = 'preview_' + ((Math.random() + 1).toString(36).substring(7));
  },
  mounted() {
    this.pageModel = this.getPageModel(this.page);
    this.pageOriginal = this.getPageModel(this.page);
  },
  methods: {
    goToMyPages() {
      this.$router.push({ name: ROUTE_NAMES.PAGES });
    },
    getPageModel(page) {
      const clonedPage = _.cloneDeep(page);
      delete clonedPage.page_blocks;
      delete clonedPage.theme_data;
      return clonedPage;
    },
    pageSavedHandler(page) {
      this.pageOriginal = _.cloneDeep(page);
      this.loadDomains();
    },
    async savePageSettings() {
      this.$refs.pageForm.savePageSettings();
    },
    async unpublishedHandler() {
      this.pageModel.published = false;
      this.pageOriginal.published = false;
    },
    async publish() {
      this.$refs.publishDialog.show();
    },
    async publishedHandler(data) {
      this.pageModel.account = data.account;
      this.pageOriginal.account = data.account;
      this.pageModel.link_type = data.link_type;
      this.pageOriginal.link_type = data.link_type;
      this.pageModel.domain_id = data.domain_id;
      this.pageOriginal.domain_id = data.domain_id;
      this.makeAsMain = data.makeAsMain;
      
      this.pageModel.published = true;
      this.pageOriginal.published = true;
    },
  },
}
</script>
