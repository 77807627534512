import api from './api';
import { responseHandler } from './api';

export default {
    /**
     * Get Profile
     *
     * @return Promise<Object>
     */
    getPageData(uuid) {
        return api
            .get('/api/pages/' + uuid)
            .then(responseHandler);
    },

    /**
     * Get Profile
     *
     * @return Promise<Object>
     */
    getPages() {
        return api
            .get('/api/pages')
            .then(responseHandler);
    },

    createPage(pageData) {
        return api
            .post('/api/pages', pageData)
            .then(responseHandler);
    },

    savePage(uuid, pageData) {
        return api
            .put('/api/pages/' + uuid, pageData)
            .then(responseHandler);
    },
    
    saveBlocksData(uuid, blocksData) {
        return api
            .post('/api/pages/' + uuid + '/blocks', {
                data: blocksData,
            })
            .then(responseHandler);
    },

    saveSettings(uuid, settingsData) {
        return api
            .post('/api/pages/' + uuid + '/settings', settingsData)
            .then(responseHandler);
    },

    publish(uuid, data) {
        return api
            .post('/api/pages/' + uuid + '/publish', data)
            .then(responseHandler);
    },

    unpublish(uuid) {
        return api
            .delete('/api/pages/' + uuid + '/publish')
            .then(responseHandler);
    },
}