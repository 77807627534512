<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="800"
  >
    <v-card v-if="specialDay">
      <v-card-title>
        <span v-if="type === 'working_time'">{{ $t('schedule.workingTime') }}</span>
        <span v-if="type === 'day_off'">{{ $t('schedule.dayOff') }}</span>
        <span v-if="type === 'break'">{{ $t('schedule.break') }}</span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-alert
            v-model="errorAlert"
            class="mb-5"
            type="warning"
            density="compact"
            closable
        >
          {{ errorMessage }}
        </v-alert>
        <v-form
            ref="businessHoursForm"
            :disabled="isUneditable"
            @submit.prevent="save"
        >
          <v-row>
            <v-col
                v-if="isUneditable"
                cols="12"
            >
              <i>{{ $t('schedule.sharedTimeHint') }}</i>
            </v-col>
            <v-col
                v-if="isSupervisor"
                cols="12"
            >
              <v-checkbox
                  v-model="specialDay.is_common"
                  :label="$t('schedule.applyToAllEmployees')"
                  hide-details
              />
            </v-col>
            <v-col cols="12">
              <date-picker v-model="specialDay.date"/>
            </v-col>
            <v-col
                v-if="type !== 'day_off'"
                cols="12"
            >
              <time-range-picker
                  v-model:time-start="specialDay.open_time"
                  v-model:time-end="specialDay.close_time"
                  :rules="rules.timesValidation"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider />
      <v-card-actions>
        <v-btn
            v-if="specialDay.id"
            :loading="deleting"
            :disabled="isUneditable"
            color="red-btn"
            variant="tonal"
            @click="clickDeleteHandler"
        >
          {{ $t('delete') }}
        </v-btn>
        <v-spacer />
        <v-btn
            @click="this.dialog = false"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
            :loading="saving"
            :disabled="isUneditable"
            prepend-icon="mdi-check-circle"
            color="primary-btn"
            variant="tonal"
            theme="dark"
            @click="save"
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <confirmation ref="confirmation"/>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import parseErrorsMixins from "@cabinet/mixins/parseErrorsMixins.js";
import TimeRangePicker from "@cabinet/components/TimeRangePicker.vue";
import {compose, ruleRequired} from "@cabinet/utils/validationRules.js";
import businessHours from "@cabinet/api/businessHours.js";
import DialogLoading from "@cabinet/components/DialogLoading.vue";
import DatePicker from "@cabinet/components/DatePicker.vue";
import Confirmation from "@cabinet/components/Confirmation.vue";

export default {
  name: 'SpecialDayDialog',
  mixins: [parseErrorsMixins],
  components: {
    Confirmation,
    DatePicker,
    DialogLoading,
    TimeRangePicker
  },
  props: {
    isSupervisor: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      saving: false,
      deleting: false,
      userId: null,
      type: null,
      specialDay: null,
    };
  },
  computed: {
    rules() {
      return compose({
        timesValidation: [
          ruleRequired(),
        ],
      });
    },
    isUneditable() {
      return !this.isSupervisor && this.specialDay && this.specialDay.is_common;
    },
  },
  methods: {
    async show(userId, entity, type = null) {
      this.userId = userId;
      this.dialog = true;
      this.hideError();
      let specialDay = {
        id: null,
        is_common: false,
        is_open: null,
        date: null,
        open_time: null,
        close_time: null,
      };
      if (entity.id) {
        if (entity.is_open) {
          this.type = 'working_time';
        }
        if (!entity.is_open) {
          if (!entity.open_time  && !entity.close_time) {
            this.type = 'day_off';
          } else {
            this.type = 'break';
          }
        }
        specialDay = _.cloneDeep(entity);
      } else {
        this.type = type;
        specialDay.is_open = type === 'working_time';
      }
      this.specialDay = specialDay;
    },
    close() {
      this.dialog = false;
    },
    async save() {
      this.hideError();
      const validate = await this.$refs.businessHoursForm.validate();
      if (!validate.valid) {
        return;
      }
      try {
        this.saving = true;
        if (!this.specialDay.id) {
          await businessHours.createSpecialDay(this.userId, this.specialDay);
        } else {
          await businessHours.updateSpecialDay(this.userId, this.specialDay.id, this.specialDay);
        }
        this.$emit('saved');
        this.dialog = false;
      } catch (e) {
        this.parseErrorResponse(e.response);
      }  finally {
        this.saving = false;
      }
    },
    clickDeleteHandler() {
      this.$refs.confirmation.showConfirm(
          this.$t('confirmation'),
          this.$t('schedule.deleteConfirmationText'),
          async () => {
            await businessHours.deleteSpecialDay(this.specialDay.id);
            this.$emit('deleted');
            this.dialog = false;
          }
      );
    }
  }
}
</script>
