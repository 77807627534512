<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="800"
  >
    <v-card :loading="loading">
      <v-card-title>{{ $t('blocks.' + block.type) }}</v-card-title>
      <v-divider />

      <component
          v-if="componentExists(block.component)"
          v-bind:is="block.component"
          v-model:loading="loading"
          v-model:theme="block.theme"
          v-model:data="block.data"
          :ref="block.component + '_' + block.id"
      ></component>

      <v-divider />
      <v-card-actions>
        <v-btn
            color="red-btn"
            variant="tonal"
            @click="$emit('delete', block.id)"
        >
          {{ $t('delete') }}
        </v-btn>
        <v-spacer />
        <v-btn
            @click="this.dialog = false"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
            prepend-icon="mdi-check-circle"
            color="primary-btn"
            variant="tonal"
            theme="dark"
            :disabled="loading"
            @click="save"
        >
          {{ $t('apply') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import Avatar_1 from "@cabinet/components/editor/blocks/Avatar_1.vue";
import Text_1 from "@cabinet/components/editor/blocks/Text_1.vue";
import Divider_1 from "@cabinet/components/editor/blocks/Divider_1.vue";
import BackCall_1 from "@cabinet/components/editor/blocks/BackCall_1.vue";
import Map_1 from "@cabinet/components/editor/blocks/Map_1.vue";
import Appointment_1 from "@cabinet/components/editor/blocks/Appointment_1.vue";
import Messengers_1 from "@cabinet/components/editor/blocks/Messengers_1.vue";
import Socials_1 from "@cabinet/components/editor/blocks/Socials_1.vue";
import Gallery_1 from "@cabinet/components/editor/blocks/Gallery_1.vue";
import DialogLoading from "@cabinet/components/DialogLoading.vue";

export default {
  name: 'EditBlockDialog',
  components: {
    DialogLoading,
    Avatar_1,
    Text_1,
    Divider_1,
    BackCall_1,
    Map_1,
    Appointment_1,
    Messengers_1,
    Socials_1,
    Gallery_1,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      block: {},
    };
  },
  methods: {
    componentExists(componentName) {
      return Object.prototype.hasOwnProperty.call(
          this.$options.components,
          componentName,
      );
    },
    show(block) {
      this.block = _.cloneDeep(block);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    save() {
      this.$emit('save', this.block);
      this.dialog = false;
    }
  }
}
</script>
