<template>
  <div>
    <loading v-if="loading"/>
    <div v-else>
      <v-tabs
          v-model="tab"
          class="page-tabs"
      >
        <v-tab value="info">Info</v-tab>
        <v-tab value="editor">Editor</v-tab>
      </v-tabs>

      <v-window v-model="tab">
        <v-window-item value="info">
          <page-info
              ref="pageInfo"
              :uuid="uuid"
              :page="page"
              :show-preview="tab === 'info'"
              @editPageClick="showEditor"
          />
        </v-window-item>

        <v-window-item value="editor">
          <editor
              v-if="tab === 'editor'"
              :loading="loading"
              :page="page"
              @goBack="showInfo"
              @blocksSaved="applyBlocksChanges"
              @settingsSaved="applySettingsChanges"
          />
        </v-window-item>
      </v-window>
    </div>
  </div>
</template>

<script>
import pages from "@cabinet/api/pages.js";
import checkAuthorizedMixins from "@cabinet/mixins/checkAuthorizedMixins.js";
import ROUTE_NAMES from "@cabinet/router/routeNames.js";
import Loading from "@cabinet/components/Loading.vue";
import PageInfo from "@cabinet/components/page/PageInfo.vue";
import Editor from "@cabinet/components/page/Editor.vue";
import {SET_SHOW_HEADER_EDITOR_MENU} from "@cabinet/store/Editor/mutations.js";

export default {
  name: 'Page',
  components: {Editor, PageInfo, Loading},
  mixins: [
      checkAuthorizedMixins,
  ],
  data() {
    return {
      uuid: null,
      routeNames: ROUTE_NAMES,
      loading: true,
      page: null,
      tab: 'info',
    };
  },
  mounted() {
    this.uuid = this.$route.params.uuid;
    this.fetchData();
  },
  unmounted() {
    this.$store.commit('editor/'+ SET_SHOW_HEADER_EDITOR_MENU, false);
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        const response = await pages.getPageData(this.uuid);
        this.page = response.data;
      } finally {
        this.loading = false;
      }
    },
    showEditor() {
      this.tab = 'editor';
      this.$store.commit('editor/'+ SET_SHOW_HEADER_EDITOR_MENU, true);
    },
    showInfo() {
      this.tab = 'info';
      this.$store.commit('editor/'+ SET_SHOW_HEADER_EDITOR_MENU, false);
    },
    applyBlocksChanges(payload) {
      this.page.page_blocks = payload.pageBlocks;
    },
    applySettingsChanges(payload) {
      this.page.settings = payload.settings;
    },
  },
}
</script>
<style>
.page-tabs {
  display: none;
}
</style>