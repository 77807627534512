import api from './api';
import { responseHandler } from './api';

export default {
    resendVerificationLink() {
        return api
            .post('/api/email/verification-notification')
            .then(responseHandler);
    },
    
    forgotPassword (data) {
        return api
            .post('/api/forgot-password', data)
            .then(responseHandler);
    },

    resetPassword (data) {
        return api
            .post('/api/reset-password', data)
            .then(responseHandler);
    },
    
    login(data) {
        return api
            .post('/api/login', data)
            .then(responseHandler);
    },

    logout() {
        return api
            .delete('/api/logout')
            .then(response => response);
    },

    registration(data) {
        return api
            .post('/api/register', data)
            .then(responseHandler);
    },
}