<template>
</template>

<script>
import {items} from "@cabinet/components/navigation/navigationItems.js";
export default {
  name: 'Main',
  components: {
  },
  data() {
    return {
      items,
    };
  },
  mounted() {
    for (const item of this.items) {
      if (this.$can('get', 'page_' + item.route)) {
        this.$router.push({ name: item.route })
        break;
      }
    }
  },
}
</script>
