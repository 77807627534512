<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="600"
  >
    <dialog-loading v-if="loading"/>
    <v-card v-else-if="event">
      <v-card-title>
        {{ isNew ? $t('event.addEvent') : $t('event.editEvent') }}
      </v-card-title>
      <v-divider />
      <v-card-text style="max-height: calc(100vh - 150px); overflow-y: scroll">
        <v-alert
            v-model="errorAlert"
            class="mb-5"
            type="warning"
            density="compact"
            closable
        >
          {{ errorMessage }}
        </v-alert>
        <v-form
            ref="editEventForm"
            @submit.prevent="save"
        >
          <v-row>
            <v-col cols="12">
              <date-picker
                  v-model="event.date"
                  :hide-details="false"
                  :rules="rules.dateValidation"
              />
            </v-col>
            <v-col
                cols="12"
            >
              <time-range-picker
                  v-model:time-start="event.time_from"
                  v-model:time-end="event.time_to"
                  :rules="rules.timeValidation"
              />
            </v-col>
            <v-col v-if="isSupervisor" cols="12">
              <v-select
                  v-model="event.employee_id"
                  :items="employees"
                  :return-object="false"
                  clearable
                  item-value="id"
                  item-title="name"
                  variant="outlined"
                  :label="$t('event.employee')"
                  @update:model-value="changeEmployeeHandler"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                  v-model="event.service_id"
                  :items="services"
                  :return-object="false"
                  :loading="loadingServices"
                  clearable
                  item-value="id"
                  item-title="title"
                  variant="outlined"
                  :label="$t('event.service')"
                  @update:model-value="changeServiceHandler"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                  v-model="event.name"
                  :disabled="event.service_id ? true : false"
                  :rules="rules.nameValidation"
                  variant="outlined"
                  :label="$t('event.title')"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                  v-model="event.contact_name"
                  variant="outlined"
                  :label="$t('event.contactName')"
              />
            </v-col>
            <v-col cols="12">
              <v-phone-input
                  v-model="event.contact_phone"
                  ref="phone"
                  :all-countries="allCountries"
                  :label="$t('event.contactPhone')"
                  :country-label="$t('phoneInput.country')"
                  default-country="KZ"
                  country-icon-mode="svg"
                  :invalid-message="$t('phoneInput.invalidPhoneMessage')"
                  :error-messages="errors.phone"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider />
      <v-card-actions>
        <v-btn
            v-if="event.id"
            :loading="deleting"
            color="red-btn"
            variant="tonal"
            @click="clickDeleteHandler"
        >
          {{ $t('delete') }}
        </v-btn>
        <v-spacer />
        <v-btn
            @click="this.dialog = false"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
            :loading="saving"
            prepend-icon="mdi-check-circle"
            color="primary-btn"
            variant="tonal"
            theme="dark"
            @click="save(false)"
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <confirmation ref="confirmation" />
    <confirmation-overlaps ref="confirmationOverlaps"/>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import parseErrorsMixins from "@cabinet/mixins/parseErrorsMixins.js";
import TimeRangePicker from "@cabinet/components/TimeRangePicker.vue";
import {compose, ruleRequired} from "@cabinet/utils/validationRules.js";
import eventsApi from "@cabinet/api/events.js";
import employeesApi from '@cabinet/api/employees.js'
import DialogLoading from "@cabinet/components/DialogLoading.vue";
import DatePicker from "@cabinet/components/DatePicker.vue";
import ConfirmationOverlaps from "@cabinet/components/events/ConfirmationOverlaps.vue";
import Confirmation from "@cabinet/components/Confirmation.vue";

export default {
  name: 'EditEventDialog',
  mixins: [parseErrorsMixins],
  components: {
    Confirmation,
    ConfirmationOverlaps,
    DatePicker,
    DialogLoading,
    TimeRangePicker
  },
  props: {
    isSupervisor: {
      type: Boolean,
      required: true,
    },
    requireDateTime: {
      type: Boolean,
      required: true,
      default: false,
    }
  },
  data() {
    return {
      dialog: false,
      loading: true,
      loadingServices: false,
      isNew: false,
      saving: false,
      deleting: false,
      event: null,
      services: [],
      employees: [],
    };
  },
  computed: {
    allCountries() {
      return [
        {
          name: 'Казахстан',
          iso2: 'KZ',
          dialCode: 7
        },
        {
          name: 'Россия',
          iso2: 'RU',
          dialCode: 7
        },
      ];
    },
    rules() {
      const rules = {
        dateValidation: [],
        timeValidation: [],
        nameValidation: [],
      };
      if (!this.event.service_id) {
        rules.nameValidation = [ruleRequired()];
      }
      if (this.requireDateTime || this.event.time_from || this.event.time_to) {
        rules.timeValidation = [ruleRequired()];
        rules.dateValidation = [ruleRequired()];
      }
      return compose(rules);
    },
    servicesById() {
      const map = {};
      this.services.forEach(item => {
        map[item.id] = item.title;
      });
      return map;
    },
  },
  methods: {
    async show(eventId, employeeId = null, date = null, time_from = null, time_to = null) {
      this.loading = true;
      this.dialog = true;
      this.isNew = !eventId;
      this.event = null;
      this.hideError();
      await this.loadData(eventId, employeeId, date, time_from, time_to);
      const userIdToGetServices = this.event.employee_id || this.$store.state.user.id;
      this.loadServices(userIdToGetServices)
    },
    async loadData(eventId, employeeId = null, date = null, time_from = null, time_to = null) {
      this.loading = true;
      const promises = [];
      if (eventId) {
        promises.push(this.loadEvent(eventId));
      } else {
        this.event = {
          employee_id: employeeId,
          service_id: null,
          name: null,
          date: date,
          time_from: time_from,
          time_to: time_to,
          contact_name: null,
          contact_phone: null,
        };
      }
      if (this.isSupervisor) {
        promises.push(this.loadEmployees());
      }
      try {
        if (promises.length) {
          await Promise.all(promises);
        }
      } finally {
        this.loading = false;
      }
    },
    async loadEvent(eventId) {
      const eventData = await eventsApi.getEvent(eventId);
      this.event = eventData.data 
    },
    async loadEmployees() {
      this.employees = await employeesApi.getEmployees();
    },
    async loadServices(userId) {
      try {
        this.loadingServices = true;
        this.services = await employeesApi.getEmployeeServices(userId);
        if (this.event.service_id && !this.servicesById[this.event.service_id]) {
          this.event.service_id = null;
        }
      } finally {
        this.loadingServices = false;
      }
    },
    close() {
      this.dialog = false;
    },
    async save(forceOverlaps) {
      this.hideError();
      const validate = await this.$refs.editEventForm.validate();
      if (!validate.valid) {
        return;
      }
      try {
        this.saving = true;
        const submitData = _.cloneDeep(this.event);
        submitData.forceOverlaps = forceOverlaps;
        if (this.isNew) {
          await eventsApi.createEvent(submitData);
        } else {
          await eventsApi.updateEvent(this.event.id, submitData);
        }
        this.$emit('saved');
        this.dialog = false;
      } catch (e) {
        const response = e.response;
        if (
            response.status === 409
            && response.data
            && response.data.error_code
            && response.data.error_code === 'event_overlaps'
        ) {
          this.$refs.confirmationOverlaps.showConfirm(() => {
            this.save(true);
          });
        } else {
          this.parseErrorResponse(response, true);
        }
      }  finally {
        this.saving = false;
      }
    },
    clickDeleteHandler() {
      this.$refs.confirmation.showConfirm(
          this.$t('confirmation'),
          this.$t('appointments.deleteEventText'),
          async() => {
            await eventsApi.delete(this.event.id);
            this.$emit('deleted');
            this.close();
          }
      );
    },
    changeServiceHandler() {
      if (this.event.service_id) {
        this.event.name = this.servicesById[this.event.service_id];
      }
    },
    changeEmployeeHandler() {
      const userIdToGetServices = this.event.employee_id || this.$store.state.user.id;
      this.loadServices(userIdToGetServices)
    },
  }
}
</script>
