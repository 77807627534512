<template>
  <div>
    <v-card
        :loading="loading"
        flat
        tile
    >
      <v-sheet
          class="pt-4 pl-2 pr-2 mx-auto"
          max-width="500"
      >
        <avatar
            v-if="avatar"
            v-model="avatar"
            :image-size="150"
            :size="100"
        />
        
        <v-alert
            v-model="errorAlert"
            class="mb-2"
            type="warning"
            density="compact"
            closable
        >
          {{ errorMessage }}
        </v-alert>
        <v-form
            ref="profileForm"
            @submit.prevent="saveProfile"
        >
          <v-text-field
              v-model="profile.name"
              :label="$t('settings.profile.name')"
              :rules="rules.nameValidation"
              :error-messages="errors.name"
              :maxlength="255"
          />
          <template v-if="profile.type === 'account'">
            <v-text-field
                v-model="profile.email"
                :label="$t('settings.profile.email')"
                :rules="rules.emailValidation"
                :error-messages="errors.email"
                :maxlength="255"
                readonly
                disabled
            />
          </template>
          <template v-if="profile.type === 'employee'">
            <v-text-field
                v-model="profile.username"
                :label="$t('settings.profile.username')"
                :rules="rules.usernameValidation"
                :error-messages="errors.username"
                :maxlength="255"
            />
            <v-text-field
                v-model="profile.email"
                :label="$t('settings.profile.employeeEmail')"
                :rules="rules.employeeEmailValidation"
                :error-messages="errors.email"
                :maxlength="255"
            />
          </template>
          
          <v-text-field
              v-model="profile.currentPassword"
              :label="$t('settings.profile.currentPassword')"
              :rules="rules.currentPasswordValidation"
              :error-messages="errors.currentPassword"
              validate-on="submit"
              type="password"
              :maxlength="40"
          />

          <v-expand-transition>
            <div v-if="showSetNewPassword">
              <v-text-field
                  v-model="profile.newPassword"
                  :label="$t('settings.profile.newPassword')"
                  :rules="rules.newPasswordValidation"
                  :error-messages="errors.newPassword"
                  type="password"
                  :maxlength="40"
              />
              <v-text-field
                  v-model="retypePassword"
                  :label="$t('settings.profile.retypePassword')"
                  :rules="rules.retypePasswordValidation"
                  type="password"
                  :maxlength="40"
              />
            </div>
          </v-expand-transition>

          <v-btn variant="tonal" density="compact" @click="showHideChangePassword">
            <template v-slot:prepend>
              <v-icon v-if="showSetNewPassword">mdi-chevron-up</v-icon>
              <v-icon v-if="!showSetNewPassword">mdi-chevron-down</v-icon>
            </template>
            {{ $t('settings.profile.changePassword') }}
          </v-btn>
        </v-form>
      </v-sheet>

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary"
               :disabled="!edited"
               @click="cancelEdit"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn color="primary"
               variant="tonal"
               :disabled="!edited"
               :loading="saving"
               @click="saveProfile"
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <confirmation ref="confirmation"/>
  </div>
</template>
<script>
import _ from 'lodash';
import parseErrorsMixins from "@cabinet/mixins/parseErrorsMixins.js";
import {compose, ruleEmail, ruleMin, ruleRequired} from "@cabinet/utils/validationRules.js";
import userApi from "@cabinet/api/user.js";
import Confirmation from "@cabinet/components/Confirmation.vue";
import {SET_USER} from "@cabinet/store/mutations.js";
import Avatar from "@cabinet/components/Avatar.vue";

export default {
  name: 'Profile',
  components: {Avatar, Confirmation},
  mixins: [parseErrorsMixins],
  data() {
    return {
      loading: true,
      saving: false,
      showSetNewPassword: false,
      avatar: null,
      avatarOriginal: null,
      profile: {},
      profileOriginal: {},
      retypePassword: null,
    };
  },
  computed: {
    edited() {
      return !_.isEqual(this.profile, this.profileOriginal)
          || !_.isEqual(this.avatar, this.avatarOriginal);
    },
    rules() {
      const newPasswordValidation = [];
      const retypePasswordValidation = [];
      if (this.showSetNewPassword) {
        newPasswordValidation.push(ruleRequired());
        newPasswordValidation.push(ruleMin('string', 6));
        retypePasswordValidation.push(ruleRequired());
        retypePasswordValidation.push(
            (v) => v === this.profile.newPassword || this.$t('validation.passwordConfirmation')
        );
      }
      
      return compose({
        nameValidation: [
          ruleRequired(),
        ],
        emailValidation: [
          ruleRequired(),
          ruleEmail(),
        ],
        usernameValidation: [
          ruleRequired(),
        ],
        employeeEmailValidation: [
          ruleEmail(),
        ],
        currentPasswordValidation: [
          ruleRequired(),
          ruleMin('string', 6),
        ],
        newPasswordValidation,
        retypePasswordValidation,
      });
    },
  },
  mounted() {
    this.fetchProfile();
  },
  watch: {
    profile: {
      handler() {
        if (this.profile.currentPassword === '') {
          this.profile.currentPassword = null;
        }
        if (this.profile.newPassword === '') {
          this.profile.newPassword = null;
        }
      },
      deep: true,
    }
  },
  methods: {
    async fetchProfile() {
      this.loading = true;
      try {
        const response = await userApi.getProfile();
        const userData = _.cloneDeep(response.data);
        userData.currentPassword = null;
        userData.newPassword = null;
        this.profile = userData;
        this.profileOriginal = _.cloneDeep(userData);
        this.avatar = {
          avatarUrl: userData.avatarUrl,
        };
        this.avatarOriginal = _.cloneDeep(this.avatar);
        
        await this.$refs.profileForm.resetValidation();
      } finally {
        this.loading = false;
      }
    },

    cancelEdit() {
      this.$refs.confirmation.showConfirm(
          this.$t('settings.profile.cancelEditTitle'),
          this.$t('settings.profile.cancelEditText'),
          () => {
            this.profile = _.cloneDeep(this.profileOriginal);
            this.retypePassword = null;
          }
      );
    },
    
    async saveProfile() {
      this.hideError();
      const validate = await this.$refs.profileForm.validate()
      if (!validate.valid) {
        return;
      }
      this.saving = true;
      try {
        const submitData = _.cloneDeep(this.profile);
        submitData.avatarUrl = this.avatar.avatarUrl;
        if (this.avatar.isAvatarTempUrl) {
          submitData.isAvatarTempUrl = true;
        }
        const response = await userApi.saveProfile(submitData);
        const profileData = response.data;
        this.$store.commit(SET_USER, _.cloneDeep(profileData));
        profileData.currentPassword = null;
        profileData.newPassword = null;
        this.profile = _.cloneDeep(profileData);
        this.profileOriginal = _.cloneDeep(profileData)
        this.avatar = {
          avatarUrl: profileData.avatarUrl,
        };
        this.avatarOriginal = _.cloneDeep(this.avatar);
        
        this.retypePassword = null;
        this.showSetNewPassword = false;
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.saving = false;
      }
    },
    
    showHideChangePassword() {
      if (this.showSetNewPassword) {
        this.retypePassword = null;
        this.profile.newPassword = null;
      }
      this.showSetNewPassword = !this.showSetNewPassword;
    }
  }
};

</script>