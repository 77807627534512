class EventBus {
    constructor() {
        this.events = {};
    }

    $on(event, callback) {
        if (!this.events[event]) {
            this.events[event] = [];
        }
        this.events[event].push(callback);
    }

    $emit(event, data) {
        const eventCallbacks = this.events[event];
        if (eventCallbacks) {
            eventCallbacks.forEach(callback => {
                callback(data);
            });
        }
    }

    $off(event, callback) {
        const eventCallbacks = this.events[event];
        if (eventCallbacks) {
            const index = eventCallbacks.indexOf(callback);
            if (index > -1) {
                eventCallbacks.splice(index, 1);
            }
        }
    }
}

export const events = {
    EDITOR_CLICK_CANCEL: 'editorClickCancel',
    EDITOR_CLICK_BACK: 'editorClickBack',
    EDITOR_CLICK_ADD_BLOCK: 'editorClickAddBlock',
    EDITOR_CLICK_SAVE: 'editorClickSave',
    TELEGRAM_BOT_SUBSCRIBED: 'telegramBotSubscribed',
    TELEGRAM_BOT_UNSUBSCRIBED: 'telegramBotUnsubscribed',
    NEW_NOTIFICATION: 'newNotification',
    EMAIL_VERIFIED: 'emailVerified',
    PAGE_IMAGE_RENDERED: 'pageImageRendered',
};

export const eventBus = new EventBus();
