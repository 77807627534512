<template>
  <v-dialog
      v-model="dialog"
      max-width="500"
      class="add-service-dialog"
      persistent
  >
    <dialog-loading v-if="loading"/>
    <v-card
        v-else
        min-height="350"
    >
      <v-card-title>{{ isNew ? $t('services.editDialog.addService') : $t('services.editDialog.editService') }}</v-card-title>
      <v-divider />
      <v-card-text>
        <v-alert
            v-model="errorAlert"
            class="mb-2"
            type="warning"
            density="compact"
            closable
        >
          {{ errorMessage }}
        </v-alert>
        
        <v-form
            ref="saveServiceForm"
            @submit.prevent="save"
        >
          <v-text-field
              v-model="submitData.title"
              :label="$t('services.editDialog.title')"
              :maxlength="255"
              :rules="rules.titleValidation"
              :error-messages="errors.title"
          />
          <v-textarea
              v-model="submitData.description"
              :label="$t('services.editDialog.description')"
              :rules="rules.descriptionValidation"
              :error-messages="errors.username"
              :maxlength="1000"
              :counter="1000"
          />

          <v-menu
              v-model="timePickerMenu"
              :close-on-content-click="false"
              max-width="290px"
              min-width="290px"
          >
            <template v-slot:activator="{ props }">
              <v-text-field
                  v-model="time"
                  :label="$t('services.editDialog.time')"
                  :maxlength="255"
                  :rules="rules.timeValidation"
                  :error-messages="errors.time"
                  prepend-inner-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="props"
              />
            </template>
            <custom-time-picker
                v-model="time"
                :hide-btn-now="true"
                :title-name="$t('services.editDialog.time')"
                @close="timePickerMenu = false"
            />
            
          </v-menu>
          
          <v-text-field
              v-model="submitData.price"
              :label="$t('services.editDialog.price')"
              :maxlength="255"
              :rules="rules.priceValidation"
              :error-messages="errors.price"
              prefix="₸"
              @keydown="validateIntegerInput"
          />
          
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
            @click="dialog = false;"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
            :loading="submitting"
            variant="tonal"
            @click="save"
        >
          {{ isNew ? $t('add') : $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import parseErrorsMixins from "@cabinet/mixins/parseErrorsMixins.js";
import servicesApi from "@cabinet/api/services.js";
import {compose, ruleRequired} from "@cabinet/utils/validationRules.js";
import { convertNumberToTime, convertTimeToNumber } from '@cabinet/utils/convertNumberToTime';
import DialogLoading from "@cabinet/components/DialogLoading.vue";
import CustomTimePicker from "@cabinet/components/CustomTimePicker.vue";

export default {
  name: 'EditServiceDialog',
  components: {CustomTimePicker, DialogLoading},
  mixins: [parseErrorsMixins],
  data() {
    return {
      dialog: false,
      timePickerMenu: false,
      time: null,
      isNew: true,
      loading: false,
      serviceId: null,
      submitData: {
        title: null,
        description: null,
        time: null,
        price: null,
      },
      submitting: false,
    };
  },
  computed: {
    rules() {
      return compose({
        titleValidation: [
          ruleRequired(),
        ],
        descriptionValidation: [],
        timeValidation: [
          ruleRequired(),
        ],
        priceValidation: [
          ruleRequired(),
        ],
      });
    },
  },
  methods: {
    async show(service = null) {
      this.dialog = true;
      this.loading = true;
      this.errors = {};
      const submitData = {
        title: null,
        description: null,
        time: null,
        price: null,
      };
      this.serviceId = null;
      if (service) {
        this.isNew = false;
        const serviceData = await servicesApi.getService(service.id);
        submitData.title = serviceData.title;
        submitData.description = serviceData.description;
        submitData.time = serviceData.time;
        submitData.price = serviceData.price;
        this.time = convertNumberToTime(serviceData.time);
        this.serviceId = service.id;
      } else {
        this.isNew = true;
      }
      this.submitData = submitData;
      this.loading = false;
    },
    hide() {
      this.dialog = false;
    },
    async save() {
      this.hideError();
      const validate = await this.$refs.saveServiceForm.validate()
      if (!validate.valid) {
        return;
      }
      try {
        this.submitting = true;
        const submitData = this.prepareDataToSubmit();
        if (this.isNew) {
          await servicesApi.createService(submitData);
        } else {
          await servicesApi.saveService(this.serviceId, submitData);
        }
        this.$emit('saved');
        this.dialog = false;
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.submitting = false;
      }
    },

    prepareDataToSubmit() {
      const dataToSubmit = _.cloneDeep(this.submitData);
      dataToSubmit.time = convertTimeToNumber(this.time);
      return dataToSubmit;
    },
    
    validateIntegerInput(event) {
      const controlKeys = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete'];
      if (controlKeys.includes(event.key)) {
        return;
      }
      
      const regex = /^[0-9]$/;
      if (!regex.test(event.key)) {
        event.preventDefault();
      }
    }
  }
}
</script>
<style>
.add-service-dialog {

}
</style>