<template>
  <common-block>
    <template v-slot:content>
      <v-form>
        <v-text-field
            v-model="dataModel.title"
            :label="$t('editor.text.title')"
            variant="underlined"
        ></v-text-field>
        <div style="height: 200px; margin-bottom: 100px">
          <quill-editor
              ref="editor"
              v-model:content="content"
              :options="options"
              @editorChange="changeHandle"
          />
        </div>
      </v-form>
    </template>
    <template v-slot:design>
      <v-form>
        <v-radio-group
            v-model="themeModel.VCardTitle.class.position"
            inline
            :label="$t('editor.text.titlePosition')"
        >
          <v-radio
              :label="$t('editor.text.positionLeft')"
              value="text-left"
          ></v-radio>
          <v-radio
              :label="$t('editor.text.positionCenter')"
              value="text-center"
          ></v-radio>
          <v-radio
              :label="$t('editor.text.positionRight')"
              value="text-right"
          ></v-radio>
        </v-radio-group>
      </v-form>
    </template>
  </common-block>
</template>
<script>
import blockMixin from "@cabinet/components/editor/blocks/blockMixin.js";
import {QuillEditor} from "@vueup/vue-quill";
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  name: 'Text_1',
  components: {QuillEditor},
  mixins: [blockMixin],
  data() {
    return {
      content: null,
      options: {
        modules: {
          toolbar: [
            [{ 'size': ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline'],
            ['link', 'blockquote'],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['clean']
          ]
        },
      },
    };
  },
  mounted() {
    this.$refs.editor.setHTML(this.dataModel.text);
  },
  methods: {
    changeHandle() {
      this.dataModel.text = this.$refs.editor.getHTML();
    },
  }
}
</script>