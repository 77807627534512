<template>
  <div class="phone-container">
    <v-responsive :aspect-ratio="9 / 19.5">
      <div v-if="loading || iframeLoading"
           class="loading-overlay"
      >
        <v-row
            align-content="center"
            class="fill-height"
            justify="center"
        >
          <v-col
              class="text-subtitle-1 text-center"
              cols="12"
          >
            {{ $t('loadingYourPage') }}
          </v-col>
          <v-col cols="6">
            <v-progress-linear
                color="primary"
                height="6"
                indeterminate
                rounded
            ></v-progress-linear>
          </v-col>
        </v-row>
      </div>
      <iframe
          :id="iframeId"
          class="preview"
          :src="baseUrl + '/design-preview/' + designId + (variantId ? '/' + variantId : '')"
      />
    </v-responsive>
  </div>
</template>

<script>
export default {
  name: 'DesignPreview',
  props: {
    designId: {
      type: Number,
      required: true,
    },
    variantId: {
      type: Number,
      required: false,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    iframeId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      baseUrl: null,
      iframeLoading: true,
    };
  },
  mounted() {
    this.baseUrl = import.meta.env.VITE_CABINET_API_BASE_URL;
    
    const iframe = document.getElementById(this.iframeId);
    const that = this;
    iframe.addEventListener('load', function() {
      that.iframeLoading = false;
    });
  }
}
</script>
<style>
.preview {
  border: none;
  width: 100%;
  height: 100%;
}
.phone-container {
  width: 330px;
  margin: 0 auto;
  border: 10px solid #505050;
  border-radius: 20px;
  iframe {
    border-radius: 10px;
  }
}
.loading-overlay {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FFFFFF;
  z-index: 2;
  border-radius: 10px;
}
</style>