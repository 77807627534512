<template>
  <div class="text-center">
    <v-btn
        v-if="hasChanges"
        class="mr-2"
        variant="tonal"
        color="red"
        :title="$t('editorMenu.cancel')"
        :disabled="!hasChanges || saving"
        @click="cancel"
    >
      <v-icon>
        mdi-undo
      </v-icon>
    </v-btn>
    <v-btn
        v-if="!hasChanges"
        class="mr-2"
        variant="tonal"
        :title="$t('editorMenu.back')"
        :disabled="saving"
        @click="back"
    >
      <v-icon>
        mdi-arrow-left-thin
      </v-icon>
    </v-btn>
    <v-btn
        variant="flat"
        color="primary"
        :disabled="saving"
        @click="showAddBlock"
    >
      <template v-slot:prepend>
        <v-icon>
          mdi-plus
        </v-icon>
      </template>
      {{ $t('editorMenu.addBlock') }}
    </v-btn>
    <v-btn
        class="ml-2"
        variant="tonal"
        color="success"
        :title="$t('editorMenu.save')"
        :loading="saving"
        :disabled="!hasChanges"
        @click="save"
    >
      <v-icon>
        mdi-content-save-outline
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { eventBus, events } from "@cabinet/utils/eventBus.js";

export default {
  name: 'EditorMenu',

  data() {
    return {

    };
  },
  computed: {
    hasChanges() {
      return this.$store.state.editor.hasChanges;
    },
    saving() {
      return this.$store.state.editor.saving;
    },
  },
  methods: {
    showAddBlock() {
      eventBus.$emit(events.EDITOR_CLICK_ADD_BLOCK);
    },
    cancel() {
      eventBus.$emit(events.EDITOR_CLICK_CANCEL);
    },
    back() {
      eventBus.$emit(events.EDITOR_CLICK_BACK);
    },
    save() {
      eventBus.$emit(events.EDITOR_CLICK_SAVE);
    },
  }
}
</script>
