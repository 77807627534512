<template>
  <v-layout>
    <navigation ref="navigation" v-if="$store.getters.initialized && $store.getters.authorized"/>

    <app-bar
        v-if="$store.getters.initialized && $store.getters.authorized"
        ref="appBar"
        @clickMenuExpand="$refs.navigation.showDrawer()"
    />
    <app-bar-unauthorized
        v-if="$store.getters.initialized && !$store.getters.authorized"
        ref="appBarUnauthorized"
    />
    
    <v-main>
      <v-container>
        <slot></slot>
      </v-container>
    </v-main>
  </v-layout>
</template>

<script>
import Navigation from "@cabinet/components/navigation/Navigation.vue";
import AppBar from "@cabinet/components/AppBar.vue";
import AppBarUnauthorized from "@cabinet/components/AppBarUnauthorized.vue";

export default {
  name: 'Layout',
  components: {AppBarUnauthorized, AppBar, Navigation},
  data() {
    return {
      
    };
  },
}
</script>
