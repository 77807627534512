<template>
  <v-menu v-model="menu">
    <template v-slot:activator="{ props }">
      <v-btn
          color="primary"
          v-bind="props"
          class="mr-3"
          :loading="loading"
      >
        
        <v-badge
            v-if="countUnread"
            color="red"
            :content="countUnread >= 100 ? '99+' : countUnread"
            offset-x="-10"
        >
          <v-icon icon="mdi-bell-outline" size="25"></v-icon>
        </v-badge>
        <v-icon
            v-else 
            icon="mdi-bell-outline"
            size="25"
        />
      </v-btn>
    </template>
    <v-card
        max-width="350"
        min-width="350"
        max-height="300px"
        min-height="300px"
        :hover="false"
        @click.stop
    >
      <div style="width: 100%; height: 300px; overflow: auto">
        <v-list
            v-if="!loading && notifications.length"
            lines="three"
        >
          <template v-for="(notification, index) in notifications">
            <component
                v-if="componentExists(notification.type)"
                v-bind:is="notification.type"
                :key="'notification_' + notification.id"
                :ref="'notification_' + notification.id"
                :id="notification.id"
                :type="notification.type"
                :data="notification.data"
                :read-at="notification.read_at"
                :created-at="notification.created_at"
                @click="clickNotification"
                @delete="deleteNotification"
            ></component>
            <v-divider v-if="index < notifications.length-1"/>
          </template>
        </v-list>
        <div
            style="display: flex; justify-content: center; align-items: center; height: 100%"
            v-if="!loading && !notifications.length"
        >
          {{ $t('notifications.noNotifications') }}
        </div>
        <v-card-actions
            v-if="hasMoreNotification"
            class="justify-center"
        >
          <v-btn
              class="justify-center"
              variant="tonal"
              density="compact"
              :loading="loadingLoadMore"
              @click.stop="loadMoreNotifications"
          >
            {{ $t('loadMore') }}...
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-menu>
</template>
<script>
import _ from 'lodash';
import notifications from "@cabinet/api/notifications.js";
import NewBackCall from "@cabinet/components/notifications/NewBackCall.vue";
import NewAppointment from "@cabinet/components/notifications/NewAppointment.vue";
import {eventBus, events} from "@cabinet/utils/eventBus.js";
export default {
  name: 'Notifications',
  components: {
    'new-back-call': NewBackCall,
    'new-appointment': NewAppointment,
  },
  data() {
    return {
      loading: false,
      loadingLoadMore: false,
      menu: false,
      countUnread: 0,
      notifications: [],
      page: 1,
      perPage: 5,
      firstPageIds: [],
      hasMoreNotification: false,
    };
  },
  watch: {
    menu() {
      if (this.menu) {
        this.loadNotifications();
      }
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.mobile;
    },
    user() {
      return this.$store.state.user;
    },
    notificationsIdMap() {
      const map = {};
      this.notifications.forEach((item, index) => {
        map[item.id] = index;
      });
      return map;
    },
  },
  mounted() {
    eventBus.$on(events.NEW_NOTIFICATION, this.newNotification);
    this.fetchCountUnread();
  },
  beforeUnmount() {
    eventBus.$off(events.NEW_NOTIFICATION, this.newNotification);
  },
  methods: {
    newNotification(payload) {
      this.countUnread = this.countUnread + 1;
    },
    
    componentExists(componentName) {
      return Object.prototype.hasOwnProperty.call(
          this.$options.components,
          componentName,
      );
    },
    
    loadNotifications() {
      this.loading = true;
      this.loadingLoadMore = false;
      this.notifications = [];
      this.page = 1;
      this.perPage = 5;
      this.countUnread = 0;
      this.firstPageIds = [];
      this.hasMoreNotification = false;
      this.fetchNotifications();
    },
    
    loadMoreNotifications() {
      this.loadingLoadMore = true;
      this.page = this.page + 1;
      this.fetchNotifications();
    },
    
    async fetchNotifications() {
      try {
        const response = await notifications.getNotifications(this.page, this.perPage, this.firstPageIds);
        const notificationsList = response.data;
        this.hasMoreNotification = notificationsList.length >= this.perPage;
        if (this.page === 1) {
          this.notifications = notificationsList;
          const firstPageIds = [];
          this.notifications.forEach(item => {
            firstPageIds.push(item.id);
          });
          this.firstPageIds = firstPageIds;
        } else {
          this.notifications = this.notifications.concat(notificationsList);
        }
      } finally {
        this.loading = false;
        this.loadingLoadMore = false;
      }
    },
    
    async fetchCountUnread() {
      this.countUnread = await notifications.getTotalUnread();
    },

    clickNotification() {
      this.menu = false;
    },

    async deleteNotification(id) {
      await notifications.deleteNotification(id);
      const index = this.notificationsIdMap[id];
      const notificationList = _.cloneDeep(this.notifications);
      notificationList.splice(index, 1);
      this.notifications = notificationList;
    }
  }
}
</script>
