<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="800"
  >
    <v-card>
      <v-card-title>{{ $t('editor.footerSettings.title') }}</v-card-title>
      <v-divider />
      <v-card-text>
        {{ $t('editor.footerSettings.description') }}
        <v-form
            ref="footerSettingsForm"
            @submit.prevent="save"
        >
          <template v-if="settings.socials">
            <v-text-field
                v-model="settings.socials.instagram"
                :label="$t('editor.footerSettings.instagramUrl')"
                :rules="rules.socialLinkValidation"
                :maxlength="255"
            />
            <v-text-field
                v-model="settings.socials.youtube"
                :label="$t('editor.footerSettings.youtubeUrl')"
                :rules="rules.socialLinkValidation"
                :maxlength="255"
            />
            <v-text-field
                v-model="settings.socials.tiktok"
                :label="$t('editor.footerSettings.tiktokUrl')"
                :rules="rules.socialLinkValidation"
                :maxlength="255"
            />
            <v-text-field
                v-model="settings.socials.vk"
                :label="$t('editor.footerSettings.vkUrl')"
                :rules="rules.socialLinkValidation"
                :maxlength="255"
            />
          </template>

        </v-form>
      </v-card-text>
      
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
            @click="this.dialog = false"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
            prepend-icon="mdi-check-circle"
            color="primary-btn"
            variant="tonal"
            theme="dark"
            @click="save"
        >
          {{ $t('apply') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import {compose, ruleUrl} from "@cabinet/utils/validationRules.js";
export default {
  name: 'FooterSettingsDialog',
  data() {
    return {
      dialog: false,
      settings: {},
    };
  },
  computed: {
    rules() {
      return compose({
        socialLinkValidation: [
          ruleUrl(),
        ],
      });
    }
  },
  methods: {
    show(settings) {
      this.dialog = true;
      this.settings = _.cloneDeep(settings);
    },
    close() {
      this.dialog = false;
    },
    async save() {
      const validate = await this.$refs.footerSettingsForm.validate()
      if (!validate.valid) {
        return;
      }
      this.$emit('save', this.settings);
      this.dialog = false;
    }
  }
}
</script>
